import Base64js from 'base64-js'
import { v4 as uuidv4 } from 'uuid'
import ProcessingTransactions from '~/models/Processing/ProcessingTransactions'

export class ProcessingWebSocketMessageHandler {
  singInterface = null

  constructor (signInterface) {
    this.singInterface = signInterface
  }

  handle (e, socket) {
    const data = JSON.parse(e.data)
    switch (data.type) {
      case 'welcome' :
        this.handleWelcomeMessage(data, socket)
        break
      case 'transaction' :
        this.handleTransactionMessage(data, socket)
        break
      case 'response' :
        this.handleResponseMessage(data, socket)
        break
      default:
        this.handleOtherMessage(data, socket)
    }
  }

  handleWelcomeMessage (message) {
  }

  static updateSignInterface (signInterface) {
    this.singInterface = signInterface
  }

  async handleTransactionMessage (message, socket) {
    const transactionModel = new ProcessingTransactions({
      id: message.data.id,
      type: message.data.type,
      status: ProcessingTransactions.statuses.created,
      localId: uuidv4()
    })
    await transactionModel.$save()
    const signedTransactionData = await this.singInterface.sign(Base64js.toByteArray(message.data.request_data))
    const data = {
      method: 'transaction.sign',
      payload: {
        id: transactionModel.id,
        request_signature: signedTransactionData
      },
      id: transactionModel.localId
    }
    socket.send(JSON.stringify(data))
    transactionModel.status = ProcessingTransactions.statuses.signed
    await transactionModel.$save()
  }

  async handleResponseMessage (message) {
    const transaction = ProcessingTransactions.query().where('localId', message.id).first()
    if (transaction) {
      transaction.status = message.data ? ProcessingTransactions.statuses.done : ProcessingTransactions.statuses.error
      await transaction.$save()
    }
  }

  handleOtherMessage (message, socket) {

  }
}
