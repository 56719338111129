import Administration from '~/models/abstracts/Administration'
import Group from '~/models/auth/Group'
import Permission from '~/models/auth/Permission'
import SoftDeletAble from '~/models/mixins/SoftDeletAble'

export default class Role extends SoftDeletAble(Administration) {
  static entity = 'roles';

  static staticRoles = {
    owner: '41618c65-2140-4aa5-8417-5ac2c3d497a8',
    superAdmin: '341e35de-d20b-4f1f-aaa4-e498ff65977e',
    employee: '5debe29d-0c09-4b18-b68e-27410708f532'
  }

  static fields () {
    return super.fields({
      id: this.attr(null),
      name: this.attr(null),
      description: this.attr(null),
      permissions: this.attr(null)
    })
  }

  static ormRelationMap = [
    'permissions'
  ]

  static ormTrans = {
    single: 'Role',
    multy: 'Roles'
  };

  static ormHeaders = [
    { text: 'Status', sortable: true, value: 'active' },
    { text: 'Role Name', value: 'name' },
    { text: 'Description', value: 'description' },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ];

  static ormFields = [
    {
      model: 'name',
      component: 'v-text-field',
      provider: {
        vid: 'name',
        name: 'Role Name',
        rules: 'required'
      },
      attrs: {
        label: 'Role Name',
        outlined: true
      }
    },
    {
      model: 'description',
      component: 'v-textarea',
      provider: {
        name: 'description'
      },
      attrs: {
        label: 'Description',
        'auto-grow': true,
        outlined: true
      }
    },
    {
      model: 'permissions',
      component: 'e-tree-select',
      provider: {
        name: 'Permissions'
      },
      attrs: {
        label: 'To access the settings of any element, click on the lock.',
        type: 'text',
        'auto-grow': true,
        outlined: true
      },
      async request () {
        await Promise.all([
          Permission.api().all(),
          Group.api().all()
        ])
      },
      items () {
        return Group.all()
      }
    }
  ];

  static ormActions = [
    {
      name: 'edit'
    }
  ];
}
