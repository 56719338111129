<template lang="pug">
  t-dialog(v-bind="$attrs" type="card" v-on="$listeners" max-width="1170px" content-class="m-orm-card-dialog")
    template(#title)
      span(class="text-center d-inline-block") {{ $t(title) }}

    template(#content)
      v-treeview(
        :items="rootItems",
        :active="active"
        @update:active="updateActive"
        :item-key="model.primaryKey"
        :item-children="model.ormTreeConfig.childrenFieldName"
        :load-children="loadChildren"
        activatable
        selection-type="independent"
      )
        template(#label="{item}")
          span {{_.isFunction(model.ormTreeConfig.textFieldName) ? model.ormTreeConfig.textFieldName(item) : item[model.ormTreeConfig.textFieldName] }}

    template(#actions)
      t-orm-buttons(:items="ormButtons")

</template>

<script>
import TDialog from '~/components/templates/t-dialog'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
export default {
  components: {
    TDialog,
    TOrmButtons
  },
  props: {
    model: {
      type: Function,
      required: true
    },
    returnField: {
      type: Boolean,
      default: false
    },
    returnedFieldName: {
      type: String,
      default: null
    },
    allSelectable: {
      type: Boolean,
      default: false
    },
    selectedVal: {
      type: null,
      default: null
    }
  },
  data: () => ({
    title: null,
    localLoading: false,
    active: []
  }),
  computed: {
    ormButtons () {
      return [
        {
          text: 'Select',
          attrs: {
            class: 'main-button',
            depressed: true
          },
          call: this.select
        }
      ]
    },
    rootItems () {
      return this.model.query().withAllRecursive(10).where(this.model.ormTreeConfig.parentFieldName, null).get()
    }
  },
  methods: {
    updateActive (items) {
      this.active = []
      if (items.length) {
        if (this.allSelectable) {
          this.active.push(items[0])
        } else if (!this.model.query().whereId(items[0]).get()[0][this.model.ormTreeConfig.hasChildrenFlagName]) {
          this.active.push(items[0])
        }
      }
    },
    async loadChildren (item) {
      if (this.model.ormTreeConfig.asyncLoading && item[this.model.ormTreeConfig.hasChildrenFlagName]) {
        await this.model.api()[this.model.ormTreeConfig.asyncLoading.requestMethod](item.id)
      }
    },
    clear (immediate = false) {
      immediate ? this.item = {} : setTimeout(() => {
        this.item = {}
      }, 300)
    },

    close () {
      this.$emit('input', false)
      this.clear()
    },

    select () {
      if (!this.active.length) { return }
      const data = this.model.query().whereId(this.active[0]).get()[0]
      this.$emit(['update', 'selectedVal'].join(':'), this.returnField ? data[this.returnedFieldName] : data)
      this.close()
    },

    fill (title) {
      this.title = title
    }
  }

}
</script>

<style scoped>

</style>
