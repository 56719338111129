<template lang="pug">
  div(:class="{ 'e-input-file-content': isShowAppendIcon }")
    ValidationProvider(:rules="rules" v-slot="{ errors }" :name="label" ref="validation" v-if="!hideProvider")
      v-file-input(v-bind="$attrs" v-model="localValue" :error-messages="errors" :label="label" ref="file" :append-icon="isShowAppendIcon")
    v-file-input(v-bind="$attrs" v-model="localValue" :label="label" ref="file" v-else :append-icon="isShowAppendIcon")
</template>

<script>

export default {
  inheritAttrs: false,
  props: {
    value: {
      type: null,
      default: null
    },
    rules: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    hideProvider: {
      type: Boolean,
      default: false
    },
    expect: {
      type: String,
      default: 'text',
      validate: val => ['text', 'dataUrl', 'arrayBuffer', 'binaryString', 'blob', 'file'].includes(val)
    }
  },
  data: () => ({
    localValue: null,
    isShowAppendIcon: '$file'
  }),
  watch: {
    async localValue (to, prev) {
      if (to instanceof File) {
        this.isShowAppendIcon = ''
        if (this.expect === 'file') {
          this.$emit('input', to)
        } else {
          const expect = this.expect.charAt(0).toUpperCase() + this.expect.slice(1)
          const data = await this[`readFileAs${expect}`](to)
          this.$emit('input', data)
        }
      } else {
        this.isShowAppendIcon = '$file'
        this.$emit('input', null)
      }
    }
  },
  created () {
    // TODO maybe this is redundant
    this.localValue = this.value
  },
  methods: {
    readFileAsText (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsText(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    readFileAsDataUrl (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    readFileAsArrayBuffer (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    readFileAsBinaryString (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsBinaryString(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    readFileAsBlob (file) {
      return new Promise((resolve, reject) => {
        resolve(file)
      })
    },
    validate () {
      return this.$refs.validation.validate()
    }
  }
}
</script>

<style scoped lang="scss">
.e-input-file-content {
  &::v-deep {
    .v-text-field {
      &__slot {
        position: relative;
        z-index: 2;
      }
    }
    .v-input {
      &__append-inner {
        position: absolute;
        right: 12px;
        z-index: 1;
      }
    }
  }
}
</style>
