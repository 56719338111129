import { get } from 'lodash'
import Base64js from 'base64-js'
import Employees from '~/modules/employees/models/Employees'
import { RepetitiveFunction } from '~/services/_utils/RepetitiveFunction'
export default class ProcessingSignAgent {
  constructor (cashierToken, signInterface, autoDestroy = {
    enable: true,
    timeout: 20000
  }) {
    this.cashierToken = cashierToken
    this.signModule = signInterface
    this.autoDestroy = autoDestroy
  }

  start = () => {
    if (!this.process) {
      this.process = new RepetitiveFunction(this.createProcess())
    }
    this.process.start()
    if (this.autoDestroy.enable) {
      this.destroyTimeOut = setTimeout(() => {
        if (this.process) { this.process.stop() }
      }, this.autoDestroy.timeout)
    }
  }

  stop = () => {
    if (this.process) {
      this.process.stop()
    }
    clearTimeout(this.destroyTimeOut)
  }

  createProcess = () => {
    return async () => {
      const transaction = get(await Employees.api().processingGetPendingTransactions(this.cashierToken), 'response.data.results[0]')
      if (transaction) {
        const dataForSign = get(await Employees.api().processingGetTransaction(this.cashierToken, transaction.id), 'response.data.request_data')
        const signedData = await this.signModule.sign(Base64js.toByteArray(dataForSign))
        await Employees.api().processingUpdateTransaction(this.cashierToken, transaction.id, { request_signature: signedData })
        return true
      } else {
        return false
      }
    }
  }

  signSeparateTransaction = async (id) => {
    const transaction = get(await Employees.api().processingGetTransaction(this.cashierToken, id), 'response.data')
    if (transaction && transaction.status === 'PENDING') {
      const signedData = await this.signModule.sign(Base64js.toByteArray(transaction.request_data))
      await Employees.api().processingUpdateTransaction(this.cashierToken, id, { request_signature: signedData })
      return true
    } else {
      return false
    }
  }
}
