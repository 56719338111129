<template lang="pug">
  v-tooltip(v-bind="$attrs" content-class="tooltip-hint")
    template(#activator="{on, attrs}")
      e-svg-icon(v-on="on" v-bind="attrs" @click="onClick" :size="iconSize" :class="classes" :style="styles") {{ icon }}
    slot
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  components: {
    ESvgIcon
  },
  props: {
    icon: {
      type: String,
      default: 'info-mark'
    },
    iconSize: {
      type: String,
      default: 'sm'
    },
    classes: {
      type: String,
      default: ''
    },
    styles: {
      type: Object,
      default: () => {}
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style scoped lang="scss">

</style>
