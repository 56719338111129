<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="listeners" type="card" max-width="1170px" content-class="m-orm-card-dialog")
    template(#title)
      span(class="text-center d-inline-block") {{ title }}

    template(#content)
      ValidationObserver(:ref="model.entity" slim)
        t-orm-fields(v-bind.sync="fields" :items="model.ormFields" :tabs="model.ormTabs" :context="context" :config="dialogConfig")

    template(#actions)
      t-orm-buttons(:items="ormButtons")
</template>

<script>
import TDialog from '~/components/templates/t-dialog'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'

export default {
  components: {
    TDialog,
    TOrmButtons,
    TOrmFields
  },
  props: {
    type: {
      type: String,
      required: true
    },
    model: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    title: null,
    item: {},
    fields: {},
    localLoading: false
  }),
  computed: {
    context () {
      return this.$config.contexts.update
    },
    dialogConfig () {
      return this.model.getOrmDialogsConfig(this.type)
    },
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.$emit('input', event)
            vm.clear()
          }
        }
      )
    },
    ormButtons () {
      return [
        {
          text: 'Cancel',
          attrs: {
            color: 'primary',
            text: true,
            depressed: true,
            class: ['mr-2']
          },
          call: this.close
        },
        {
          text: 'Save',
          attrs: {
            color: 'primary',
            depressed: true
          },
          loading: this.localLoading,
          call: this.save
        }
      ]
    }
  },
  created () {
    this.$set(this.$data, 'fields', this.model.getOrmFieldsObject())
  },
  methods: {
    clear (immediate = false) {
      immediate ? this.item = {} : setTimeout(() => {
        this.item = {}
      }, 300)
    },

    close () {
      this.$emit('input', false)
      this.clear()
    },

    async save () {
      if (!await this.$refs[this.model.entity].validate()) {
        return false
      }

      this.localLoading = true

      // try {
      //   await this.model.api().update(this.item, { active: false }, false)
      //
      //   const config = this.model.getOrmDialogsConfig(this.type).notification(this.item)
      //   config.type = this.$tc(config.type, 3)
      //   this.$notification.success(this.$t('m-orm-delete-dialog.notification', config))
      //   this.close()
      // } catch (e) {
      //   this.$handlers.error(e, this.$refs[this.model.entity])
      // }
      this.localLoading = false
    },

    fill (item, title) {
      this.title = title
      this.item = item
      this.$set(this.$data, 'fields', this.model.getOrmFieldsObject(item))
    }
  }
}
</script>

<style lang="sass">
  .m-orm-card-dialog
    //
</style>
