import Dialog from '~/models/system/Dialog'

const confirmationDialog = {
  computed: {
    confirmationDialog () {
      return Dialog.query().where('type', 'confirmation').first()
    }
  }
}
export default confirmationDialog
