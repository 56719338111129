<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="listeners" type="confirmation" max-width="495px" )
    template(#title)
      div(class="text-center d-inline-block") {{ title }}
    template(#content)
      ValidationObserver(:ref="model.entity" slim)
        ValidationProvider(v-bind="provider" :name="name" v-slot="{ errors }")
          e-input-password(is-visible v-model="password" :label="$t('New password')" :error-messages="errors")
    template(#actions)
      t-orm-buttons(:items="ormButtons" :classes="['text-center']")
</template>

<script>
import TDialog from '~/components/templates/t-dialog'
import EFlatIcon from '~/components/elements/icons/e-flat-icon'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import EInputPassword from '~/components/elements/inputs/e-input-password'

export default {
  components: {
    EInputPassword,
    TDialog,
    EFlatIcon,
    TOrmButtons
  },
  props: {
    type: {
      type: String,
      required: true
    },
    model: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    title: null,
    item: null,
    localLoading: false,
    password: null,
    provider: {
      vid: 'newPassword',
      name: 'New password',
      rules: 'required|min:6'
    }
  }),
  computed: {
    name () {
      const name = this._.get(this.provider, 'name', '')
      const splitName = name.split('|')

      return (splitName.length >= 2)
        ? `"${this._.upperFirst(this.$tc(splitName[0], splitName[1]))}"`
        : `"${this._.upperFirst(this.$tc(name, 1))}"`
    },
    dialogConfig () {
      return this.model.getOrmDialogsConfig(this.type)
    },
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.$emit('input', event)
            vm.clear()
          }
        }
      )
    },
    ormButtons () {
      return [
        {
          text: 'Cancel',
          attrs: {
            color: 'primary',
            depressed: true,
            text: true
          },
          call: this.close
        },
        {
          text: 'Reset',
          attrs: {
            color: 'error',
            depressed: true,
            class: ['mr-4']
          },
          loading: this.localLoading,
          call: this.resetPassword
        }
      ]
    }
  },
  methods: {
    clear (immediate = false) {
      immediate ? this.item = {} : setTimeout(() => {
        this.item = {}
        this.password = null
      }, 300)
    },

    close () {
      this.$emit('input', false)
      this.clear()
    },

    async resetPassword () {
      if (!await this.$refs[this.model.entity].validate()) {
        return false
      }
      this.localLoading = true
      try {
        await this.model.api().update(this.item, { password: this.password })

        this.$notification.success(this.$t('m-orm-reset-password-dialog.notification'))
      } catch (e) {
        this.$handlers.error(e, this)
      }
      this.localLoading = false
    },

    fill (item, title) {
      this.item = item
      this.title = title
    }
  }
}
</script>

<style lang="sass">
</style>
