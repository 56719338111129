<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="listeners" :max-width="cardWidth")
    template(#title) {{ title }}
    template(#content)
      m-form-supply(:context="context" :item="item" :type="itemType" @dialog:close="close" :multiple="multiple")
</template>

<script>
import TDialog from '~/components/templates/t-dialog'
import fill from '~/mixins/modules/dialogs/fill'
import checkPropCtx from '~/mixins/methods/checkPropCtx'
import MFormSupply from '~/modules/goods/views/components/m-form-supply'

export default {
  components: {
    MFormSupply,
    TDialog
  },
  mixins: [checkPropCtx, fill],
  props: {
    type: {
      type: String,
      required: true
    },
    model: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    item: {},
    itemType: null,
    multiple: false
  }),
  computed: {
    dialogCtx () {
      return Object.assign({}, {
        parent: this.selectedItem,
        dialogRefs: this.$refs,
        model: this.model.entity
      }, { ...this.ctx })
    },
    dialogConfig () {
      return {
        ...this.model.getOrmDialogsConfig(this.type),
        ctx: this.dialogCtx
      }
    },
    context () {
      return this._.get(this.dialogConfig.config, 'context', this.parentContext || this.$config.contexts.create)
    },
    cardWidth () {
      return [700, 'px'].join('')
    },
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.$emit('input', event)
          }
        }
      )
    },
    title () {
      let name
      if (this.itemType === 'supply') {
        switch (this.context) {
          case this.$config.contexts.create:
            name = 'New supply'
            break
          case this.$config.contexts.update:
            name = ''
            break
        }
      } else if (this.itemType === 'writeOff') {
        switch (this.context) {
          case this.$config.contexts.create:
            name = 'New write-off'
            break
          case this.$config.contexts.update:
            name = ''
            break
        }
      }
      return this.$t(name)
    }
  },
  methods: {
    close () {
      this.$emit('input', false)
    },
    afterFill ({ type, item, multiple = false }) {
      this.$set(this.$data, 'itemType', type)
      this.$set(this.$data, 'item', item)
      this.$set(this.$data, 'multiple', multiple)
    }
  }
}
</script>

<style scoped lang="scss">
//
</style>
