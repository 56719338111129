export default superclass => class extends superclass {
  static persistAble = true
  static persistBy = 'create'

  static persistOptions = {
    create: [],
    insert: [],
    update: [],
    insertOrUpdate: []
  }

  static getPersistBy () {
    return this.persistBy
  }

  static getPersistOptions () {
    return Object.fromEntries(
      Object.entries(this.persistOptions).map(([param, arr]) => arr.length ? [param, arr] : [])
    )
  }
}
