<template lang="pug">
  v-tooltip(top)
    template(#activator="{on}")
      v-btn(icon v-on="on")
        e-svg-icon()
          | info-mark
    slot
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
export default {
  components: { ESvgIcon }
}
</script>

<style scoped lang="scss">

</style>
