<template lang="pug">
  v-row
    v-col(cols="12" class="py-0")
      div(class="relative pl-4")
        p(class="e-tree-select__title mb-1") {{ $t($attrs.label) }}

    v-col(cols="12" class="py-0")
      v-card(class="elevation-0 e-tree-select mb-5")
        v-card-text(class="pa-0 e-tree-select__text")
          v-treeview(v-if="!loading"
            dense
            :open-on-click="false"
            v-model="objects"
            :items="list"
            selected-color="success"
            transition
            selectable
            return-object
            expand-icon="mdi-chevron-down"
            on-icon="mdi-lock-open"
            off-icon="mdi-lock"
            indeterminate-icon="mdi-lock-plus")
            template(#append="scoped")
              v-tooltip(top)
                template(v-slot:activator="{ on }")
                  div(v-on="on")
                    v-checkbox(v-if="scoped.leaf"
                      v-model="onlyRead"
                      :disabled="!scoped.selected"
                      :value="objectReadPermission(scoped.item)"
                      multiple
                      color="success"
                      hide-details
                      class="ma-0 pa-0"
                      :ripple="false")
                span {{ $t('Only view') }}

          e-progress-linear(v-show="loading" size="sm")
</template>

<script>
import { VTreeview, VCheckbox, VTooltip } from 'vuetify/lib'
import EProgressLinear from '~/components/elements/progress/e-progress-linear'
import Permission from '~/models/auth/Permission'

export default {
  components: {
    VTreeview,
    EProgressLinear,
    VCheckbox,
    VTooltip
  },
  inheritAttrs: false,
  props: {
    value: {
      type: [Array, null],
      default: null
    },
    items: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    objects: [],
    permissions: [],
    onlyRead: [],
    default: []
  }),
  computed: {
    list () {
      return this.items.map(item => ({
        id: item.id,
        name: this.getName(item),
        children: this.getChildren(item)
      }))
    },
    permList () {
      return Permission.all()
    }
  },
  watch: {
    objects () {
      this.updatePermissions()
    },
    onlyRead () {
      this.updatePermissions()
    },
    loading (cur) {
      if (!cur && this.value) {
        this.default = this.value
        this.objects = this._.uniqBy(this.value.map(perm => perm.object), 'id')
      }
    }
  },
  methods: {
    objectPermissions (object) {
      return this.permList.filter(p => p.object.key === object.key)
    },
    objectReadPermission (object) {
      const selected = this.objectPermissions(object).filter(p => p.action.key === 'read')
      return selected.length ? selected[0] : null
    },
    updatePermissions () {
      if (this.default.length) {
        this.permissions = this.default.slice()
        const groupedByModelName = this._.groupBy(this.value, item => item.object.key)

        for (const modelName in groupedByModelName) {
          const model = groupedByModelName[modelName]
          const firstAction = model[0]

          if (model.length === 1 && firstAction.action.key === 'read') {
            this.onlyRead.push(this._.find(this.permList, ['id', firstAction.id]))
          }
        }
        this.default = []
      } else {
        this.permissions = []

        for (const objectIndex in this.objects) {
          const object = this.objects[objectIndex]

          if (object) {
            const readPerm = this.onlyRead.find((i) => {
              if (!i) {
                return false
              }
              return i.object.id === object.id
            })

            !readPerm
              ? this.permissions.push(...this.objectPermissions(object))
              : this.permissions.push(readPerm)
          } else {
            this.onlyRead = []
          }
        }
      }

      this.$emit('input', this.permissions)
    },
    getChildren (item) {
      const items = []

      for (const cItem of item.objects) {
        if (cItem.$id === item.$id) { continue }

        items.push({
          ...cItem,
          name: this.getName(cItem)
        })
      }

      return items.sort((a, b) => {
        return a.name > b.name ? 1 : -1
      })
    },
    getName (item) {
      return item.name
    }
  }
}
</script>

<style lang="scss">
  .e-tree-select {
    background: $gray-bg-color !important;
    border-radius: 3px !important;
    border: 1px solid $gray-bg-border-color !important;
    position: relative;

    &__title {
      font-size: 1rem;
      color: #484B4D !important;

      &:before {
        content: '*';
        position: absolute;
        left: 0;
        color: var(--v-primary-base);
        font-size: 1.5rem;
      }
    }

    &__text {
      min-height: 40px;
    }
  }
</style>
