import { Model } from '@vuex-orm/core'
import User from '~/models/administration/User'

export default class CurrentUser extends User {
  static entity = 'currentuser'

  static fields () {
    return super.fields()
  }

  static apiConfig = {
    get actions () {
      const configActions = User.apiConfig.actions
      configActions.getCurrent = function () {
        return this.get(Model.$routes.users.profile(), {
          dataKey: null,
          save: true,
          persistBy: 'create',
          persistOptions: {
            insertOrUpdate: ['organization']
          }
        })
      }
      return configActions
    }
  }
}
