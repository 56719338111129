<template lang="pug">
  div
    p(class="mb-6") {{ $t('You are trying to sell more items than your inventory') }}
    div(class="btns-wrap pb-3")
      v-btn(
        class="secondary-button color-error"
        @click="disableLeftovers"
        :loading="loading"
      ) {{ $t('Disable the use of leftovers') }}
      v-btn(
        class="main-button"
        @click="addLeftovers"
        :disabled="loading"
        black
      ) {{ $t('Add leftovers') }}
</template>

<script>
import AccountingGoodsSettings from '~/modules/goods/models/AccountingGoodsSettings'

export default {
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    loading: false
  }),
  methods: {
    addLeftovers () {
      this.closeModal(false)
      this.$router.push('/dashboard/goods')
    },
    async disableLeftovers () {
      try {
        this.loading = true
        await AccountingGoodsSettings.api().setSettings({
          use_leftovers: false
        })
        this.closeModal(true)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.btns-wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}
</style>
