<template lang="pug">
  v-tooltip(
    :top="position === 'top'"
    :left="position === 'left'"
    :bottom="position === 'bottom'"
    :right="position === 'right'"
  )
    template(#activator="{on}")
      v-btn(
        v-on="on"
        @click="onClick"
        class="secondary-button"
        :loading="loading"
        outlined
      )
        e-svg-icon(size="lg") done-all-2
    | {{ $t('Mark all as read') }}
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import Notifications from '~/modules/notifications/models/Notifications'

export default {
  name: 'ButtonReadAllNotifications',
  components: {
    ESvgIcon
  },
  props: {
    onReadAll: {
      type: Function,
      default: () => {}
    },
    position: {
      type: String,
      default: 'top'
    }
  },
  data: () => ({
    loading: false
  }),
  methods: {
    async onClick () {
      try {
        this.loading = true
        await Notifications.api().readAll()
        if (this._.isFunction(this.onReadAll)) {
          await this.onReadAll()
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
