import Search from '~/models/abstracts/Search'
import { formats } from '~/const/global'

export class InvoicesSearch extends Search {
  static entity = 'invoicessearch'

  static fields () {
    return {
      id: this.attr(null),
      number: this.attr(null),
      date: this.attr(null)
    }
  }

  toString () {
    const date = this.date && this.$moment(this.date).format(formats.date)
    return date ? `Рахунок №${this.number} від ${date}` : `Рахунок №${this.number}`
  }
}

export default InvoicesSearch
