/* global google */

export default class GooglePayService {
  paymentClient = null
  price = null
  config = null
  callback = null

  constructor (config, callback) {
    this.config = config
    this.callback = callback
  }

  createClient () {
    this.paymentClient = new google.payments.api.PaymentsClient({ environment: this.config.environment })
  }

  // price in penny
  setTotalPrice (price) {
    this.price = price
  }

  async isReadyToPay () {
    try {
      const result = await this.paymentClient.isReadyToPay(this.config.isReadyToPayRequestData)
      if (result.result) {
        return true
      }
      return false
    } catch (e) {
      return false
    }
  }

  createButton () {
    return this.paymentClient.createButton(
      {
        buttonColor: 'black',
        buttonType: 'plain',
        buttonLocale: 'uk',
        buttonSizeMode: 'fill',
        onClick: this._onClickPaymentButton()
      }
    )
  }

  _onClickPaymentButton () {
    return async () => {
      const paymentDataRequest = Object.assign({}, this.config.paymentClearDataRequest)
      paymentDataRequest.transactionInfo = {
        totalPriceStatus: 'FINAL',
        totalPrice: (this.price / 100).toString(),
        currencyCode: 'UAH',
        countryCode: 'UA'
      }
      paymentDataRequest.merchantInfo = {
        merchantName: this.config.merchantName,
        merchantId: this.config.merchantId
      }

      try {
        const tokenData = await this.paymentClient.loadPaymentData(paymentDataRequest)
        const token = tokenData.paymentMethodData.tokenizationData.token ?? null
        this.callback(token)
      } catch (e) {
        this.callback(null, e)
      }
    }
  }
}
