import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'

export class Referrals extends ChainInheritance(Directory, []) {
  static entity = 'referrals'
  static paginated = true
  static ormLoadWithRelations = true

  static ormTrans = {
    single: 'Referral',
    multy: 'Referrals'
  }

  static fields () {
    return {
      name: this.attr(null),
      status: this.attr(null),
      payment_date: this.attr(null),
      registration_date: this.attr(null)
    }
  }

  static ormHeaders = [
    { text: 'Status', value: 'status', sortable: false, filterable: false },
    { text: 'Name', value: 'name', sortable: false, filterable: false },
    { text: 'Date', value: 'payment_date', sortable: false, filterable: false },
    { text: 'Date', value: 'registration_date', sortable: false, filterable: false }
  ]
}

export default Referrals
