<template lang="pug">
  v-toolbar(flat :color="color" height="auto" class="m-orm-toolbar white")
    slot
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    }
  }
}
</script>

<style lang="scss">
  .m-orm-toolbar {
    .v-toolbar__content {
      padding: 0
    }
  }
</style>
