<template lang="pug">
  v-tooltip(top)
    template(v-slot:activator="{ on }")
      v-btn(v-on="on" text class="e-button-help title primary--text")
        span
          e-svg-icon(class="e-button-help__icon") question
          span(class="pl-1 e-button-help__text bold") {{ $t('Help section') }}

    span {{ $t('A help section is under development') }}
</template>

<script>
import { VTooltip } from 'vuetify/lib'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  // TODO: use toolbar and link component!
  components: {
    VTooltip,
    ESvgIcon
  }
}
</script>

<style lang="scss">
  .e-button-help {
    &__icon {
      vertical-align: sub;
    }

    &__text {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        bottom: 1px;
        right: 0;
        width: 98%;
        height: 1px;
        opacity: 0.3;
        background: var(--v-primary-base);
      }
    }
  }
</style>
