<template lang="pug">
  v-row(align="center" justify="center" class="mb-6")
    e-input-file(v-bind="$attrs" accept="image/*" ref="ImageInput" @change="uploaded")
    v-hover
      template(v-slot:default="{ hover }")
        v-img(:src="logo ? logo : require('~/assets/images/avatar.svg')"
          :lazy-src="loading ? require('~/assets/images/avatar.svg') : undefined"
          contain
          aspect-ratio="1"
          max-width="150"
          max-height="150"
          @click="$refs.ImageInput.click()"
          class="mx-auto e-input-image")

          template
            e-overlay-block(:loading="loading" :hover="hover")

          template(#placeholder)
            e-overlay-block(:hover="true")
</template>

<script>
import { VHover } from 'vuetify/lib'
import EOverlayBlock from '~/components/elements/overlays/e-overlay-block'
import EInputFile from '~/components/elements/inputs/e-input-file'
import MediaObject from '~/models/system/MediaObject'

export default {
  components: {
    EInputFile,
    VHover,
    EOverlayBlock
  },
  props: {
    value: {
      type: null,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    logo: null,
    loading: false
  }),
  created () {
    if (this.value) {
      const item = this._.isArray(this.value) ? this.value[this.value.length - 1] : this.value

      if (item) {
        this.logo = item.contentUrl

        const media = item instanceof MediaObject ? item : new MediaObject(item)

        this.$emit('input', this.multiple ? [media] : media)
      }
    }
  },
  methods: {
    uploaded (file) {
      // this.toBase64(file).then((row) => {
      //   this.logo = row
      // })

      const data = new FormData()
      data.append('files[]', file, file.name)

      this.save(data)
    },

    async save (data) {
      this.loading = true
      try {
        const res = await MediaObject.api().create(data, false)

        if (res.entities.mediaobject.length) {
          const lastIndex = res.entities.mediaobject.length - 1

          this.logo = res.entities.mediaobject[lastIndex >= 0 ? lastIndex : 0].contentUrl
        }

        this.$emit('input', this.multiple ? res.entities.mediaobject : res.entities.mediaobject[0])
      } catch (e) {
        //
      }
      this.loading = false
    },

    toBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = this.toFileReader(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },

    toFileReader (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      return reader
    }
  }
}
</script>

<style lang="scss" scoped>
  .e-input-image {
    &:hover {
      cursor: pointer;
    }
  }
</style>
