import Base64js from 'base64-js'
import { validate as uuidValidate } from 'uuid'
export function isBase64 (str) {
  try {
    Base64js.toByteArray(str)
    return true
  } catch (err) {
    return false
  }
}

export function isUuid (str) {
  return uuidValidate(str)
}
