<template lang="pug">
  v-col(v-if="isVisible" cols="12" class="py-0")
    v-row
      t-orm-label(v-if="item.label" :item="item")

      v-col(:cols="checkboxCols" class="py-0")
        v-checkbox(:value="value"
                   v-bind="morphedAttrs"
                   @change="$emit('input', $event)"
                   class="mt-0"
                   color="success")

      v-expand-transition
        v-col(v-if="value" cols="12" class="py-0")
          template(v-if="item.attrs && item.attrs.bordered")
            m-group-bordered(class="mb-4")
              slot

          template(v-else)
            slot
</template>

<script>
import { VCheckbox, VExpandTransition } from 'vuetify/lib'
import TOrmLabel from '~/components/templates/orm/_includes/t-orm-label'
import MGroupBordered from '~/components/modules/groups/m-group-bordered'
import ctx from '~/mixins/props/ctx'
import mda from '~/mixins/computed/morphedAttrs'

// TODO: replace "v-checkbox" by "e-input-wrapper".
export default {
  components: {
    VCheckbox,
    TOrmLabel,
    VExpandTransition,
    MGroupBordered
  },
  mixins: [ctx, mda],
  props: {
    value: {
      type: null,
      default: null
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isVisible () {
      return this._.get(this.morphedAttrs, 'visible', true)
    },
    checkboxCols () {
      return this.item.label ? 7 : 12
    }
  },
  created () {
    const chilModels = this._.map(this._.get(this.item, 'fields', []), 'model')
    const values = Object.values(this._.pick(this.ctx.attrs, chilModels))

    this.$emit('input', !!values.filter(el => ![null, undefined, false].includes(el)).length)
  }
}
</script>

<style lang="scss">
  .e-input-collapse {
    .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }
</style>
