import OrmModel from '~/models/abstracts/base/OrmModel'

export default class Connector {
  isObject (obj) {
    return typeof obj === 'object'
  }

  isOrmModel (target) {
    return target && (this.isOrmModelObj(target) || this.isOrmModelCls(target))
  }

  isOrmModelCls (target) {
    return target.prototype instanceof OrmModel
  }

  isOrmModelObj (target) {
    return target instanceof OrmModel
  }

  isPrimitive (v) {
    return (v !== Object(v))
  }
}
