import { Model } from '@vuex-orm/core'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import DateTimeAble from '~/models/mixins/DateTimeAble'
import SortAble from '~/models/mixins/SortAble'
import ConfigureAble from '~/models/mixins/ConfigureAble'
import ContextAble from '~/models/mixins/ContextAble'

export default class OrmModel extends ChainInheritance(Model, [
  SortAble,
  DateTimeAble,
  ConfigureAble,
  ContextAble
]) {
  // Indicates that current model is paginated(sorts,filters and other stuff) based on server pagination.
  static paginated = false

  get primaryVal () {
    return this[this.$primaryKey()]
  }

  set primaryVal (val) {
    this[this.$primaryKey()] = val
  }

  // Takes all columns except actions column (we render it manually).
  static ormColsExcept = ['actions']

  static texts () {
    return {
      buttons: {
        create: this.ormTrans.single
      },
      page: this.ormTrans.multy,
      drawer: this.ormTrans.multy
    }
  }

  static getFiltrationHeaders () {
    return this.ormHeaders.filter(header => header.filterable)
  }
}
