<template lang="pug">
  v-row(:class="[!noMarginTop && 'mt-6']")
    v-col(cols="12" :class="classes")
      v-row(v-for="(item, index) in items" :key="index")
        v-col(v-bind="titleCols" class="py-0" v-if="item.label")
          span(class="d-inline-block mt-2") {{ $t(item.label) }}

        v-col(v-bind="fieldCols" class="py-0")
          e-input-wrapper(
            :item="item"
            :value="$attrs[item.model]"
            :key="[item.model, '-', index].join('')"
            @input="$emit(['update:', item.model].join(''), $event)")
</template>

<script>
import EInputWrapper from '~/components/elements/inputs/e-input-wrapper'

export default {
  components: {
    EInputWrapper
  },
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      required: true
    },
    classes: {
      type: Array,
      default: () => ([
        ''
      ])
    },
    type: {
      type: String,
      default: 'm-form',
      validator: (value) => {
        return ['m-form', 't-orm-form-dialog'].includes(value)
      }
    },
    cols: {
      type: Object,
      default: () => {}
    },
    noMarginTop: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    titleCols () {
      return (this.type === 'm-form') ? {
        cols: 12,
        md: 3,
        lg: 3,
        xl: 3
      } : {
        cols: 12,
        md: 5,
        lg: 5,
        xl: 5
      }
    },
    fieldCols () {
      return this.cols || ((this.type === 'm-form') ? {
        cols: 12,
        md: 6,
        lg: 6,
        xl: 6
      } : {
        cols: 12,
        md: 7,
        lg: 7,
        xl: 7
      })
    }
  }
}
</script>

<style lang="scss">
  //
</style>
