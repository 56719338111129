const scrollToFailedValidation = {
  methods: {
    scrollToFailedValidation (el) {
      this.$nextTick(() => {
        if (el) {
          const errEl = el.querySelector('.error--text')
          if (errEl) {
            errEl.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }
        }
      })
    }
  }
}

export default scrollToFailedValidation
