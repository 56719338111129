<template lang="pug">
  v-row(v-if="grid && grid.length")
    v-col(cols="12" class="py-0")
      m-grid-node-renderer(v-for="(node, index) in grid" :node="node" :config="config" :key="index")
</template>

<script>
import MGridNodeRenderer from '~/components/modules/groups/m-grid-node-renderer'
export default {
  name: 'MGroupGridRenderer',
  components: {
    MGridNodeRenderer
  },
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  computed: {
    grid () {
      return this.config.grid
    }
  }
}
</script>

<style lang="scss">
  .m-group-grid-renderer {
    //
  }
</style>
