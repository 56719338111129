import { Model } from '@vuex-orm/core'

const resolvers = {}

class Sidebar extends Model {
  static entity = '_sidebar';

  static fields () {
    return {
      id: this.uid(),
      isOpen: this.attr(false),
      type: this.attr(null),
      params: this.attr(null)
    }
  }

  async open (params) {
    const promise = new Promise((resolve, reject) => {
      resolvers[this.id] = {
        resolve,
        reject
      }
    })
    this.isOpen = true
    this.params = params
    await this.$save()
    return promise
  }

  async close () {
    delete resolvers[this.id]
    this.isOpen = false
    this.params = null
    await this.$save()
  }
}

export default Sidebar
