<template lang="pug">
  v-card(class="finish-card")
    v-row(justify="center" align="center" no-gutters)
      v-col(cols="12")
        h2(class="login-wrapper__title mb-8") {{ $t('Completion of registration') }}
        p(class="mb-6") {{ $t('Thank you') }}!
        p(class="mb-0") {{ $t('Go to the portal and start working with the most convenient software checkouts') }}

      v-col(cols="12")
        e-button-main(
          :enableIcon="false"
          @click="$attrs.btnCall"
          :loading="$attrs.loading"
          class="d-block mx-auto mt-8"
        ) {{ $t('Complete registration and go to the portal') }}
</template>

<script>
import EButtonMain from '~/components/elements/buttons/e-button-main'

export default {
  components: {
    EButtonMain
  }
}
</script>

<style scoped lang="scss">
.finish-card {
  border-radius: 24px !important;
  overflow: hidden;
  max-width: 548px;
  margin: 0 auto;
  width: 100%;
  padding: 32px;
  box-shadow: none;
}
</style>
