import { mapAbilities } from '~/store/plugins/vuex-orm'
import { ormConfig } from '~/const/global'

export default (store) => {
  return store.subscribe((mutation) => {
    switch (mutation.type) {
      case 'user/SET_RULES':
        mutation.payload ? store.state.ability.update(mapAbilities(mutation.payload, ormConfig, process.env.mock.abilities)) : store.state.ability.update([])
        break
    }
  })
}
