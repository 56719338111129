import AuthenticatedCashier from '~/modules/receipt/models/AuthenticatedCashier'

const setAuthenticatedCashier = {
  methods: {
    async setAuthenticatedCashier (data, authenticatedCashier) {
      const newData = Object.assign({}, authenticatedCashier || {}, data)
      await AuthenticatedCashier.create({
        data: newData
      })
    }
  }
}

export default setAuthenticatedCashier
