const sz = {
  props: {
    size: {
      type: String,
      default: 'md',
      validator: v => ['xs', 'sm', 'md', 'lg', 'xl'].includes(v)
    }
  }
}

export default sz
