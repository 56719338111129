<template lang="pug">
  div(class="barcode-scanner text-center pt-3 pb-9")
    e-progress-circular(
      v-if="loading"
      width="4"
      size="xl"
      color="#161b25"
    )
    input(
      v-model="barcode"
      ref="input"
      class="barcode-scanner__input"
    )
</template>

<script>
import EOverlayBlock from '~/components/elements/overlays/e-overlay-block'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'

export default {
  components: {
    EProgressCircular,
    EOverlayBlock
  },
  props: {
    onScan: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    barcode: null,
    loading: true
  }),
  watch: {
    barcode () {
      setTimeout(async () => {
        if (this.onScan && typeof this.onScan === 'function') {
          await this.onScan(this.barcode)
        } else {
          this.$emit('scan', this.barcode)
        }
      }, 300)
    }
  },
  mounted () {
    this.focusInput()
    document.addEventListener('click', this.focusInput)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.focusInput)
  },
  methods: {
    focusInput () {
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.barcode-scanner {
  &__input {
    margin: 0;
    padding: 0;
    height: 0;
    overflow: hidden;
    display: block;
  }
}
</style>
