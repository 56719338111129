export default {
  data: () => ({
    apmTransactionType: 'custom'
  }),
  methods: {
    apmRum ({ transactionName, spanName, endTransaction = false, endSpan = false, transactionLabels = {}, spanLabels = {} }) {
      // apm was removed
    }
  }
}
