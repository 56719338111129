<template lang="pug">
  v-row(no-gutters class="t-static-field")
    v-col(cols="3" v-if="'label' in $slots" class="mr-2")
      span(class="d-inline-block text--secondary label")
        slot(name="label")
    v-col()
      slot

</template>

<script>
export default {
  //
}
</script>

<style scoped lang="scss">
  .t-static-field{
    min-height: $text-field-filled-full-width-outlined-slot-min-height + 19px;
  }
</style>
