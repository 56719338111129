<template lang="pug">
  t-dialog(:value="item.visible" @update:returnValue="close")
    template(#title) Some title here

    template(#content) Some text here
</template>

<script>
import base from '~/mixins/modules/dialogs/base'

export default {
  mixins: [base]
}
</script>

<style lang="scss" scoped>
  //
</style>
