<template lang="pug">
  v-row(class="m-group-bordered mx-0")
    v-col(cols="12" class="py-4")
        slot
</template>

<script>
import { VCheckbox } from 'vuetify/lib'
import TOrmLabel from '~/components/templates/orm/_includes/t-orm-label'

export default {
  components: {
    VCheckbox,
    TOrmLabel
  }
}
</script>

<style lang="scss">
  .m-group-bordered {
    background: $gray-bg-color;
    border-radius: 3px;
    border: 1px solid $gray-bg-border-color;
    position: relative;
  }
</style>
