export class RepetitiveFunction {
  prevResult = null
  callbackRunning = false
  constructor (process, isAsync = true, interval = 1000) {
    this.process = process
    this.intervalTime = interval
    this.isAsyncProcess = isAsync
    this.started = false
  }

  start () {
    if (this.interval && this.started) { return }
    this.interval = setInterval(this.intervalFunction, this.intervalTime)
    this.started = true
  }

  stop () {
    if (this.interval) {
      clearInterval(this.interval)
      this.started = false
    }
  }

  intervalFunction = async () => {
    if (this.callbackRunning) { return }
    try {
      this.callbackRunning = true
      if (this.isAsyncProcess) {
        this.prevResult = await this.process(this.prevResult)
      } else {
        this.prevResult = this.process(this.prevResult)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    } finally {
      this.callbackRunning = false
    }
  }
}
