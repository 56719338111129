<template lang="pug">
  component(:is="node.component" v-bind="node.attrs")
    // TODO: double rows here.
    v-row(v-if="isFilled || isTabbed || hasContentComponent")
      m-group-tabs(v-if="isTabbed" :items="tabs" :config="config" :fields="this.config.items")
        template(#default="{ fields, tab }")
          v-col(v-if="tab.grid" cols="12" class="py-0")
            m-group-grid-renderer(:config="tabGridConfig(tab)")

          t-orm-fields-content(v-else v-bind="config.attrs" v-on="config.listeners" :ctx="config.ctx" :items="fields" :context="config.context")

      component(v-else-if="hasContentComponent"
        :is="node.contentComponent.name"
        v-bind="node.contentComponent.attrs"
        v-on="config.listeners"
        :parentItem="parent"
        :config="config")

      t-orm-fields-content(v-else-if="isFilled"
        v-bind="config.attrs"
        v-on="config.listeners"
        :ctx="config.ctx"
        :items="nodeFields"
        :context="config.context")

    template(v-if="node.nodes && node.nodes.length")
      m-grid-node-renderer(v-for="(childNode, index) in node.nodes"
        :key="index"
        :node="childNode"
        :config="config")
</template>

<script>
import { VCol, VRow } from 'vuetify/lib'
import TOrmFieldsContent from '~/components/templates/orm/t-orm-fields-content'
import MGroupTabs from '~/components/modules/groups/m-group-tabs'
// TODO: remove it, all this stuf must be renderred by concrete component
import MOrmTableWrapper from '~/components/modules/tables/wrappers/m-orm-table-wrapper'
import TOrmFieldsWrapper from '~/components/templates/wrappers/t-orm-fields-wrapper'
import TOrmFieldsContentWrapper from '~/components/templates/wrappers/t-orm-fields-content-wrapper'
import MOrmViewTable from '~/components/modules/tables/orm/m-orm-view-table'

export default {
  name: 'MGridNodeRenderer',
  components: {
    VRow,
    VCol,
    TOrmFieldsContent,
    MGroupTabs,
    MOrmTableWrapper,
    MOrmViewTable,
    TOrmFieldsWrapper,
    TOrmFieldsContentWrapper
  },
  props: {
    node: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  },
  computed: {
    parent () {
      return this._.get(this.config, 'ctx.parent', null)
    },
    isTabbed () {
      return this.tabs && this.tabs.length
    },
    tabs () {
      // TODO: CONTEXT!
      const context = this._.get(this.config.config, 'context', [])

      if (this.node.tabs) {
        return this.node.tabs.filter((tab) => {
          if (tab.visible) {
            return this._.isFunction(tab.visible) ? tab.visible(this.parent) : tab.visible
          }
          if (!('contexts' in tab)) {
            return true
          }

          return tab.contexts.includes(context)
        })
      }

      return this.node.tabs
    },
    isFilled () {
      return this.nodeFields.length
    },
    hasContentComponent () {
      return !!this.node.contentComponent
    },
    nodeFields () {
      return this._.intersectionWith(this.config.items, this.node.fields, (arrVal, othVal) => arrVal.model === othVal)
    }
  },
  methods: {
    tabGridConfig (tab) {
      return Object.assign({}, this.config, {
        grid: tab.grid
      })
    }
  }
}
</script>

<style lang="scss">
  .m-group-grid-renderer {
    //
  }
</style>
