<template lang="pug">
  v-row(no-gutters class="mb-6")
    v-col(class="e-input-dynamic")
      v-row
        v-col(cols="12" class="py-0 pb-2")
          h4(class="title font-weight-regular wb-bw") {{ $t(header, {max}) }}

      v-row(v-for="index in count" :key="index" no-gutters)
        // t-orm-label(:item="{label: fieldsLabels[index-1]}")

        v-col(cols="12" md="12" lg="12" xl="12" class="py-0")
          v-row(no-gutters)
            v-col(class="py-0 e-input-dynamic__field")
              ValidationProvider(v-bind="provider" :name="errorName(fieldsLabels[index-1])" v-slot="{ errors }")
                component(:is="fieldComponent"
                          v-bind="fieldAttrs"
                          :name="getFieldName(index)"
                          :value="dynamicValues[index-1]"
                          :label="$t(fieldsLabels[index-1])"
                          :error-messages="errors[0]"
                          @input="emitFieldInput($event, index)")

              e-button-close(v-if="isLast(index)"
                            size="xs"
                            class="e-input-dynamic__close-btn"
                            @click="removeField(index)")

      v-row(v-if="count < max" justify="start" no-gutters)
        v-col(cols="12" md="8" lg="7" xl="8" class="py-0")
          e-link(size="lg" @click="addField") + {{$t('Add row')}}
</template>

<script>
import { VSelect, VTextField, VCheckbox, VAutocomplete, VTextarea, VFileInput } from 'vuetify/lib'
import { ValidationProvider } from 'vee-validate'
import TOrmLabel from '~/components/templates/orm/_includes/t-orm-label'
import EButtonClose from '~/components/elements/buttons/e-button-close'
import ELink from '~/components/elements/links/e-link'

// TODO apply validation and improve this component
export default {
  components: {
    VTextField,
    VSelect,
    VCheckbox,
    VAutocomplete,
    VTextarea,
    VFileInput,
    TOrmLabel,
    EButtonClose,
    ELink,
    ValidationProvider
  },
  props: {
    header: {
      type: String,
      required: true
    },
    fieldComponent: {
      type: String,
      required: true
    },
    initialCount: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 10
    },
    fieldNames: {
      type: [Array, String],
      default: 'Dynamic'
    },
    fieldAttrs: {
      type: Object,
      default: null
    },
    fieldProvider: {
      type: Object,
      default: null
    },
    fieldsLabels: {
      type: [Array, String],
      default: 'label'
    },
    label: {
      type: String,
      default: 'Add field'
    },
    values: {
      type: Object,
      default: () => ({})
    },
    namePattern: {
      type: String,
      default: '{name}{index}'
    }
  },
  data () {
    return {
      count: Object.values(this.values).filter(o => o).length || this.initialCount,
      dynamicValues: []
    }
  },
  computed: {
    provider () {
      const provider = Object.assign({}, this.fieldProvider)

      for (const key in this.fieldProvider) {
        provider[key] = this._.isFunction(this.fieldProvider[key])
          ? this.fieldProvider[key](this.ctx)
          : this.fieldProvider[key]
      }

      return provider
    }
  },
  created () {
    for (let i = 1; i <= this.count; i++) {
      this.dynamicValues.push(this.fieldValue(i) || '')
    }
  },
  methods: {
    getFieldName (index) {
      const name = typeof this.fieldNames === 'string' ? this.fieldNames : this.fieldNames[index - 1]
      return this.namePattern.replace('{name}', name).replace('{index}', index)
    },
    addField () {
      this.count++
      this.dynamicValues.push('')
    },
    removeField (index) {
      this.dynamicValues.splice(index - 1, 1)
      this.dynamicValues.push(null)
      for (let i = index; i <= this.count; i++) {
        this.emitFieldInput(this.dynamicValues[i - 1], i, false)
      }
      this.count--
      this.dynamicValues.pop()
    },
    emitFieldInput (value, index, updateValues = true) {
      if (updateValues) { this.dynamicValues.splice(index - 1, 1, value) }
      // TODO maybe rethink this logic
      const fieldName = this.getFieldName(index)
      this.$emit('update:currentDynamicField', fieldName)
      this.$emit('input', value)
      this.$emit(['update:', fieldName].join(''), value)
    },

    fieldValue (index) {
      const fieldName = this.getFieldName(index)
      return this.values[fieldName]
    },
    // Checkers
    isLast (index) {
      return this.count === index
    },
    errorName (name) {
      return `"${this.$t(name).toLowerCase()}"`
    }
  }
}
</script>

<style lang="scss" scoped>
  .e-input-dynamic {
    &__field {
      position: relative;
      padding-right: 34px !important;
    }

    &__close-btn {
      position: absolute;
      top: 11px;
      right: 0;
    }
  }
</style>
