import MGroupTree from '~/components/modules/groups/m-group-tree'

const mgtsfc = {
  components: {
    MGroupTree
  },
  data: () => ({
    tree: {
      checked: []
    },
    selectedGroup: null,
    filters: null
  }),
  computed: {
    treeConfig () {
      return {
        filters: this.groupModel.getOrmFiltersObject(this.filters, true)
      }
    }
  }
}

export default mgtsfc
