export default superclass => class extends superclass {
  // Field for soft deletable models.
  static fields (fields) {
    const obj = Object.assign({
      active: this.attr(true)
    }, fields)

    return super.fields.length ? super.fields(obj) : obj
  }
}
