<template lang="pug">
  v-row
    v-col(cols="12" class="py-0")
      v-data-table(v-bind="tableConfig"
        :options.sync="options"
        :headers="tableHeaders"
        :items="rows"
        :loading="isLoading"
        v-model="selected"
        :loading-text="$t('Loading... Please wait')"
        :single-select="config.singleSelect"
        :item-key="config.itemKey || 'name'"
        class="elevation-0 m-orm-list-table"
        height="auto"
        show-select
        multi-sort)

        template(v-slot:top)
          v-col(v-if="model.isFilterable || model.ormFilters" cols="12" class="py-0")
            t-orm-filters(v-model="filters" :model="model")

        template(v-slot:item="scopedItemProps" v-if="config.actions")
          t-orm-item(:scopedItem="scopedItemProps"
            :model="model"
            :config="tableItemConfig"
            context="orm-select-dialog")
</template>

<script>
import pagination from '~/mixins/tables/pagination'
import filtration from '~/mixins/tables/filtration'
import searching from '~/mixins/tables/searching'
import rows from '~/mixins/tables/rows'
import actions from '~/mixins/tables/actions'
import TOrmItem from '~/components/templates/orm/t-orm-item'

export default {
  name: 'MOrmSelectTable',
  components: {
    TOrmItem
  },
  mixins: [pagination, filtration, searching, rows, actions],
  props: {
    value: {
      type: [Array, null],
      default: () => null
    },
    model: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    // TODO: create unary config property.
    defaultFilter: {
      type: Object,
      default: null
    },
    async: {
      type: Boolean,
      default: false
    },
    short: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    selected: [],
    tableLoading: false
  }),
  computed: {
    isLoading () {
      return this.tableLoading || this.loading
    },
    modelTableConfig () {
      // NOTE: Config will be cached once.
      return this.model.getTableConfig()
    },
    tableConfig () {
      return Object.assign(this.$config.table, this.modelTableConfig, this.$pagination, this.$searching)
    },
    tableHeaders () {
      let headers = this.model.ormHeaders

      if (this.short && this.model.isShortAble) {
        headers = headers.filter(header => this.model.getShortHeaders().includes(header.value))
      }

      return this._.map(headers, item => (Object.assign({}, {
        ...item
      }, {
        text: this.$t(item.text)
      })))
    },
    tableItemConfig () {
      return {
        checkbox: true,
        short: true,
        disabled: scopedItem => !scopedItem.isSelected,
        dblclick: (value, scopedItem) => {
          if (!this.selected.includes(value)) {
            if (this.config.singleSelect) {
              this.selected = [value]
            } else {
              this.selected.push(value)
            }
          } else {
            this.selected.splice(this.selected.map(item => item.$id).indexOf(value.$id), 1)
          }
        },
        actions: this.$actions
      }
    }
  },
  watch: {
    selected (val) {
      this.$emit('input', val)
    }
  },
  created () {
    if (this.short) {
      this.selected = this.value
    }
  },
  methods: {
    clear () {
      this.selected = []
    }
  }
}
</script>

<style lang="scss">
.v-data-table {
  &__wrapper {
    > table {
      overflow: hidden;
    }
  }
}
</style>
