<template lang="pug">
  v-row
    v-col(cols="12" :class="classes" class="py-0")
      v-btn(
        icon
        v-for="(item, index) in items"
        :key="index"
        v-bind="item.attrs"
        v-if="isVisible(item)"
        :disabled="isDisabled(item)"
        @click="item.call(target, item.config)")
          component(
            :is="item.icon.type"
            v-bind="item.icon.attrs ? item.icon.options : {}"
            :class="item.icon.classes ? item.icon.classes : []"
            ) {{ item.icon.text }}
</template>

<script>
import { VBtn, VIcon } from 'vuetify/lib'
import EFlatIcon from '~/components/elements/icons/e-flat-icon'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  components: {
    VBtn,
    VIcon,
    EFlatIcon,
    ESvgIcon
  },
  props: {
    target: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    classes: {
      type: Array,
      default: () => ([
        'text-right'
      ])
    }
  },
  methods: {
    isVisible (item) {
      const rules = [
        this._.isFunction(item.isPermitted) ? item.isPermitted(this.target) : true,
        this._.isFunction(item.visible) ? item.visible(this.target) : true
      ]

      return this._.compact(rules).length === rules.length
    },

    isDisabled (item) {
      const rules = [
        this._.isFunction(item.disabled) ? item.disabled(this.target) : false
      ]

      return this._.compact(rules).length === rules.length
    }
  }
}
</script>

<style lang="scss">
  //
</style>
