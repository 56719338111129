<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="$listeners" max-width="900px")
    template(#title) {{ title }}
    template(#content)
      v-row(class="mt-3 mb-3")
        v-col(cols="12")
          m-stepper(
            :stepper="stepper"
            ref="stepper"
            @change="stepChanged"
            @dialog:close="close"
          )

    template( v-if="showControls" v-slot="actions" )
      t-orm-buttons(:items="ormModalButtons" :context="context")
</template>

<script>

import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import TDialog from '~/components/templates/t-dialog'
import checkPropCtx from '~/mixins/methods/checkPropCtx'
import MStepper from '~/components/modules/stepper/m-stepper'
import fill from '~/mixins/modules/dialogs/fill'

export default {
  components: {
    MStepper,
    TOrmFields,
    TOrmButtons,
    TDialog
  },
  mixins: [checkPropCtx, fill],
  props: {
    type: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    model: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    itemLoading: false,
    nextEmptyStep: null
  }),
  computed: {
    context () {
      return this._.get(this.dialogConfig.config, 'context', this.parentContext || this.$config.contexts.create)
    },
    stepper () {
      return this.dialogConfig.stepper
    },
    dialogConfig () {
      return {
        ...this.model.getOrmDialogsConfig(this.type)
      }
    },
    showControls () {
      return this._.get(this.dialogConfig, 'showControls', false)
    },
    isLoading () {
      return this.loading || this.itemLoading
    },
    title () {
      // TODO: create one logic for pattern generation.
      let title, name

      if (this.dialogConfig.title) {
        title = { ...this.dialogConfig.title.split('|') }
      }

      // TODO: move to class config???
      if (this.dialogConfig.config && this.dialogConfig.config.modalName) {
        name = this.dialogConfig.config.modalName
      } else {
        switch (this.context) {
          case this.$config.contexts.create:
            name = 'Creating of'
            break
          case this.$config.contexts.read:
            name = 'Card of'
            break
          case this.$config.contexts.update:
            name = 'Editing of'
            break
        }
      }

      const modalType = (() => {
        if (title) {
          return this.$tc(title[0], title[1])
        } else if (this.dialogConfig.config && this.dialogConfig.config.ucFirst) {
          return this._.upperFirst(this.$tc(this.model.ormTrans.single, 2))
        } else if (this.dialogConfig.config && this.dialogConfig.config.modalName) {
          return this.$tc(this.model.ormTrans.single, 1)
        } else {
          return this.$tc(this.model.ormTrans.single, 2)
        }
      })()

      return [
        this.$t(name),
        modalType,
        (this.dialogConfig.config && this.dialogConfig.config.ucFirst) ? this.selectedItem.name : ''
      ].join(' ').trim(' ')
    },
    ormModalButtons () {
      return [
        {
          text: 'Cancel',
          attrs: {
            color: 'primary',
            text: true,
            depressed: true
          },
          class: ['mr-2'],
          call: this.close
        },
        {
          text: this.nextEmptyStep ? 'Next' : 'Done',
          attrs: {
            color: 'primary',
            depressed: true
          },
          loading: this.itemLoading,
          call: this.next
        }
      ]
    }
  },
  methods: {
    close () {
      this.$emit('input', false)
    },

    async next () {
      const { beforeDone } = this._.get(this.dialogConfig, 'config.hooks', {})

      if (this.nextEmptyStep) {
        return this.$refs.stepper.goTo(this.nextEmptyStep)
      } else {
        if (this._.isFunction(beforeDone)) {
          this.itemLoading = true
          await beforeDone()
          this.itemLoading = false
        }
        this.close()
      }
    },

    stepChanged (data) {
      this.nextEmptyStep = data.nextEmptyStep
    },
    afterFill () {
      this.$nextTick().then(() => {
        this.nextEmptyStep = this.$refs.stepper.nextEmptyStep
        this.$refs.stepper.setData('dialogData', { model: this.model, parentContext: this.parentContext, selectedItem: this.selectedItem, ctx: this.ctx })
      })
    }
  }
}
</script>

<style lang="scss">
  //
</style>
