export default class Stepper {
  /**
   * Default stepper attrs
   * @type {{}}
   */
  stepperAttrs = {
    divided: false
  }

  /**
   * Default step attrs
   * @type {{}}
   */
  stepAttrs = {
    editable: true
  }

  /**
   * Components for stepper content
   * structure : [{component:_component_ (string or vue component), attrs:{} }]
   * @param stepComponents
   *
   * Initial step
   * @param initialStep
   *
   * Attributes for v-stepper component
   * @param stepperAttrs
   *
   * Attributes for v-step component
   * @param stepAttrs
   *
   * Texts for stepper header. Can include array of texts or texts template
   * @param texts
   */
  constructor (stepComponents = [], initialStep = 1, texts = {}, stepperAttrs = {}, stepAttrs = {}) {
    this.steps = stepComponents.length
    this.stepComponents = stepComponents
    this.initialStep = initialStep
    Object.assign(this.stepperAttrs, stepperAttrs)
    Object.assign(this.stepAttrs, stepAttrs)
    this.texts = texts
  }
}
