<template lang="pug">
  div(class="chips-list")
    v-chip(
      v-for="(item, index) in list"
      :key="index"
      class="chips-list__chip"
      :title="item"
      small
    ) {{ item }}
</template>

<script>
export default {
  name: 'EChipsList',
  components: {},
  mixins: [],
  props: {},
  data: () => ({}),
  computed: {
    list () {
      return this._.get(this.$attrs, 'value', [])
    }
  },
  created () {
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.chips-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  max-height: 100px;
  overflow-y: auto;
  padding: 5px;

  &__chip {
    max-width: 150px;

    &::v-deep {
      .v-chip__content {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        height: auto;
      }
    }
  }
}
</style>
