<template lang="pug">
  div
    | crud table
</template>

<script>
export default {
  name: 'MOrmCrudTable'
}
</script>

<style scoped>

</style>
