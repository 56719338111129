const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0
}
const allowedCardNetworks = ['MASTERCARD', 'VISA']

const allowedAuthMethods = ['PAN_ONLY', 'CRYPTOGRAM_3DS']

const baseCardPaymentMethod = {
  type: 'CARD',
  parameters: {
    allowedAuthMethods,
    allowedCardNetworks
  }
}

function createTokenizationSpecification (gatewayMerchantId, gateway) {
  return {
    type: 'PAYMENT_GATEWAY',
    parameters: {
      gateway,
      gatewayMerchantId
    }
  }
}

const cardPaymentMethod = (merchantId, gateway) => Object.assign(
  { tokenizationSpecification: createTokenizationSpecification(merchantId, gateway) },
  baseCardPaymentMethod
)

function createConfig (merchantId, gateway) {
  return {
    isReadyToPayRequestData: Object.assign({}, baseRequest, { allowedPaymentMethods: [baseCardPaymentMethod] }),
    paymentClearDataRequest: Object.assign({}, baseRequest, { allowedPaymentMethods: [cardPaymentMethod(merchantId, gateway)] })
  }
}

export const isReadyToPayRequestData = Object.assign({}, baseRequest, { allowedPaymentMethods: [baseCardPaymentMethod] })

export const paymentClearDataRequest = Object.assign({}, baseRequest, { allowedPaymentMethods: [cardPaymentMethod] })

export default class GooglePayConfig {
  merchantId = 'TEST'
  gatewayMerchantId='TEST'
  merchantName= 'TEST'
  environment = 'TEST'
  gateway= null
  isReadyToPayRequestData = null
  paymentClearDataRequest = null

  constructor (merchantId, gatewayMerchantId, merchantName, gateway, environment) {
    this.environment = environment
    this.merchantId = merchantId
    this.gatewayMerchantId = gatewayMerchantId
    this.merchantName = merchantName
    this.gateway = gateway
    const config = createConfig(this.gatewayMerchantId, this.gateway)
    this.isReadyToPayRequestData = config.isReadyToPayRequestData
    this.paymentClearDataRequest = config.paymentClearDataRequest
  }
}
