<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="listeners")
    template(#title)  {{ $t(title) }}

    template(#content)
      m-orm-select-table(v-model="selected" v-bind="tableConfig.attrs" :config="tableConfig")

    template(#actions)
      t-orm-buttons(:items="ormButtons")
</template>

<script>
// TODO define why we have error when include this component
import TDialog from '~/components/templates/t-dialog'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'

export default {
  components: {
    TDialog,
    TOrmButtons
  },
  props: {
    title: {
      type: String,
      default: 'Currency selection for the directory'
    },
    type: {
      type: String,
      default: ''
    },
    context: {
      type: String,
      default: 'selection',
      validator: val => ['creation', 'selection']
    },
    loading: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => {}
    },
    model: {
      type: Function,
      required: true
    },
    target: {
      type: Function,
      default: () => {}
    },
    selectedVal: {
      type: null,
      default: null
    }
  },
  data: () => ({
    selected: [],
    localLoading: false
  }),
  computed: {
    modelSortParams () {
      return this.model.getSortParams()
    },
    tableConfig () {
      const attrs = {
        loading: this.isLoading,
        model: this.model,
        ref: 'm-orm-select-table',
        defaultFilter: this.$attrs.defaultFilter
      }

      return Object.assign(
        this.$config.table,
        this.config,
        this.modelSortParams,
        {
          attrs
        })
    },
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.$emit('input', event)
            vm.clear()
          }
        }
      )
    },
    isLoading () {
      return this.loading || this.localLoading
    },
    ormButtons () {
      return [
        {
          text: 'Cancel selected',
          attrs: {
            color: 'primary',
            class: ['mr-4'],
            text: true,
            depressed: true,
            disabled: !this.selected.length
          },
          call: () => this.clear(true)
        },
        {
          text: 'Select',
          attrs: {
            color: 'primary',
            depressed: true,
            disabled: !this.selected.length
          },
          loading: this.localLoading,
          call: this.selectAction
        }
      ]
    },
    selectAction () {
      return this.context === 'creation' ? this.selectWithCreation : this.select
    }
  },
  methods: {
    clear (immediate = false) {
      const selectTable = this.$refs[this.tableConfig.attrs.ref]

      if (immediate) {
        this.selected = []
        selectTable.clear()
      } else {
        setTimeout(() => {
          this.selected = []
          selectTable.clear()
        }, 300)
      }
    },

    close () {
      this.$emit('input', false)
      this.clear()
    },

    async selectWithCreation () {
      if (!this.selected.length) {
        this.close()
      }

      this.localLoading = true
      try {
        const promises = []

        for (const index in this.selected) {
          promises.push(this.target.api().create(this.model.getOrmFieldsObject(this.selected[index])))
        }

        await Promise.all(promises)

        this.$notification.success((promises.length === 1)
          ? [this._.upperFirst(this.$tc(this.model.ormTrans.single, 1)), this.$t('was created!')].join(' ').trim()
          : [this.$t(this.model.ormTrans.multy), this.$t('was created!')].join(' ').trim()
        )

        this.close()
      } catch (e) {
        this.$handlers.error(e, this.$refs[this.model.entity])
      }
      this.localLoading = false
    },

    select () {
      const selectedVal = this.selected.length && (
        this.config.singleSelect ? this.selected[0] : this.selected
      )
      this.$emit(['update', 'selectedVal'].join(':'), selectedVal)
      this.close()
    },

    fill () {
      // define
    }
  }
}
</script>

<style lang="scss" scoped>
  .m-orm-list-table {
    >>> tr {
      &:nth-child(2n - 1) {
        background-color: #F0F6FC;
      }
    }
  }
</style>
