import Dictionary from '~/models/abstracts/Dictionary'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import PersistAble from '~/models/mixins/PersistAble'

export class KoatuuDictionary extends ChainInheritance(Dictionary, [
  PersistAble
]) {
  static defaultSort = false
  static entity = 'koatuudictionary'
  static persistBy = 'insertOrUpdate'

  static fields () {
    return {
      id: this.attr(null),
      firstLevel: this.attr(),
      secondLevel: this.attr(),
      thirdLevel: this.attr(),
      fourthLevel: this.attr(),
      category: this.attr(),
      name: this.attr(),
      position: this.attr(null),
      // TODO possible slow place
      children: this.hasMany(KoatuuDictionary, 'parentId', 'id'),
      parentId: this.attr(null),
      hasChildren: this.attr(null)
    }
  }

  get childrenVal () {
    return this.children.length ? this.children : []
  }

  get code () {
    return KoatuuDictionary.getCode(this)
  }

  static getCode (koatuu) {
    return koatuu.fourthLevel || koatuu.thirdLevel || koatuu.secondLevel || koatuu.firstLevel
  }

  static ormTrans = {
    single: 'Koatuu dictionary',
    multy: 'Koatuu dictionary'
  };

  static ormTreeConfig = {
    parentFieldName: 'parentId',
    childrenFieldName: 'childrenVal',
    textFieldName: 'name',
    hasChildrenFlagName: 'hasChildren',
    asyncLoading: {
      // TODO implement function arg support
      requestMethod: 'children'
    }
  }

  static ormHeaders = [
  ];

  static ormFields = [
  ];

  static ormActions = [
    //
  ];
}

export default KoatuuDictionary
