import Dialog from '~/models/system/Dialog'

const signPluginForOrganization = {
  computed: {
    // this dialog will be used to store organization EDS key and reuse it
    signPluginForOrganization () {
      return Dialog.query().where('type', 'signPluginForOrganization').first()
    }
  }
}
export default signPluginForOrganization
