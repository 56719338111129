import OrmModel from '~/models/abstracts/base/OrmModel'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import PersistAble from '~/models/mixins/PersistAble'
import StringAble from '~/models/mixins/StringAble'

export default class Search extends ChainInheritance(OrmModel, [
  PersistAble,
  StringAble
]) {
  static persistBy = 'insert'
  static paginated = true
  static searchable = true

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(null)
    }
  }

  static getSearchFields () {
    return Object.keys(this._.pick(this.fields(), this._.difference(Object.keys(this.fields()), ['id'])))
  }
}
