import { ormConfig } from '~/const/global'

export default function ({ store, route, redirect }) {
  const model = route.matched[0].components.default.options.computed.model()

  if (process.env.debug.permissions) {
    // eslint-disable-next-line no-console
    console.debug(store.getters.ability.rules.filter(r => r.subject === model[ormConfig.modelKey]))
  }
}
