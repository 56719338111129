<template lang="pug">
  div
    p Вибачте, якщо ми не встигли відповісти на ваше звернення у Checkbox. У кінці року дуже велике навантаження :(
    p(class="mb-7")
      span(class="mr-1") Ми незабаром з вами зв'яжемось, а поки ви можете переглянути наші
      e-link(href="https://docs.google.com/document/d/1Hf8PkrgE_gl7Ns3XMosGhKA5l_t2bcaKRYSd11oaSio/edit") {{ $t('Frequently Asked Questions') }}
    v-btn(class="d-block mx-auto mb-4" @click="$attrs.closeModal" dark) {{ $t('Clear') }}
</template>

<script>
import ELink from '~/components/elements/links/e-link'

export default {
  components: { ELink }
}
</script>

<style scoped lang="scss">

</style>
