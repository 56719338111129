import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'

export class Uktzeds extends ChainInheritance(Directory, []) {
  static entity = 'uktzeds'

  static fields () {
    return {
      id: this.attr(null),
      part: this.attr(null),
      code: this.attr(null),
      text: this.attr(null)
    }
  }
}

export default Uktzeds
