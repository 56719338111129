import OrmModel from '~/models/abstracts/base/OrmModel'

export default class Dictionary extends OrmModel {
  // Default sorting param.
  static defaultSortParam = 'number'
  // Default sorting order.
  static defaultSortOrder = false

  static getSortParams () {
    return super.getSortParams({
      'sort-by': ['number'],
      'sort-desc': [false]
    })
  }
}
