import Base64js from 'base64-js'

export function addSignHeaderDp (dataBase64, prefix = 'UA1_SIGN\0') {
  const data = Base64js.toByteArray(dataBase64)
  const dataLen = data.length
  const header = (stringToAsciiByteArray(prefix))
  const len = (getBytes0(parseInt(dataLen)))
  const bytes = new Uint8Array(dataLen + header.length + len.length)
  bytes.set(header)
  bytes.set(len, header.length)
  bytes.set(data, header.length + len.length)
  return Base64js.fromByteArray(bytes)
}

function stringToAsciiByteArray (str) {
  const bytes = new Uint8Array(str.length)
  for (let i = 0; i < str.length; ++i) {
    const charCode = str.charCodeAt(i)
    if (charCode > 0xFF) {
      throw new Error('Character ' + String.fromCharCode(charCode) + ' can\'t be represented by a US-ASCII byte.')
    }
    bytes[i] = (charCode)
  }
  return bytes
}

function getBytes0 (x) {
  const bytes = new Uint8Array(4)
  let i = 0
  bytes[i++] = ((x >>> 0) & 0xFF)
  bytes[i++] = ((x >>> 8) & 0xFF)
  bytes[i++] = ((x >>> 16) & 0xFF)
  bytes[i++] = ((x >>> 24) & 0xFF)
  return bytes
}
