import Sidebar from '~/modules/sidebar/models/Sidebar'

const switchingSidebar = {
  computed: {
    switchingSidebar () {
      return Sidebar.query().where('type', 'switching').first()
    }
  }
}
export default switchingSidebar
