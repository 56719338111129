const ModelsCtor = {
  computed: {
    $modelsCtor () {
      return {
        model: this.model,
        group: this.groupModel
      }
    }
  }
}

export default ModelsCtor
