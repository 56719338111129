import Connector from '~/plugins/vuex-orm/plugins/_drivers/abstract/Connector'
import OrmModel from '~/models/abstracts/base/OrmModel'

export default class VuexORMConnector extends Connector {
  #query
  #params
  #sortBy = []
  #filterBy = [[], []]
  #mapped = {
    sortBy: {},
    filterBy: {}
  }

  #patterns = {
    sortByField: (field) => {
      let orderField = field

      if (this.isObject(field)) {
        const key = Object.keys(field)[0]
        const val = Object.values(field)[0]

        orderField = this.isOrmModel(val)
          ? `${key}.${val.primaryKey}`
          : field
      }

      return orderField
    },
    sortByOrder: desc => desc ? 'desc' : 'asc',
    filterByField: field => field,
    filterByVal: value => value
  }

  constructor (query, params) {
    super()

    this.#query = query
    this.#params = params
    this.mapParams()
    this.chainQuery()
  }

  get query () {
    return this.#query
  }

  mapParams () {
    for (const key in this.#params) {
      const val = this.#params[key]

      Array.isArray(val) ? this.mapArray(key, val) : this.mapObject(key, val)
    }

    if (Object.values(this.#sortBy).length === 2) {
      this.chainSortBy()
    }

    if (Object.values(this.#filterBy).length === 2) {
      this.chainFilterBy()
    }
  }

  mapArray (key, val) {
    if (key.includes('sort') && Array.isArray(val) && val.length) {
      this.#sortBy.push(val)
    }
  }

  // TODO: put to Connector.
  mapObject (key, val) {
    if (key.includes('filter') && val) {
      Object.entries(val).map(([field, val]) => {
        if (field && val) {
          this.#filterBy[0].push(field)
          this.#filterBy[1].push(val)
        }
      })
    }
  }

  chainSortBy () {
    const [fields, values] = this.#sortBy

    for (const i in fields) {
      this.#mapped.sortBy[this.#patterns.sortByField(fields[i])] = this.#patterns.sortByOrder(values[i])
    }
  }

  chainFilterBy () {
    const [fields, values] = this.#filterBy

    for (const i in fields) {
      this.#mapped.filterBy[this.#patterns.filterByField(fields[i])] = this.#patterns.filterByVal(values[i])
    }
  }

  chainQuery () {
    for (const field in this.#mapped.filterBy) {
      const val = this.#mapped.filterBy[field]
      // Filtering by single or array of ORM models.
      if (val instanceof OrmModel || (Array.isArray(val) && val.every(i => this.isOrmModel(i)))) {
        if (!Array.isArray(val)) {
          this.#query.whereHas(field, query => query.whereId(val.primaryVal))
        } else {
          return this.#query.whereHas(field, query => query.whereIdIn(val.map(i => i.primaryVal)))
        }
      } else {
        // TODO: remove passing of root folder to connector.
        if (typeof val === 'object' && val.isRoot) { continue }

        // Custom filters based on dot notation,
        if (field.includes('.')) {
          const [strategy, fieldName] = field.split('.')

          this.#query.where((model) => {
            // const fieldVal = model.$primaryKey() === fieldName ? model.$id : model[fieldName]
            const fieldVal = model[fieldName]

            if (Array.isArray(val)) {
              return strategy === 'only' ? val.includes(fieldVal) : !val.includes(fieldVal)
            }
            return strategy === 'only' ? val === fieldVal : val !== fieldVal
          })
        }

        if (this.#mapped.filterBy.length === 1) {
          this.#query.where(field, val)
        } else {
          this.#query.orWhere(field, val)
        }
      }
    }

    for (const field in this.#mapped.sortBy) {
      if (!field.includes('.')) {
        this.#query.orderBy(field, this.#mapped.sortBy[field])
      } else {
        // TODO: fix it!
        // const [rel, key] = field.split('.')
        // this.#query.with(rel, (query) => {
        //   query.orderBy('name', this.#mapped.sortBy[field])
        //   return query
        // })

        // this.#query.orderBy(model => model[rel][key])
      }
    }
  }

  manualSort (items) {
    const fields = []
    for (const field in this.#mapped.sortBy) {
      if (field.includes('.')) {
        fields.push({
          [field]: this.#mapped.sortBy[field]
        })
      }
    }

    if (fields.length) {
      // TODO: add multi sort by relations.
      // TODO: create query for filtering of objects based on relations.
      return items.sort((a, b) => {
        const key = Object.keys(fields[0])[0]
        const order = Object.values(fields[0])[0]

        const [rel, field] = key.split('.')
        const firstParam = a && a[rel] && a[rel][field]
        const secondParam = b && b[rel] && b[rel][field]

        return order === 'asc' ? firstParam - secondParam : secondParam - firstParam
      })
    }

    return items
  }
}
