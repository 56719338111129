import { Model } from '@vuex-orm/core'
import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'

export class AccountingGoodsSettings extends ChainInheritance(Directory, []) {
  static entity = 'accountinggoodssettings'
  static ormLoadWithRelations = true

  static ormTrans = {
    single: 'Good',
    multy: 'Goods',
    notificationSingle: 'Good',
    subTitle: 'Good subtitle'
  }

  static fields () {
    return {
      use_accounting_system: this.attr(null),
      use_disadvantage: this.attr(null),
      default_branch: this.attr(null),
      use_leftovers: this.attr(null),
      use_branches: this.attr(null)
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.setSettings = function (payload) {
        return this.put(Model.$routes.accountinggoodssettings.list(), payload)
      }
      configActions.getSettings = function () {
        AccountingGoodsSettings.deleteAll()
        return this.get(Model.$routes.accountinggoodssettings.list())
      }
      return configActions
    }
  }
}

export default AccountingGoodsSettings
