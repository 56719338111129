<template lang="pug">
  v-app
    v-content
      v-container(fluid fill-height class="default pa-0 background align-start")
        nuxt
    notification
    dialogs(ref="dialogs")
    e-new-version-informer
</template>

<script>
import notification from './includes/_notification'
import dialogs from './includes/_dialogs'
import ENewVersionInformer from '~/components/elements/informers/e-new-version-informer'

export default {
  components: {
    notification,
    dialogs,
    ENewVersionInformer
  }
}
</script>
