<template>
  <i v-bind="$attrs" :class="classes" :style="styles" v-on="$listeners" />
</template>

<script>

const name = 'e-flat-icon'

export default {
  name,
  inheritAttrs: false,
  props: {
    size: {
      type: String,
      default: 'md',
      validator: (value) => {
        return ['sm', 'md', 'lg', 'xlg'].includes(value)
      }
    },
    // eslint-disable-next-line vue/require-default-prop
    color: {
      type: String
    },
    // eslint-disable-next-line vue/require-default-prop
    text: {
      type: String
    }
  },
  computed: {
    classes () {
      return ['fi', this._.get(this.$slots, 'default[0].text', this.text).trim(), this.size]
    },
    styles () {
      return {
        color: this.color
      }
    }
  }
}
</script>

<style lang="sass">
  .fi
    vertical-align: middle

    &.sm
      font-size: 0.75rem
    &.md
      font-size: 1.075rem
    &.lg
      font-size: 1.275rem
    &.xlg
      font-size: 1.55rem

    &::before
      line-height: 1.563rem !important
      float: right !important
</style>
