<template lang="pug">
  t-dialog(
    v-bind="$attrs"
    :value="isOpen"
    v-on="$listeners"
    max-width="900px"
    :hide-overlay="!isVisible"
    :content-class="!isVisible ? 'iit-sign-dialog-hidden' : ''"
    :onCloseBtn="onCloseBtn"
    @input="close"
  )
    template(#title) {{ title }}
    template(#content)
      v-row(class="mt-3 mb-3")
        v-col(cols="12")
          v-overlay(absolute :value="isLoading" )
            e-progress-circular
          m-iit-sign-plugin(
            @keyLoaded="onKeyLoaded($event)"
            @data:signed="processSignedData"
            @data:cert="processCertData"
            @iframeLoading="onIframeLoading"
            :signData="signData"
            ref="plugin"
            :plugin-id="pluginId"
          )
</template>

<script>
import { mapState } from 'vuex'
import Base64js from 'base64-js'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import TDialog from '~/components/templates/t-dialog'
import MIitSignPlugin from '~/components/modules/IIT/m-iit-sign-plugin'
import fill from '~/mixins/modules/dialogs/fill'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import { TaxReportService } from '~/services/Tax/Report/TaxReportService'
import taxAuth from '~/mixins/pages/tax-auth'

export default {
  components: {
    EProgressCircular,
    MIitSignPlugin,
    TOrmButtons,
    TDialog
  },
  mixins: [fill, taxAuth],
  props: {
    type: {
      type: String,
      required: true
    },
    signData: {
      type: [String, Object],
      default: null
    },
    afterSingCallback: {
      type: Function,
      default: null
    },
    model: {
      type: Function,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    },
    global: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    allowSeal: {
      type: Boolean,
      default: false
    },
    switchFlag: {
      type: String,
      default: null
    },
    pluginId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    localLoading: true,
    certData: null,
    iitDialogStoreType: 'mDialogIitSignPlugin',
    onClose: null,
    localKeyData: null
  }),
  computed: {
    ...mapState({
      savedKeyData: state => state.user.savedKeyData,
      storeDialogConfig: state => state.dialogs.dialogs.mDialogIitSignPlugin
    }),
    isOpen () {
      return this.global ? this._.get(this.iitDialog, 'options.open', false) : this.value
    },
    organization () {
      return this.$Organization || {}
    },
    isLoading () {
      return this.loading || this.localLoading
    },
    dialogConfig () {
      if (!this.model) { return {} }
      return {
        ...this.model.getOrmDialogsConfig(this.type)
      }
    },
    isVisible () {
      return this.global ? this._.get(this.iitDialog, 'options.visible', false) : this.visible
    },
    title () {
      if (this.$attrs.title) {
        return this.$t(this.$attrs.title)
      }
      let title

      if (this.dialogConfig.title) {
        title = { ...this.dialogConfig.title.split('|') }
      }

      const modalType = (() => {
        if (title) {
          return this.$tc(title[0], title[1])
        } else if (this.dialogConfig.config && this.dialogConfig.config.ucFirst) {
          return this._.upperFirst(this.$tc(this.model.ormTrans.single, 2))
        } else if (this.dialogConfig.config && this.dialogConfig.config.modalName) {
          return this.$tc(this.model.ormTrans.single, 1)
        } else {
          return this.$tc(this.model.ormTrans.single, 2)
        }
      })()

      return [
        modalType,
        (this.dialogConfig.config && this.dialogConfig.config.ucFirst) ? this.selectedItem.name : ''
      ].join(' ').trim(' ')
    }
  },
  created () {
    if (this.global) {
      this.$store.commit('user/SET_KEY_DATA', {
        keyData: null
      })
      this.$store.commit('dialogs/SET_DIALOG_OPTIONS', {
        type: this.iitDialogStoreType,
        actions: {
          sign: this.sign,
          signHash: this.signHash,
          envelopData: this.envelopData,
          syncData: this.handleSyncData,
          signData: this.handleSignData
        }
      })
    }
  },
  methods: {
    onIframeLoading (loading) {
      this.localLoading = loading
    },
    onCloseBtn () {
      if (this.global) {
        this.destroyIitGlobalDialog()
      } else {
        this.$emit('input', false)
      }
      this.onCloseCb()
    },
    close () {
      this.$emit('input', false)
      this.onCloseCb()
    },
    onCloseCb (data) {
      if (typeof this.onClose === 'function') {
        this.onClose(data || this.localKeyData)
        this.onClose = null
        this.localKeyData = null
      }
    },
    processSignedData (data) {
      if (!this.afterSingCallback) { return }
      this.afterSingCallback(data, this.certData)
    },
    processCertData (data) {
      this.certData = data
    },
    sign (data, emmit = true) {
      return this.$refs.plugin.sign(data, emmit)
    },
    signHash (data) {
      return this.$refs.plugin.signHash(data)
    },
    envelopData (certs, data) {
      return this.$refs.plugin.envelopData(certs, data)
    },
    switchGlobalDialogLoading (loading = false) {
      this.$store.commit('dialogs/SET_DIALOG_OPTIONS', {
        type: this.iitDialogStoreType,
        options: {
          loading
        }
      })
    },
    closeGlobalDialog ({ options = {}, actions = {} } = {}) {
      this.$store.dispatch('dialogs/closeWithOptions', {
        type: this.iitDialogStoreType,
        options: {
          keysMatchCheck: true,
          ...options
        },
        actions: {
          onKeyLoaded: null,
          ...actions
        }
      })
      this.onCloseCb()
    },
    keysMatch (keyData, keysCheck = true) {
      const EDRPOUCode = this._.get(keyData, '[0].infoEx.subjEDRPOUCode', '')
      const DRFOCode = this._.get(keyData, '[0].infoEx.subjDRFOCode', '')

      if ((EDRPOUCode || DRFOCode) !== this.organization.edrpou && keysCheck) {
        this.$handlers.error('The USREOU codes do not match. Please choose another', this)
        this.closeGlobalDialog({
          options: {
            open: false
          }
        })
        return false
      } else {
        return true
      }
    },
    async onKeyLoaded (keyData) {
      const isSeal = keyData && !this._.get(keyData[0], 'infoEx.subjDRFOCode', '')

      if (isSeal && !this.allowSeal) {
        this.$handlers.error('Please use the EDS key instead of seal', this)
        this.onCloseBtn()
      } else if (this.global) {
        const type = this._.get(this.iitDialog, 'options.type', false)

        if (type === 'sync') {
          await this.handleSyncData({ data: keyData })
        } else if (type === 'sign') {
          await this.handleSignData({
            data: keyData,
            onClose: this._.get(this.storeDialogConfig, 'actions.onClose', null)
          })
        }
      } else {
        this.$emit('keyLoaded', keyData, this.switchFlag)
      }
      this.localKeyData = keyData
    },
    async handleSyncData ({ data, model, tableRequest, onKeyLoaded, keysMatchCheck, options = {}, actions = {}, onClose }) {
      const savedKeyData = this._.get(this.savedKeyData, 'keyData', null)
      const keyData = data || savedKeyData
      this.onClose = onClose

      if (!keyData) {
        this.$store.dispatch('dialogs/openWithOptions', {
          type: this.iitDialogStoreType,
          options: {
            model,
            keysMatchCheck,
            type: 'sync',
            title: 'Authorize in SFS',
            open: true,
            ...options
          },
          actions: {
            tableRequest,
            onKeyLoaded,
            onClose,
            ...actions
          }
        })
      } else {
        try {
          const keysCheck = keysMatchCheck || this._.get(this.iitDialog, 'options.keysMatchCheck', true)
          if (!this.keysMatch(keyData, keysCheck)) {
            return
          }
          this.switchGlobalDialogLoading(true)
          this.localKeyData = keyData

          if (!savedKeyData) {
            this.$store.commit('user/SET_KEY_DATA', {
              keyData
            })
          }

          const onKeyLoadedCallback = onKeyLoaded || this._.get(this.iitDialog, 'actions.onKeyLoaded', null)
          const entityModel = model || this._.get(this.iitDialog, 'options.model', null)
          const entityTableRequest = tableRequest || this._.get(this.iitDialog, 'actions.tableRequest', null)
          const EDRPOUCode = this._.get(keyData, '[0].infoEx.subjEDRPOUCode', '')
          const DRFOCode = this._.get(keyData, '[0].infoEx.subjDRFOCode', '')
          const signedData = await this.sign(this.organization.edrpou || EDRPOUCode || DRFOCode) // xml sign in base64

          const payload = {
            EDRPOUCode,
            DRFOCode,
            sign: signedData
          }

          if (onKeyLoadedCallback) {
            await onKeyLoadedCallback(keyData, payload.sign)
          } else if (entityModel) {
            const response = await entityModel.api().synchronize(payload)
            // todo workaround to update store by new values
            await entityTableRequest()
            // ****************
            const count = response.response.data.sync
            const _new = response.response.data.new
            const deactivated = response.response.data.deactivated
            this.$notification.success(this.$t(`entitySynchronization.${entityModel.entity}`, { count, new: _new, deactivated }))
          } else {
            await this.$store._actions.authorizeInTax[0](payload)
            this.$notification.success(this.$t('Data successfully updated'))
          }
        } catch (e) {
          this.destroyIitGlobalDialog()
          this.$handlers.error(e, this)
        } finally {
          this.closeGlobalDialog()
          this.switchGlobalDialogLoading(false)
        }
      }
    },
    async handleSignData ({ data, model, createdEntity, options = {}, actions = {}, onClose }) {
      const savedKeyData = this._.get(this.savedKeyData, 'keyData', null)
      const keyData = data || savedKeyData
      this.onClose = onClose

      if (!keyData) {
        this.$store.dispatch('dialogs/openWithOptions', {
          type: this.iitDialogStoreType,
          options: {
            model,
            createdEntity,
            type: 'sign',
            title: 'Send to sfs',
            open: true,
            ...options
          },
          actions: {
            onClose,
            ...actions
          }
        })
      } else {
        try {
          if (!this.keysMatch(keyData)) {
            return
          }
          this.switchGlobalDialogLoading(true)
          this.localKeyData = keyData

          if (!savedKeyData) {
            this.$store.commit('user/SET_KEY_DATA', {
              keyData
            })
          }
          const entityModel = model || this._.get(this.iitDialog, 'options.model', null)
          const savedCreatedEntity = createdEntity || this._.get(this.iitDialog, 'options.createdEntity', null)
          const HKBOS = keyData[0].infoEx.subjDRFOCode || keyData[0].infoEx.subjEDRPOUCode
          let xmlResponse
          if (entityModel.entity === 'cashregisterorder') {
            xmlResponse = await entityModel.api().get(entityModel.$routes[entityModel.entity].xmlDoc(savedCreatedEntity[entityModel.primaryKey]),
              { save: false })
          } else {
            xmlResponse = await entityModel.api().put(entityModel.$routes[entityModel.entity].xmlDoc(savedCreatedEntity[entityModel.primaryKey]),
              { HKBOS },
              { save: false })
          }
          const xml = Base64js.toByteArray(this._.get(xmlResponse, 'response.data.xml', null))
          const fname = this._.get(xmlResponse, 'response.data.fname', null)
          const userCert = keyData[0].data
          const taxReportService = new TaxReportService(this._.get(this.iitDialog, 'actions', {}))
          const signData = await taxReportService.getEnvelopedData(xml, userCert)

          if (entityModel.entity === 'cashregisterorder') {
            await entityModel.api().post(entityModel.$routes[entityModel.entity].taxRegister(savedCreatedEntity[entityModel.primaryKey]), {
              signData,
              fname
            })
          } else {
            await entityModel.api().put(entityModel.$routes[entityModel.entity].taxRegister(savedCreatedEntity[entityModel.primaryKey]), {
              signData,
              fname
            })
            const entity = entityModel.query().whereId(savedCreatedEntity[entityModel.primaryKey]).first()

            if (entity.taxDocId && entity.status !== 'error') {
              const docId = entity.taxDocId + ''
              const reqEncryptedId = await taxReportService.getEnvelopedData(Base64js.toByteArray(btoa(docId)), userCert)
              await entityModel.api().update(entity, { reqEncryptedId })
            }
          }
          this.$notification.success(`entityActions.create.${entityModel.entity}`)
        } catch (e) {
          this.destroyIitGlobalDialog()
          this.$handlers.error(e, this)
        } finally {
          this.closeGlobalDialog()
          this.switchGlobalDialogLoading(false)
        }
      }
    }
  }
}
</script>

<style lang="scss">
  .iit-sign-dialog-hidden {
    display: none !important;
  }
</style>
