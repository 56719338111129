var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0 m-group-tree"},[[_c(_vm.model.ormDialogs[_vm.ormDialog],_vm._b({tag:"component",model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},'component',_vm.treeDialogConfig,false)),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"6"}},[_c('div',{staticClass:"font-weight-bold subtitle-2"},[_vm._v(" "+_vm._s(_vm._.upperFirst(_vm.$tc(_vm.model.ormTrans.multy, 2))))])]),_c('v-col',{staticClass:"py-1 text-right",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"primary--text font-weight-bold subtitle-2",attrs:{"text":""},on:{"click":_vm.createAction}},[_vm._v("+ "+_vm._s(_vm.$t('New group')))])],1)],1)],1),_c('v-treeview',_vm._b({staticClass:"m-group-tree__tree",attrs:{"items":_vm.items,"item-key":_vm.model.primaryKey,"item-children":_vm.treeConfig ? _vm.treeConfig.childrenFieldName : undefined,"load-children":_vm.loadNodes,"selectable":_vm.config.selectable,"selected-color":"success","return-object":"","activatable":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_c('v-row',{staticClass:"m-group-tree__item-row",attrs:{"justify":"start","align":"center","no-gutters":""},on:{"dblclick":function($event){return _vm.treeItemConfig.dblclick(item)}}},[_c('v-col',{staticClass:"py-0 text-truncate",attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"color":item.active ? 'success': 'secondary'}},[_vm._v(_vm._s(open ? 'mdi-folder-open' : 'mdi-folder'))]),_c('v-tooltip',{attrs:{"top":"","light":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"primary--text inline-block ml-2 m-group-tree__text"},on),[_vm._v(_vm._s(item.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])],1)],1)]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(!_vm.$isReadCtx(_vm.context) && !item.isRoot)?_c('t-orm-menu-actions',{attrs:{"items":_vm.filteredActions,"target":item}}):_vm._e()]}}]),model:{value:(_vm.tree.checked),callback:function ($$v) {_vm.$set(_vm.tree, "checked", $$v)},expression:"tree.checked"}},'v-treeview',_vm.tree,false,true)),_c('e-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.treeLoading),expression:"treeLoading"}],attrs:{"size":"sm"}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }