import Vue from 'vue'
import wysiwyg from 'vue-wysiwyg'
import 'vue-wysiwyg/dist/vueWysiwyg.css'

export default function () {
  Vue.use(wysiwyg, {
    hideModules: {
      code: true,
      table: true
    }
  })
}
