<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title") {{ $t('Supplies settings') }}
    div(class="dialog__content")
      p(class="mb-6") {{ dialogText }}
      t-orm-fields(v-bind.sync="form" :items="fields")
    div(class="dialog__actions")
      v-btn(
        class="main-button mx-auto d-block mt-5"
        @click="save"
        :loading="loading"
      ) {{ $t('Save') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import AccountingGoodsSettings from '~/modules/goods/models/AccountingGoodsSettings'

export default {
  name: 'MBlockSwitchSuppliesSetting',
  components: {
    TOrmFields
  },
  mixins: [],
  props: {
    useLeftovers: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      default: () => {}
    },
    text: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    loading: false,
    form: {
      useLeftovers: false
    }
  }),
  computed: {
    dialogText () {
      return this.$t(this.text || 'To use goods accounting, you must enable the use of balances in the settings')
    },
    fields () {
      return [
        {
          model: 'useLeftovers',
          component: 'e-input-switch-tooltip',
          provider: {
            vid: 'useLeftovers',
            name: 'Leftovers are used'
          },
          attrs: {
            label: 'Leftovers are used'
          },
          cols: {
            cols: 12
          }
        }
      ]
    }
  },
  created () {
    this.$set(this.form, 'useLeftovers', this.useLeftovers)
  },
  methods: {
    async save () {
      if (this.form.useLeftovers === this.useLeftovers) {
        this.closeModal(false)
        return
      }
      try {
        this.loading = true
        await AccountingGoodsSettings.api().setSettings({
          use_leftovers: this._.get(this.form, 'useLeftovers')
        })
        await AccountingGoodsSettings.api().getSettings()
        this.closeModal(true)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
