<template lang="pug">
  v-row
    v-col(cols="12" class="py-0")
      v-toolbar(flat height="50" v-if="hideTableTopBar")
        component(is="m-orm-select-dialog"
                  v-bind="selectionDialogAttrs"
                  v-model="dictionaryModal"
                  @update:selection="openCreationModal"
                  :loading="tableLoading")

        v-btn(v-if="canCreate" color="primary" class="m-orm-table__activator" @click="activator" :loading="tableLoading")
          | {{ tableButton }}
        v-spacer

      m-orm-table(:defaultFilter="configFilters" :hide-top-bar="hideTableTopBar" v-bind="$attrs" :parent-item="parentItem" ref="ormTable")
</template>

<script>
// TODO: delete it, this breaks our filter component! (loop)
// import MOrmSelectDialog from '~/components/modules/dialogs/orm/m-orm-select-dialog'
import { actions as ormActions, ormConfig } from '~/const/global'
import configFilters from '~/mixins/computed/configFilters'

export default {
  name: 'MOrmTableWrapper',
  mixins: [configFilters],
  // components: { MOrmSelectDialog },
  inheritAttrs: false,
  props: {
    context: {
      type: String,
      default: 'default',
      validator: val => ['default', 'dictionary'].includes(val)
    },
    parentItem: {
      type: Object,
      default: () => ({})
    },
    filter: {
      type: [Object, Function],
      default: () => ({})
    },
    selectionDialogAttrs: {
      type: Object,
      default: () => ({})
    },
    dictionaryFieldsMap: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      dictionaryModal: false,
      tableLoading: false
    }
  },
  computed: {
    hideTableTopBar () {
      return false
    },
    canCreate () {
      return this.$ability.can(ormActions.create, this.$attrs.model[ormConfig.modelKey])
    },
    tableButton () {
      // TODO remove it
      const addWord = this._.get(this.$attrs, 'model.entity', null) === 'goodscomponent' ? this.$t(' Customize') : `+ ${this.$t('Add')}`
      return [
        addWord,
        this.$tc(this.$attrs.model.ormTrans.single, 2)
      ].join(' ').trim()
    },

    // Connector for configFilters mixin.
    config () {
      return {
        filter: this.filter
      }
    },
    selectedItem () {
      return this.parentItem
    }
  },
  methods: {
    activator () {
      this.dictionaryModal = true
    },
    openCreationModal (data) {
      this.$refs.ormTable.defaultAction(this.processDictionaryData(data))
    },
    processDictionaryData (data) {
      const obj = {}
      if (this._.isEmpty(this.dictionaryFieldsMap)) {
        return data[0]
      } else {
        for (const key in this.dictionaryFieldsMap) {
          if (this._.get(data[0], this.dictionaryFieldsMap[key], null)) {
            obj[key] = this._.get(data[0], this.dictionaryFieldsMap[key], null)
          }
        }
        return obj
      }
    }

  }
}
</script>

<style scoped>

</style>
