import Vue from 'vue'
import { ValidationObserver, ValidationProvider, extend, localize, configure } from 'vee-validate'

import * as rules from 'vee-validate/dist/rules'

for (let rule in rules) {
  extend(rule, rules[rule])
}

const getLocale = (opt, locale) => {
  if (typeof opt === 'object' && opt.locale[locale]) {
    return opt.locale[locale]
  }
  return locale
}

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

export default ({ app }) => {
  const nuxti18n = {"locale":{"en-US":"en-US"}}
  if (nuxti18n && app.i18n) {
    const validatorLocale = getLocale(nuxti18n, app.i18n.locale)
    import(`vee-validate/dist/locale/${validatorLocale}.json`).then(dic => {
      localize(validatorLocale, dic)
    })

    const beforeLanguageSwitch = app.i18n.beforeLanguageSwitch
    app.i18n.beforeLanguageSwitch = (oldLocale, newLocale) => {
      beforeLanguageSwitch(oldLocale, newLocale)
      const newValidatorLocale = getLocale(nuxti18n, newLocale)
      import(`vee-validate/dist/locale/${newValidatorLocale}.json`).then(dic => {
        localize(newValidatorLocale, dic)
      })
    }
  }
}
