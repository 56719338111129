const ctx = {
  props: {
    ctx: {
      type: Object,
      default: () => {}
    }
  }
}

export default ctx
