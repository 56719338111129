const currencyFormat = {
  filters: {
    currencyFormat: value => `${(value || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ')}`
  },
  methods: {
    moneyUahFormatter (value) {
      return value ? parseFloat(value).toFixed(2) : ''
    },
    floatKeyPress ($event) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault()
      }
    }
  }
}

export default currencyFormat
