<template lang="pug">
  v-btn(:class="getCls" v-on="listeners" v-bind="$attrs" text)
    slot
</template>

<script>
import sz from '~/mixins/props/sizes'

export default {
  mixins: [sz],
  props: {
    underline: {
      type: Boolean,
      default: false
    },
    textBold: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: '_blank',
      validator: (value) => {
        return ['_blank', '_self', '_parent', '_top'].includes(value)
      }
    }
  },
  computed: {
    getCls () {
      return [
        'e-link',
        `e-link__${this.size}`,
        this.underline && 'e-link__underline',
        this.textBold && 'e-link__text-bold'
      ]
    },
    listeners () {
      return Object.assign({},
        this.$listeners,
        {
          click: (e) => {
            e.stopPropagation()
            if (this.href) {
              window.open(this.href, this.target)
            }
            this.$emit('click', e)
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.e-link {
  color: $e-link-color;
  height: auto !important;
  padding: 0 !important;
  min-width: auto !important;
  font-size: inherit !important;
  vertical-align: initial;
  font-weight: 400;
  letter-spacing: 0;
  white-space: normal;
  word-break: normal;

  &::v-deep {
    .v-ripple__container {
      display: none;
    }
    .v-progress-circular {
      height: 100% !important;
    }
  }

  &:before {
    display: none !important;
  }

  &__sm {
    font-size: 0.75rem;
  }

  &__md {
    font-size: 0.875rem;
  }

  &__lg {
    font-size: 1.125rem;
  }

  &:hover {
    cursor: pointer;
  }

  &__underline {
    text-decoration: underline;
  }

  &__text-bold {
    font-weight: 700;
  }
}
</style>
