import { Model } from '@vuex-orm/core'
import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'

export class TaxInfo extends ChainInheritance(Directory, []) {
  static entity = 'taxinfo'

  static fields () {
    return {
      id: this.attr(null),
      GRUP: this.attr(null),
      STAVKA: this.attr(null)
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.getTaxInfo = function () {
        return this.get(Model.$routes.taxinfo.list())
      }
      return configActions
    }
  }
}

export default TaxInfo
