<template lang="pug">
  v-tooltip(v-if="tooltip" right)
    template(#activator="{on, attrs}")
      div(v-on="on" style="width:23px")
        v-radio-group(:value="boolValue" :disabled="!boolValue" :color="color" hide-details class="ma-0 pa-0")
          v-radio(:value="true" :color="color" :ripple="false" readonly)
    span()
      | {{$t(tooltip)}}
  v-radio-group(v-else :value="boolValue" :disabled="!boolValue" :color="color" hide-details class="ma-0 pa-0")
    v-radio(:value="true" :color="color" :ripple="false" readonly)
</template>

<script>
import { VRadio, VRadioGroup } from 'vuetify/lib'

export default {
  components: {
    VRadio,
    VRadioGroup
  },
  props: {
    value: {
      type: [Boolean, String, Number],
      required: true
    },
    valueMap: {
      type: Object,
      default: () => ({})
    },
    colorMap: {
      type: Object,
      default: () => ({})
    },
    tooltipsMap: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    boolValue () {
      if (typeof this.value === 'boolean' || typeof this.value === 'number') {
        return !!this.value
      } else {
        return this.valueMap[this.value]
      }
    },
    color () {
      return this.colorMap[this.value] || 'success'
    },
    tooltip () {
      if (this.tooltipsMap[this.value]) {
        return this.tooltipsMap[this.value].replace('{value}', this.value)
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
  //
</style>
