<template lang="pug">
  div(class="white")
    v-alert(
      outlined
      prominent
      :dense="dense"
      :dismissible="dismissible"
      :color='color'
      :icon='icon'
      :type="type"
      :class="paddingClass"
    )
      span(class="drawer--text")
        slot

      template(#prepend)
        e-svg-icon(
          :size="iconSize"
          class="mr-4"
          v-if="type === 'warning'") warning

</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  components: {
    ESvgIcon
  },
  props: {
    iconSize: {
      type: String,
      default: 'xlg'
    },
    dense: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'info',
      validator: (value) => {
        return ['info', 'error', 'warning'].includes(value)
      }
    },
    dismissible: {
      type: Boolean,
      default: false
    },
    removePadding: {
      type: Boolean,
      default: false
    },
    hideIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    paddingClass () {
      return this.removePadding ? 'pa-0' : ''
    },
    icon () {
      if (this.hideIcon) { return false }
      switch (this.type) {
        case 'info': return 'mdi-information'
        case 'warning':
        case 'error' : return 'mdi-alert'
        default : return null
      }
    },
    color () {
      switch (this.type) {
        case 'info': return 'success'
        case 'warning': return 'yellow'
        case 'error' : return 'red'
        default : return null
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
