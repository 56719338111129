<template lang="pug">
  v-menu(v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    :nudge-top="26"
    min-width="290px"
    class="e-input-datetime")

    template(v-slot:activator="{ on }")
      v-text-field(:value="getFieldFormat"
        v-bind="$attrs"
        :class="$attrs.classes"
        v-on="on"
        readonly)

    v-card(class="elevation-0 e-input-datetime__card")
      v-card-text(class="pa-0")
        v-date-picker(v-if="['date', 'datetime'].includes(type)"
          v-model="date"
          :locale="$config.locale.code"
          first-day-of-week="1"
          :max="maxDate"
          :min="minDate"
          color="primary"
          class="elevation-0"
          scrollable)

        v-time-picker(v-if="['time', 'datetime'].includes(type)"
          v-model="time"
          :max="maxDate"
          :min="minDate"
          color="primary"
          class="elevation-0"
          format="24hr"
          scrollable
          use-seconds)
      v-card-actions(class="text-right")
        v-col(cols="12" class="py-0")
          v-row(justify="end")
            v-btn(v-if="isDateTime || clearable" color="primary" @click="cleanup") {{ $t('Cleanup') }}
            v-btn(color="primary" class="ml-2" @click="menu = false") {{ $t('choose') }}
</template>

<script>

// TODO: add select of seconds.
export default {
  props: {
    value: {
      type: null,
      default: null
    },
    range: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'date',
      validator: (value) => {
        return ['date', 'datetime', 'time'].includes(value)
      }
    },
    max: {
      type: Function,
      default: null
    },
    min: {
      type: Function,
      default: null
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    date: null,
    time: null,
    menu: false,
    datetime: null
  }),
  computed: {
    isDateTime () {
      return ['datetime'].includes(this.type)
    },
    pickerFormats () {
      return {
        date: 'YYYY-MM-DD',
        time: 'HH:mm:ss',
        datetime: 'DD-MM-YYYY HH:mm:ss'
      }
    },
    pickerFormat () {
      return this.pickerFormats[this.type]
    },
    fieldFormat () {
      const list = {
        date: 'DD-MM-YYYY',
        time: 'HH:mm:ss',
        datetime: 'DD-MM-YYYY HH:mm:ss'
      }
      return list[this.type]
    },
    getDate () {
      return this._.get(this, 'date', null)
    },
    getTime () {
      return this._.get(this, 'time', null) || (this.isDateTime && '00:00:00')
    },
    getDateTime () {
      const arr = this._.compact([this.getDate, this.getTime])
      return arr.length ? arr.join(' ').trim(' ') : null
    },
    getFieldFormat () {
      const v = this._.get(this.$data, this.type)
      return (v && this.type !== 'time' && !this.isDateTime) ? this.$moment(v).format(this.fieldFormat) : v
    },
    maxDate () {
      return this._.isFunction(this.max) ? this.max() : undefined
    },
    minDate () {
      return this._.isFunction(this.min) ? this.min() : undefined
    }
  },
  watch: {
    'value' (val) {
      if (this.type !== 'time') {
        this[this.type] = this.isDateTime ? val : new this.$moment(val).format(this.pickerFormat)
      }
    },
    getDateTime (val, prev) {
      if (prev !== val) {
        this.$emit('input', val)
      }
    },
    'time' (val) {
      if (val) {
        const splitVal = val.split(':')

        if (this.max) {
          const splitMaxVal = this.maxDate.split(':')

          if (this.$moment([1970, 1, 1, ...splitVal, 0]) > this.$moment([1970, 1, 1, ...splitMaxVal, 0])) {
            this.time = this.maxDate
          }
        }

        if (this.min) {
          const splitMinVal = this.minDate.split(':')

          if (this.$moment([1970, 1, 1, ...splitVal, 0]) < this.$moment([1970, 1, 1, ...splitMinVal, 0])) {
            this.time = this.minDate
          }
        }
      }
    }
  },
  created () {
    if (this.value) {
      if (this.type === 'time') {
        this.time = this.value
      } else if (this.isDateTime) {
        const dateTime = this.value.split(' ')
        this.date = dateTime[0]
        this.time = dateTime[1]
        this.datetime = this.value
      } else {
        const obj = new this.$moment(this.value)
        this.date = obj.format(this.pickerFormats.date)
      }
    }
  },
  methods: {
    now () {
      return new this.$moment()
    },
    cleanup () {
      this.date = null
      this.time = null
      // else {
      //   this[this.type] = this.now().format(this.pickerFormats.date)
      // }
    }
  }
}
</script>

<style lang="scss">
.e-input-datetime {
  &__card {
    .v-picker {
      border-radius: 0 !important;

      &__title {
        height: 88px;
      }
    }
  }
}
</style>
