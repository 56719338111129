<template lang="pug">
  v-progress-linear(indeterminate :height="calcSize" color="primary" v-bind="$attrs")
</template>

<script>
import { VProgressLinear } from 'vuetify/lib'

export default {
  components: {
    VProgressLinear
  },
  props: {
    size: {
      type: String,
      default: 'md',
      validator: (value) => {
        return ['sm', 'md', 'lg'].includes(value)
      }
    }
  },
  data: () => ({
    sizes: {
      sm: 2,
      md: 4,
      lg: 6
    }
  }),
  computed: {
    calcSize () {
      return this.sizes[this.size]
    }
  }
}
</script>

<style lang="scss">
  //
</style>
