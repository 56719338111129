<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="listeners" type="confirmation" max-width="600px" content-class="m-orm-synchronization-dialog")
    template(#title)
      div(class="text-center")
        p {{ $t('m-orm-synchronization-dialog.title') }}
        p(class="display-1 error--text") {{ $t('m-orm-synchronization-dialog.part1') }}
        p(class="error--text lh-normal mb-0") {{ $t('m-orm-synchronization-dialog.part2') }}
        p(class="subtitle-1 error--text lh-normal") {{ $t('m-orm-synchronization-dialog.part3') }}

    template(#actions)
      t-orm-buttons(:items="ormButtons" :classes="['text-center']")
</template>

<script>
import TDialog from '~/components/templates/t-dialog'
import EFlatIcon from '~/components/elements/icons/e-flat-icon'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'

export default {
  components: {
    TDialog,
    EFlatIcon,
    TOrmButtons
  },
  props: {
    type: {
      type: String,
      required: true
    },
    model: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    title: null,
    item: {},
    localLoading: false
  }),
  computed: {
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.$emit('input', event)
            vm.clear()
          }
        }
      )
    },
    ormButtons () {
      return [
        {
          text: 'Cancel',
          attrs: {
            color: 'primary',
            depressed: true,
            text: true
          },
          call: this.close
        },
        {
          text: 'OK',
          attrs: {
            color: 'primary',
            depressed: true,
            class: ['mr-4']
          },
          loading: this.localLoading,
          call: this.close
        }
      ]
    }
  },
  methods: {
    clear (immediate = false) {
      immediate ? this.item = {} : setTimeout(() => {
        this.item = {}
      }, 300)
    },

    close () {
      this.$emit('input', false)
      this.clear()
    },

    fill (item, title) {
      this.item = item
      this.title = title
    }
  }
}
</script>

<style lang="sass">
  .m-orm-delete-dialog
    overflow-y: unset !important

  .lh-normal
    line-height: normal
</style>
