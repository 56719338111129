import InputBuilder from '~/components/abstracts/builders/InputBuilder'
import patterns from '~/const/patterns'

export default class Input {
  model
  component
  provider
  attrs

  constructor (builder) {
    if (!(builder instanceof InputBuilder)) {
      throw new TypeError('Input class expects "InputBuilder" in constructor.')
    }

    const {
      component,
      model,
      provider,
      attrs,
      query,
      request,
      items,
      searchModel,
      fieldVal,
      loadMoreQuery,
      hint,
      asyncDefault,
      cols,
      saveModelInstance,
      emitSearch,
      defaultVal
    } = builder

    if (!this.inPattern(component)) {
      throw new Error(`${component} is not presented in list of available components.`)
    }

    const { model: pProvider, attrs: pAttrs } = this.getPattern(component)

    this.component = component
    this.model = model
    this.provider = Object.assign({}, pProvider, provider)
    this.attrs = Object.assign({}, pAttrs, attrs)

    // TODO: Create appropriate autocomplete input class.
    // TODO: create dynamic binding to current input config.
    if (query) {
      this.query = query
    }

    if (loadMoreQuery) {
      this.loadMoreQuery = loadMoreQuery
    }

    if (defaultVal) {
      this.default = defaultVal
    }

    if (hint) {
      this.hint = hint
    }

    if (request) {
      this.request = request
    }

    if (items) {
      this.items = items
    }

    if (searchModel) {
      this.searchModel = searchModel
    }

    if (fieldVal) {
      this.fieldVal = fieldVal
    }

    if (asyncDefault) {
      this.asyncDefault = asyncDefault
    }

    if (cols) {
      this.cols = cols
    }

    if (saveModelInstance !== null && saveModelInstance !== undefined) {
      this.saveModelInstance = saveModelInstance
    }

    if (emitSearch !== null && emitSearch !== undefined) {
      this.emitSearch = emitSearch
    }
  }

  inPattern (component) {
    return Object.keys(patterns).includes(component)
  }

  getPattern (component) {
    return patterns[component]
  }
}
