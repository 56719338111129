import Vue from 'vue'

export default function ({ app, store }) {
  Vue.prototype.$dialog = {
    open: (payload) => {
      return store.dispatch('dialogs/open', payload)
    },
    close: (type) => {
      return store.dispatch('dialogs/close', type)
    }
  }
}
