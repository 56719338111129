import _commonData from '~/modules/receipt/mixins/getters/commonData'
import Shifts from '~/models/directories/Shifts'

const shiftData = {
  mixins: [_commonData],
  computed: {
    shift () {
      return this._.get(this.authenticatedCashier, 'shift', null)
    },
    isOpenedShift () {
      return this.shift && this.shift.status === Shifts.processingStatuses.OPENED
    }
  }

}

export default shiftData
