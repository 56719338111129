import CurrentUser from '~/modules/auth/Models/CurrentUser'

export default {
  api: {
    load () {
      return CurrentUser.api().getCurrent()
    }
  },
  get () {
    return CurrentUser.query().withAll().first()
  }
}
