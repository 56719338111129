<template lang="pug">
  v-row(class="m-group-dynamic-increment" justify="end")
    v-col(cols="12" class="py-0")
      v-row(no-gutters)
        v-col(cols="12" class="py-0 m-group-dynamic-increment__row")
          slot(v-bind="scopedAttrs")

          e-button-close(
            v-if="!isDefCount"
            size="xs"
            class="m-group-dynamic-increment__close-btn"
            @click="removeField")

        v-col(cols="12" class="py-0 mb-6")
          v-row(v-if="incrementable" justify="end" no-gutters)
            v-col(cols="auto" class="py-0")
              e-link(size="lg" @click="addField") + {{$t('Add row')}}
</template>

<script>
import { VCheckbox } from 'vuetify/lib'
import TOrmLabel from '~/components/templates/orm/_includes/t-orm-label'
import EButtonClose from '~/components/elements/buttons/e-button-close'
import ELink from '~/components/elements/links/e-link'

export default {
  components: {
    VCheckbox,
    TOrmLabel,
    EButtonClose,
    ELink
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    counter: 1
  }),
  computed: {
    isDefCount () {
      return this.counter === this.defaultCounter
    },
    incrementable () {
      return this.counter !== (('max' in this.rendererConfig.counter) && this.rendererConfig.counter.max)
    },
    rendererConfig () {
      return this.item.renderer.attrs.config
    },
    defaultCounter () {
      return this._.get(this.rendererConfig, 'counter', { default: 1 }).default
    },
    scopedAttrs () {
      return {
        // TODO: CONTEXT!
        scopedAttrs: Object.assign({}, this.item.renderer.attrs, {
          context: this.item.renderer.attrs.context,
          config: Object.assign({}, this.item.renderer.attrs.config, {
            counter: this.counter
          })
        })
      }
    }
  },
  created () {
    if (this._.isArray(this.value) && this.value.length > 0) {
      this.counter = this.value.length
    } else {
      this.counter = this.defaultCounter
    }
  },
  methods: {
    addField () {
      if (this.incrementable) {
        this.counter += 1
      }
    },
    removeField () {
      if (this.counter > 1) {
        this.counter -= 1
      }
    }
  }
}
</script>

<style lang="scss">
  .m-group-dynamic-increment {
    &__row {
      position: relative;
      padding-right: 34px !important;
    }

    &__close-btn {
      position: absolute !important;
      bottom: 36px;
      right: 0;
    }
  }
</style>
`
