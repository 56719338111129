import Search from '~/models/abstracts/Search'

export class AccountingGoodsSearch extends Search {
  static entity = 'accountinggoodssearch'

  static fields () {
    return {
      id: this.attr(null),
      code: this.attr(null),
      name: this.attr(null),
      count: this.attr(null)
    }
  }

  toString () {
    return `${this.name} (${this.code})`
  }
}

export default AccountingGoodsSearch
