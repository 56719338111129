export default {
  data: _ => ({
    parentContext: null,
    selectedItem: {},
    ctx: {}
  }),
  methods: {
    fill (context, item, ctx = {}) {
      this.parentContext = context
      this.selectedItem = item
      this.ctx = ctx

      if (this._.isFunction(this.afterFill)) {
        this.afterFill(context, item, ctx)
      }
    }
  }
}
