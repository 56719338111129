<template lang="pug">
  div(class="input-multiple-select")
    v-text-field(
      v-model="filter.search"
      class="filled-input"
      :placeholder="$t('Search')"
      prepend-inner-icon="mdi-magnify"
      :loading="loadings.search"
      outlined
      hide-details
    )
    div(
      v-if="!items.length"
      class="input-multiple-select__no-data-text"
    ) {{ $t('No items were found') }}
    div(v-else)
      div(class="input-multiple-select__items-wrap")
        //v-checkbox(
        //  :ripple="false"
        //  class="mt-0 pt-2 pb-1"
        //  hide-details
        //)
        //  template(v-slot:label)
        //    p(class="input-multiple-select__item-text") {{ $t('Select all') }} ({{ totalItems }})
        v-checkbox(
          v-for="item in items"
          :key="item.id"
          @change="onSelect(item, $event)"
          :ripple="false"
          :input-value="isChecked(item)"
          class="mt-0 pt-2 pb-1"
          hide-details
        )
          template(v-slot:label)
            p(
              class="input-multiple-select__item-text"
              :title="item.toString()"
            ) {{ item.toString() }}
      e-link(
        v-if="items.length < totalItems"
        :loading="loadings.more"
        @click="loadMore"
        class="input-multiple-select__load-more-btn"
        underline
      ) {{ $t('Show more') }}
</template>

<script>
import ELink from '~/components/elements/links/e-link'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'

export default {
  name: 'InputMultipleSelect',
  components: {
    ELink,
    EProgressCircular
  },
  mixins: [],
  props: {
    model: {
      type: null,
      default: null
    },
    queryFilter: {
      type: Object,
      default: () => {}
    },
    selectedItems: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    totalItems: null,
    filter: {
      offset: 1,
      limit: 5,
      search: null
    },
    requestedItems: [],
    debouncedSearch: null,
    loadings: {
      more: false,
      search: false
    }
  }),
  computed: {
    items () {
      let items = this.model.query().all() || []
      if (this.filter.search) {
        const ids = this._.map(this.requestedItems, item => item.id)
        items = this.model.query().whereIdIn(ids).get()
      }
      return items
    }
  },
  watch: {
    'filter.search' () {
      this.filter.offset = 1
      this.debouncedSearch()
    }
  },
  created () {
    this.requestItems()
    this.debouncedSearch = this._.debounce(this.requestItems, 800)
  },
  methods: {
    onSelect (item, checked) {
      let selectedItems = JSON.parse(JSON.stringify(this.selectedItems))
      if (checked) {
        selectedItems.push(item)
      } else {
        selectedItems = selectedItems.filter(selectedItem => selectedItem.id !== item.id)
      }
      this.$emit('change', selectedItems)
    },
    isChecked (item) {
      return Boolean(this._.findIndex(this.selectedItems, item) !== -1)
    },
    async loadMore () {
      this.filter.offset += 1
      await this.requestItems({
        loadingKey: 'more'
      })
    },
    async requestItems ({ loadingKey = 'search' } = {}) {
      if ((!this.$Organization && !this.$User.isSuperAdmin) || this.loading || this._.get(this.$attrs, 'disabled') || this._.get(this.$attrs, 'readonly')) {
        return
      }
      try {
        this.loadings[loadingKey] = true
        const filter = {
          ...this.filter,
          ...this.queryFilter
        }
        const data = this._.get(await this.model.api().filter(filter).all({}, { persistBy: 'insertOrUpdate' }), 'response.data')
        this.requestedItems = this._.get(data, 'data', [])
        this.totalItems = this._.get(data, 'meta.totalItems', null)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loadings[loadingKey] = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.input-multiple-select {
  &__item-text {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    color: #161B25;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
  }

  &__items-wrap {
    max-height: 205px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__load-more-btn {
    font-size: 12px !important;
    font-weight: 300;
    margin: 5px auto 0;
    display: block;
  }

  &__no-data-text {
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    margin-top: 8px;
    color: #161B25;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0;
  }

  &::v-deep {
    .v-label {
      width: calc(100% - 32px);
    }

    .v-icon {
      font-size: 20px;
    }
  }
}
</style>
