<template lang="pug">
  v-text-field(class='e-input-with-button' v-bind="$attrs" v-on="$listeners")
    template(slot='append')
      e-svg-icon(:name="$attrs.icon.text" @click.native="cbBtn")
</template>

<script>
import { VTextField } from 'vuetify/lib'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  components: {
    VTextField,
    ESvgIcon
  },
  props: {
    svgIcon: {
      type: String,
      default: ''
    },
    ctx: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    cbBtn () {
      this.$attrs.cb(this.ctx).then((code) => {
        this.$emit('input', code)
      })
    }
  }
}
</script>

<style lang="scss">
  .e-input-with-button {
      .v-input {
        &__append-inner {
          cursor: pointer;
        }
      }
  }
</style>
