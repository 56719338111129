<template lang="pug">
  notifications(width="auto" position="bottom right" :reverse="true")
    template(slot="body" slot-scope="props")
      div(@click="props.close")
        v-alert(class="ma-1 alert" :type="getType(props)")
          slot
           span(class="alert__msg"
             v-html="props.item.data.translate ? $t(props.item.text) : props.item.text"
             :title="props.item.data.translate ? $t(props.item.text) : props.item.text")
</template>

<script>
export default {
  methods: {
    getType (props) {
      return props.item.data.type ? props.item.data.type : 'success'
    }
  }
}
</script>

<style lang="scss" scoped>
  .alert {
    &::v-deep {
      .v-icon {
        align-self: center;
      }
    }

    &__msg {
      font-size: .8rem;
      max-width: 500px;
      display: -webkit-box;
      line-clamp: 3;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 60px;
    }
  }
</style>
