<template lang="pug">
  span(v-if="value")
    v-tooltip(v-if="tooltip" top)
      template(#activator="{on, attrs}")
        v-row(no-gutters)
          v-col(cols="auto" v-on="on")
            span(class="hidden-value")
              | {{value}}
            span(:style="style")
              | {{visibleText}}
          v-col(cols="1" @click="copyToClipboard" v-if="clipboardCopy" class="ml-1" style="cursor:pointer")
            e-svg-icon()
              | copy
      span()
        | {{tooltip}}
    template(v-else)
      span(:title="value")
        | {{visibleText}}

</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import clipboard from '~/mixins/global/_clipboard'
export default {
  components: { ESvgIcon },
  mixins: [clipboard],
  props: {
    value: {
      type: String,
      default: null
    },
    tooltip: {
      type: String,
      default: null
    },
    visibleSymbols: {
      type: Number,
      default: 10
    },
    clipboardCopy: {
      type: Boolean,
      default: false
    },
    minWidth: {
      type: Number,
      default: null
    }
  },
  computed: {
    visibleText () {
      if (this.value.length > this.visibleSymbols) {
        return this.value.substr(0, this.visibleSymbols) + '...'
      } else {
        return this.value
      }
    },
    style () {
      if (this.minWidth) {
        return {
          display: 'inline-block',
          'min-width': this.minWidth + 'px'
        }
      }
      return null
    }
  },
  methods: {
    copyToClipboard () {
      try {
        this.copy(this.value, '.hidden-value')
        this.$notification.success(this.$t('Copied to clipboard'))
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .hidden-value{
    visibility: hidden;
    position: absolute;
  }

</style>
