import ModelsCtor from '~/mixins/computed/connector/ModelsCtor'

const itemsFilter = {
  mixins: [ModelsCtor],
  computed: {
    itemsFilter () {
      const list = [this.$modelsCtor.group.getOrmFiltersObject(this.filters, false)]

      if (this.$modelsCtor.model.groupAble && this.selectedGroup) {
        list.push({ [this.$modelsCtor.model.getOrmTreeConfig().parent]: this.selectedGroup })
      }

      return Object.assign(...list)
    }
  }
}

export default itemsFilter
