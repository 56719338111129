import Search from '~/models/abstracts/Search'

export class GoodsGroupsSearch extends Search {
  static entity = 'goodsgroupssearch'

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(null)
    }
  }

  toString () {
    return this.name
  }
}

export default GoodsGroupsSearch
