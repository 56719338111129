import { Model } from '@vuex-orm/core'
import Dialog from '~/models/system/Dialog'
import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import AppNotifications from '~/services/Notifications/AppNotifications'
import TimestampAble from '~/models/mixins/TimestampAble'

export class Reviews extends ChainInheritance(Directory, [
  TimestampAble
]) {
  static entity = 'reviews'
  static paginated = true
  static defaultSortParam = 'dateCreated'

  static statuses = {
    new: {
      value: 'new',
      text: 'Нове'
    },
    closed: {
      value: 'closed',
      text: 'Опрацьовано'
    },
    in_progress: {
      value: 'in_progress',
      text: 'В роботі'
    }
  }

  static ormTrans = {
    single: 'Review',
    multy: 'Reviews'
  }

  static fields () {
    return {
      id: this.attr(null),
      label: this.attr(null),
      status: this.attr(null),
      message: this.attr(null),
      executor: this.attr(null),
      dateCreated: this.attr(null),
      organization: this.attr(null),
      finishedAt: this.attr(null),
      takenAt: this.attr(null),
      link: this.attr(null)
    }
  }

  get dateCreatedString () {
    return this.getDateTime(this.dateCreated)
  }

  get dateTakenString () {
    return this.getDateTime(this.takenAt)
  }

  get dateFinishedString () {
    return this.getDateTime(this.finishedAt)
  }

  get statusLabel () {
    return this._.get(Reviews.statuses[this.status], 'text')
  }

  get dateOfJob () {
    return this.getDateTime(this.finishedAt || this.takenAt)
  }

  get adminName () {
    return `${this._.get(this.executor, 'profile.firstName', '')} ${this._.get(this.executor, 'profile.lastName', '')}`
  }

  static ormHeaders = [
    { text: 'Date created', value: 'dateCreatedString', sortable: true, filterable: false },
    { text: 'Organization ', value: 'organization.name', sortable: false, filterable: false },
    { text: 'Message', value: 'message', sortable: false, filterable: false },
    { text: 'Label', value: 'label', sortable: false, filterable: false },
    { text: 'Status', value: 'statusLabel', width: '150', sortable: true, filterable: false },
    { text: 'Admin', value: 'adminName', sortable: false, filterable: false },
    { text: 'Date', value: 'dateOfJob', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormActions = [
    {
      name: 'read'
    },
    {
      name: 'take',
      text: 'Take into work',
      icon: {
        type: 'e-svg-icon',
        text: 'signature-agent'
      },
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'confirmation').first()
        await dialog.open({
          title: 'Would you like to take this review into job?',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            try {
              await Reviews.api().processFeedback(item.id)
              AppNotifications.success('Review successfully taken into job')
            } catch (e) {
              AppNotifications.error(e)
            }
          }
        })
      },
      visible: item => item.status === Reviews.statuses.new.value
    },
    {
      name: 'close',
      text: 'Close feedback',
      icon: {
        type: 'e-svg-icon',
        text: 'done'
      },
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'confirmation').first()
        await dialog.open({
          title: 'This reviews successfully processed?',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            try {
              await Reviews.api().closeFeedback(item.id)
              AppNotifications.success('Review successfully closed')
            } catch (e) {
              AppNotifications.error(e)
            }
          }
        })
      },
      visible: item => item.status === Reviews.statuses.in_progress.value
    }
  ]

  static ormDialogs = {
    read: 'm-orm-view-dialog'
  }

  static ormDialogsConfig = {
    read: {
      title: item => ({ name: item.organization.name }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'dateCreatedString',
            label: 'Date created'
          },
          {
            model: 'organization.name',
            label: 'Organization '
          },
          {
            model: 'message',
            label: 'Message'
          },
          {
            model: 'link',
            label: 'Page'
          },
          {
            model: 'label',
            label: 'Label'
          },
          {
            model: 'statusLabel',
            label: 'Status'
          },
          {
            model: 'adminName',
            label: 'Admin'
          },
          {
            model: 'dateTakenString',
            label: 'Start date'
          },
          {
            model: 'dateFinishedString',
            label: 'End date'
          }
        ]
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.closeFeedback = function (feedbackId) {
        return this.post(Model.$routes.reviews.close(feedbackId), {}, { dataKey: null })
      }
      configActions.processFeedback = function (feedbackId) {
        return this.post(Model.$routes.reviews.process(feedbackId), {}, { dataKey: null })
      }

      return configActions
    }
  }
}

export default Reviews
