export default class ApplePayService {
  async isReadyToPay (merchantId) {
    const ApplePaySession = window.ApplePaySession

    if (ApplePaySession && ApplePaySession.canMakePayments()) {
      return await ApplePaySession.canMakePaymentsWithActiveCard(merchantId)
    }
  }

  createPaymentRequest ({ amount, label }) {
    return {
      countryCode: 'UA',
      currencyCode: 'UAH',
      merchantCapabilities: [
        'supports3DS'
      ],
      supportedNetworks: [
        'visa',
        'masterCard'
      ],
      total: {
        type: 'final',
        label,
        amount
      }
    }
  }

  _onClickPaymentButton ({ amount, label, performValidation, payment }) {
    const ApplePaySession = window.ApplePaySession
    const session = new ApplePaySession(3, this.createPaymentRequest({ amount, label }))

    session.begin()

    session.onvalidatemerchant = async (e) => {
      const res = await performValidation(e.validationURL)
      session.completeMerchantValidation(res)
    }

    session.onpaymentauthorized = async (e) => {
      if (e.payment.token) {
        await payment(e.payment.token)
        session.completePayment(ApplePaySession.STATUS_SUCCESS)
      } else {
        session.completePayment(ApplePaySession.STATUS_FAILURE)
      }
    }
  }
}
