<template lang="pug">
  t-dialog(
    v-bind="$attrs"
    :value="isOpened"
    v-on="$listeners"
    :max-width="width"
    @input="close"
  )
    template(#title v-if="title" )
      p(class='text-center ma-0 pa-0') {{ title }}
    template(#content)
      v-row(class="mt-3 mb-3")
        v-col(cols="12" v-if="text" v-html="text")
        v-col(cols="12" v-if="showHint" class="pb-0 hint")
          e-svg-icon(size="xmd" style="vertical-align: -4px" class="mr-2") {{ _.get(hint, 'icon', '') }}
          | {{ hintText }}
    template(#actions)
      v-row(no-gutters justify="center")
        v-col(cols="auto")
          v-btn(@click="confirm" color="primary" class="main-button button-min-width" depressed :loading="confirmLoading")
            | {{ $t(buttonText.approve) }}
        v-col(cols="auto")
          v-btn(@click="close" class="ml-2 ml-sm-4 secondary-button color-error button-min-width" outlined :loading="rejectLoading")
            | {{ $t(buttonText.dismiss) }}
</template>

<script>
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import TDialog from '~/components/templates/t-dialog'
import Dialog from '~/models/system/Dialog'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import responsive from '~/mixins/pages/responsive'

export default {
  components: {
    TOrmButtons,
    TDialog,
    ESvgIcon
  },
  mixins: [responsive],
  props: {
    type: {
      type: String,
      default: 'confirmation'
    }
  },
  data: () => ({
    confirmLoading: false,
    rejectLoading: false
  }),
  computed: {
    dialog () {
      return Dialog.query().where('type', this.type).first()
    },
    isOpened () {
      return this._.get(this.dialog, 'isOpened', false)
    },
    params () {
      return this._.get(this.dialog, 'params', '')
    },
    title () {
      return this.checkText(this._.get(this.params, 'title', ''))
    },
    text () {
      return this.checkText(this._.get(this.params, 'text', ''))
    },
    hint () {
      return this._.get(this.params, 'hint', '')
    },
    showHint () {
      const visible = this.hint && this._.get(this.hint, 'visible', true)
      return this._.isFunction(visible) ? visible() : visible
    },
    hintText () {
      return this.checkText(this._.get(this.hint, 'text', ''))
    },
    buttonText () {
      const defaultTexts = {
        approve: this.$t('Approve'),
        dismiss: this.$t('Dismiss')
      }
      return this._.get(this.params, 'buttonText', defaultTexts)
    },
    width () {
      return this._.get(this.params, 'width', '500px')
    },
    onConfirm () {
      return this._.get(this.params, 'onConfirm')
    },
    onReject () {
      return this._.get(this.params, 'onReject')
    }
  },
  created () {
    if (!this.dialog) {
      Dialog.insert({
        data: {
          type: this.type
        }
      })
    }
  },
  methods: {
    async close () {
      if (this._.isFunction(this.onReject)) {
        this.rejectLoading = true
        await this.onReject()
        this.rejectLoading = false
      }
      await this.dialog.close(false, true)
    },
    async confirm () {
      if (this._.isFunction(this.onConfirm)) {
        try {
          this.confirmLoading = true
          await this.onConfirm(this)
        } catch (e) {
          this.$handlers.error(e, this)
        } finally {
          this.confirmLoading = false
        }
      }
      await this.dialog.close(true, true)
    },
    checkText (text) {
      return this._.isFunction(text) ? text(this) : this.$t(text)
    }
  }
}
</script>

<style scoped lang="scss">
.hint {
  font-size: 0.75rem;
}
</style>
