import OrmModel from '~/models/abstracts/base/OrmModel'

export default class MediaObject extends OrmModel {
  static entity = 'mediaobject';

  static fields () {
    return {
      // For generating missing foreign keys.
      mediable_id: this.attr(null),

      id: this.attr(null),
      contentUrl: this.attr(null),
      originalName: this.attr(null),
      srcset: this.attr(null),
      size: this.attr(null),
      mimeType: this.attr(null)
    }
  }

  static ormTrans = {
    single: 'MediaObject',
    multy: 'MediaObjects'
  };

  static ormHeaders = [
    //
  ];

  static ormFields = [
    //
  ];
}
