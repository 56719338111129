<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="listeners" type="confirmation" max-width="495px" content-class="m-orm-toggle-status-dialog")
    template(#title)
      span(class="text-center d-inline-block") {{ title }}
      v-avatar(color="white" size="73" class="m-orm-delete-dialog__avatar elevation-1")
        e-flat-icon(:class="item.active ? 'error--text': 'success--text'"
                    :text="item.active ? 'flaticon-garbage' : 'flaticon-repeat'"
                    size="xlg")

    template(#actions)
      t-orm-buttons(:items="ormButtons" :classes="['text-center']")
</template>

<script>
import TDialog from '~/components/templates/t-dialog'
import EFlatIcon from '~/components/elements/icons/e-flat-icon'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'

export default {
  components: {
    TDialog,
    EFlatIcon,
    TOrmButtons
  },
  props: {
    type: {
      type: String,
      required: true
    },
    model: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    title: null,
    item: {},
    localLoading: false
  }),
  computed: {
    dialogConfig () {
      return this.model.getOrmDialogsConfig(this.type)
    },
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.$emit('input', event)
            vm.clear()
          }
        }
      )
    },
    ormButtons () {
      return [
        {
          text: 'Cancel',
          attrs: {
            color: 'primary',
            depressed: true,
            text: true,
            class: ['mr-4']
          },
          call: this.close
        },
        {
          text: 'Confirm',
          attrs: {
            color: this.item.active ? 'error' : 'success',
            depressed: true
          },
          loading: this.localLoading,
          call: this.deactivate
        }
      ]
    }
  },
  methods: {
    clear (immediate = false) {
      immediate ? this.item = {} : setTimeout(() => {
        this.item = {}
      }, 300)
    },

    close () {
      this.$emit('input', false)
      this.clear()
    },

    async deactivate () {
      this.localLoading = true
      try {
        await this.model.api().update(this.item, { active: !this.item.active }, false)

        const context = this.item.active ? 'deactivation' : 'activation'
        const config = this.dialogConfig.notification(this.item)
        config.type = this.$tc(config.type, 1)

        this.$notification.success(this.$t(`m-orm-toggle-status-dialog.notification.${context}`, config))
        this.close()
      } catch (e) {
        this.$handlers.error(e, this.$refs[this.model.entity])
      }
      this.localLoading = false
    },

    fill (item, title) {
      this.item = item
      this.title = title
    }
  }
}
</script>

<style lang="sass">
  .m-orm-toggle-status-dialog
    overflow-y: unset !important

    &__avatar
      position: absolute
      top: -38px
      left: 50%
      right: 50%
      transform: translate(-50%, 0)
</style>
