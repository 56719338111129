import EdsKeyData from '~/services/EdsKey/EdsKeyData'
import Organization from '~/models/directories/Organization'

export default class TaxAuthService {
  static makeKeyData (keyData) {
    return keyData instanceof EdsKeyData ? keyData : new EdsKeyData(keyData)
  }

  static async getAuthData (keyData, signFunction) {
    const data = TaxAuthService.makeKeyData(keyData)
    const EDRPOUCode = data.edrpouCode
    const DRFOCode = data.drfoCode
    const sign = await signFunction(EDRPOUCode || DRFOCode)
    return { EDRPOUCode, DRFOCode, sign }
  }

  static async sendAuthData (keyData, signFunction) {
    const payload = await TaxAuthService.getAuthData(keyData, signFunction)
    if (payload && payload.sign) {
      return Organization.api().sendTaxAuthData(payload)
    } else {
      return null
    }
  }
}
