import ChainInheritance from '~/models/mixins/ChainInheritance'
import Directory from '~/models/abstracts/Directory'

export default class AdminAuthLog extends ChainInheritance(Directory, []) {
  static entity = 'adminAuthLog'
  static defaultSortParam = 'date'
  static ormLoadWithRelations = true
  static paginated = true

  static ormTrans = {
    single: 'Admin auth log',
    multy: 'Admin auth logs'
  }

  static fields () {
    return {
      id: this.attr(null),
      date: this.attr(null),
      event: this.attr(null),
      user: this.attr(null)
    }
  }

  get dateString () {
    return this.getDateTime(this.date)
  }

  get adminFullName () {
    return `${this.user.profile.firstName} ${this.user.profile.lastName}`
  }

  static ormHeaders = [
    { text: 'Admin', value: 'adminFullName', sortable: false, filterable: false },
    { text: 'Event', value: 'event', sortable: false, filterable: false },
    { text: 'Date', value: 'dateString', sortable: true, filterable: false }
  ]
}
