<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="$listeners" type="confirmation" max-width="600px")
    template(#title)
      span(class="text-center d-inline-block")
        | Податкова не приймає будь які заяви до 04.01. Тож, рекомендуємо подати заявку в понеділок
</template>

<script>
import TDialog from '~/components/templates/t-dialog'
import EFlatIcon from '~/components/elements/icons/e-flat-icon'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'

export default {
  components: {
    TDialog,
    EFlatIcon,
    TOrmButtons
  },
  data: () => ({
    title: null,
    item: {},
    localLoading: false
  }),
  computed: {
    ormButtons () {
      return [
        {
          text: 'Cancel',
          attrs: {
            color: 'primary',
            depressed: true,
            text: true
          },
          call: this.close
        },
        {
          text: 'Confirm',
          attrs: {
            color: 'error',
            depressed: true,
            class: ['mr-4']
          },
          loading: this.loading,
          call: this.confirm
        }
      ]
    }
  },
  methods: {
    close () {
      this.$emit('confirmed', false)
      this.$emit('input', false)
    },

    confirm () {
      this.$emit('confirmed', true)
      this.$emit('input', false)
    },
    fill () {
      return null
    }
  }
}
</script>

<style lang="sass">
</style>
