export default superclass => class extends superclass {
  // Getting of all configuration related to model for table.
  static getTableConfig () {
    return {
      'multi-sort': this.multiSort,
      dblClickAction: this.dblClickAction,
      ...this.getSortParams()
    }
  }
}
