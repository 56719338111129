<template lang="pug">
  div()
    div(style="border: 1px solid black;border-radius: 14px")
      v-btn(style="width: 50%" :dark="!isBtn1Transparent" @click="btnClick1" :class="{ transparent: isBtn1Transparent }") {{ btnName1 }}
      v-tooltip(top)
        template(#activator="{on, attrs}")
          v-btn(
            v-on="on"
            v-bind="attrs"
            style="width: 50%"
            @click="btnClick2"
            :dark="isBtn1Transparent"
            :class="{ transparent: !isBtn1Transparent }"
          ) {{ btnName2 }}
        span {{ $t(tooltip) }}
</template>

<script>
export default {
  props: {
    tooltip: {
      type: String,
      default: ''
    },
    btnName1: {
      type: String,
      default: 'Поштучно'
    },
    btnName2: {
      type: String,
      default: 'Вимірна'
    },
    btn1Value: {
      type: String,
      default: null
    },
    btn2Value: {
      type: String,
      default: null
    },
    value: {
      type: null,
      default: null
    }
  },
  data () {
    return {
      isBtn1Transparent: false
    }
  },
  created () {
    if (this.value) {
      this.$emit('input', this.value)
      if (this.value === true) {
        this.isBtn1Transparent = true
      } else if (this.value === false) {
        this.isBtn1Transparent = false
      }
    }
  },
  methods: {
    btnClick1 () {
      this.isBtn1Transparent = false
      if (this.btn1Value) {
        this.$emit('input', this.btn1Value)
      } else {
        this.$emit('input', false)
      }
    },
    btnClick2 () {
      this.isBtn1Transparent = true
      if (this.btn2Value) {
        this.$emit('input', this.btn2Value)
      } else {
        this.$emit('input', true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.transparent {
  background: transparent;
}
</style>
