import Dialog from '~/models/system/Dialog'

export default function ({ app }) {
  setTimeout(() => {
    const dialog = Dialog.query().where('type', 'information').first()
    dialog.open({
      text: 'Зараз робота дпс не стабільна тому збільшений час обробки чеку. В звʼязку з цим можуть бути значні затримки при видачі чеків. Приносимо вибачення за тимчасові незручності.',
      width: '660px'
    })
  }, 2500)
}
