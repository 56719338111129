export default superclass => class extends superclass {
  // Enabled of default sorting.
  static defaultSort = true
  // Enabled of multi sorting in table.
  static multiSort = false
  // Default sorting param.
  static defaultSortParam = 'active'
  // Default sorting order.
  static defaultSortOrder = true

  // Here you can configure sorting of field in some direction.
  static sortParams () {
    return Object.assign({}, {
      'sort-by': this.defaultSortParam,
      'sort-desc': this.defaultSortOrder
    })
  }

  static getSortParams (params) {
    if (!this.defaultSort) {
      return {}
    }

    // TODO: replace by merge strategy ?
    if (params) {
      // const [sortBy, sortDesc] = params
      return this.multiSort ? params : this.sortParams()
    }

    return this.sortParams()
  }
}
