import { Model } from '@vuex-orm/core'

export default class AdTag extends Model {
  static entity = 'advertising_tag';
  static primaryKey = 'tagType'

  static admitadType = 'admitad'
  static expiredAfter = 7776000000 // milliseconds, 90 days

  static fields () {
    return {
      tag: this.attr(null),
      tagType: this.attr(null),
      expiredAt: this.attr(null)
    }
  }
}
