import { Model } from '@vuex-orm/core'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'

export class Suppliers extends PersistAble(OrmModel) {
  static entity = 'suppliers'
  static defaultSortParam = 'name'
  static defaultSortOrder = true

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(null)
    }
  }

  static ormFields = [
    {
      model: 'name',
      component: 'v-text-field',
      provider: {
        vid: 'name',
        name: 'Name ',
        rules: 'required'
      },
      attrs: {
        type: 'text',
        label: 'Name ',
        outlined: true
      },
      default: (ctx, item) => (ctx === 'update' && item.name) || null
    }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      return configActions
    }
  }
}

export default Suppliers
