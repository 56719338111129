import Dialog from '~/models/system/Dialog'

const informationDialog = {
  computed: {
    informationDialog () {
      return Dialog.query().where('type', 'information').first()
    }
  }
}
export default informationDialog
