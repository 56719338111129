import Dictionary from '~/models/abstracts/Dictionary'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import PersistAble from '~/models/mixins/PersistAble'

export class TaxCodesDictionary extends ChainInheritance(Dictionary, [
  PersistAble
]) {
  static defaultSort = false
  static entity = 'taxcodesdictionary';

  static persistBy = 'create' ;

  static fields () {
    return {
      id: this.attr(null),
      cReg: this.attr(null),
      cRaj: this.attr(null),
      cSti: this.attr(null),
      nameReg: this.attr(null),
      nameRaj: this.attr(null),
      nameSti: this.attr(null),
      tSti: this.attr(null),
      // TODO possible slow place
      children: this.hasMany(TaxCodesDictionary, 'parentId', 'id'),
      parentId: this.attr(null),
      hasChildren: this.attr(null)
    }
  }

  static ormTrans = {
    single: 'Dpi dictionary',
    multy: 'Dpi dictionary'
  };

  static ormTreeConfig = {
    parentFieldName: 'parentId',
    childrenFieldName: 'children',
    textFieldName: item => item.nameSti || item.nameReg,
    hasChildrenFlagName: 'hasChildren',
    asyncLoading: {
      // TODO implement function arg support
      requestMethod: 'children'
    }
  }

  static ormHeaders = [
  ];

  static ormFields = [
  ];

  static ormActions = [
    //
  ];
}

export default TaxCodesDictionary
