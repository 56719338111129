const mda = {
  computed: {
    morphedAttrs () {
      const attrs = this._.mapValues(this.item.attrs, (value, key) => {
        if (['disabled'].includes(key) && this._.isFunction(value)) {
          return value(this.ctx)
        }

        if (['visible'].includes(key) && this._.isFunction(value)) {
          // TODO define more common way to pass call context (this) in function
          const isVisible = value(this.ctx, this)

          if (!isVisible) {
            this.$emit('input', this.item.default)
            this.input = this.item.default
          }

          return isVisible
        }

        if (['placeholder', 'label'].includes(key)) {
          const splitValue = value.split('|')
          return (splitValue.length >= 2) ? this._.upperFirst(this.$tc(splitValue[0], splitValue[1])) : this._.upperFirst(this.$tc(value, 1))
        }

        return value
      })

      if (['e-input-datetime'].includes(this.item.component) && this.classes && this.classes.length) {
        Object.assign(attrs, {
          classes: this.classes
        })
      }

      return attrs
    }
  }
}

export default mda
