<template lang="pug">
  div(class="e-comparison")
    div(class="e-comparison__text") {{ comparedValue }} / {{ total }} {{ units }}
    v-progress-linear(
      :value="countProgressValue(comparedValue, total)"
      height="8"
      :color="progressColor(countProgressValue(comparedValue, total))"
      background-opacity="0.08"
      class="e-comparison__progress"
    )
</template>

<script>
export default {
  name: 'EComparison',
  props: {
    comparedValue: {
      type: [Number, String],
      default: null
    },
    total: {
      type: [Number, String],
      default: null
    },
    unitsText: {
      type: String,
      default: ''
    }
  },
  computed: {
    units () {
      return this.unitsText ? this.$t(this.unitsText) : ''
    }
  },
  methods: {
    countProgressValue (value, total) {
      return parseFloat((value / (total / 100)).toFixed(2))
    },
    progressColor (val) {
      return val > 90 ? '#FF1744' : '#161B25'
    }
  }
}
</script>

<style scoped lang="scss">
.e-comparison {
  padding: 14px 0;

  &__text {
    font-size: 0.75rem;
    font-weight: 400;
    white-space: nowrap;
  }

  &__progress {
    border-radius: 6px;
    margin-top: 5px;

    &::v-deep {
      .v-progress-linear__determinate {
        border-radius: 6px;
      }
    }
  }
}
</style>
