const filtration = {
  methods: {
    attachFiltrationFields (filters) {
      if (this.model.paginated && this.model.searchable) {
        const fields = this.model.getSearchFields()

        if (this.debouncedSearchString && fields.length) {
          for (const field of fields) {
            filters[field] = this.debouncedSearchString
          }
        }
      }

      return filters
    },

    attachFiltrationHeaders (filters) {
      if (this.model.paginated) {
        const headers = this.model.getFiltrationHeaders()

        if (headers.length) {
          for (const header of headers) {
            filters[header.value] = this.debouncedSearchString
          }
        }
      }

      return filters
    },

    detachFiltrationHeaders (filters) {
      if (this.model.paginated) {
        const headers = this.model.getFiltrationHeaders()

        if (headers.length) {
          for (const header of headers) {
            delete filters[header.value]
          }
        }
      }

      return filters
    }
  }
}

export default filtration
