<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="listeners" :max-width="cardWidth")
    template(#title) {{ title }}

    template(#content)
      ValidationObserver(:ref="model.entity" slim)
        t-orm-fields(v-bind.sync="editedItem"
          :items="model.ormFields"
          :tabs="model.ormTabs"
          :context="context"
          :config="dialogConfig"
          class="mt-4")

    template(#actions)
      t-orm-buttons(:items="ormModalButtons" :context="context" :classes="['text-center']")
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import TDialog from '~/components/templates/t-dialog'
import checkPropCtx from '~/mixins/methods/checkPropCtx'
import fill from '~/mixins/modules/dialogs/fill'
import validate from '~/mixins/validation/validate'

export default {
  components: {
    TOrmFields,
    TOrmButtons,
    TDialog
  },
  mixins: [checkPropCtx, fill, validate],
  props: {
    type: {
      type: String,
      required: true
    },
    model: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    itemLoading: false,
    editedItem: {}
  }),
  computed: {
    dialogCtx () {
      return Object.assign({}, {
        parent: this.selectedItem,
        dialogRefs: this.$refs,
        model: this.model.entity
      }, { ...this.ctx })
    },
    dialogConfig () {
      return {
        ...this.model.getOrmDialogsConfig(this.type),
        ctx: this.dialogCtx
      }
    },
    context () {
      return this._.get(this.dialogConfig.config, 'context', this.parentContext || this.$config.contexts.create)
    },
    apiActionName () {
      return this._.get(this.dialogConfig.config, 'apiActionName', this.$isCreateCtx(this.context) ? 'create' : 'update')
    },
    cardWidth () {
      let width = 650

      // Here we calc width of dialog windows based on num of cols in grid.
      if (this.isGridded && this.dialogConfig.grid[0].nodes.length > 1) {
        width += (this.dialogConfig.grid[0].nodes.length * 230)
      }

      return [width, 'px'].join('')
    },
    isGridded () {
      return this.dialogConfig && ('grid' in this.dialogConfig)
    },
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.clear()
            vm.$emit('input', event)
          }
        }
      )
    },
    isLoading () {
      return this.loading || this.itemLoading
    },
    title () {
      // TODO: create one logic for pattern generation.
      let title, name

      if (this.dialogConfig.title) {
        title = { ...this.dialogConfig.title.split('|') }
      }

      // TODO: move to class config???
      if (this.dialogConfig.config && this.dialogConfig.config.modalName) {
        name = this.dialogConfig.config.modalName
      } else {
        switch (this.context) {
          case this.$config.contexts.create:
            name = 'Creating of'
            break
          case this.$config.contexts.read:
            name = 'Card of'
            break
          case this.$config.contexts.update:
            name = 'Editing of'
            break
        }
      }

      const modalType = (() => {
        if (title) {
          return this.$tc(title[0], title[1])
        } else if (this.dialogConfig.config && this.dialogConfig.config.ucFirst) {
          return this._.upperFirst(this.$tc(this.model.ormTrans.single, 2))
        } else if (this.dialogConfig.config && this.dialogConfig.config.modalName) {
          return this._.lowerFirst(this.$tc(this.model.ormTrans.single, 1))
        } else {
          return this._.lowerFirst(this.$tc(this.model.ormTrans.single, 2))
        }
      })()

      return [
        this.$t(name),
        modalType,
        (this.dialogConfig.config && this.dialogConfig.config.ucFirst) ? this.selectedItem.name : ''
      ].join(' ').trim(' ')
    },
    ormModalButtons () {
      return [
        {
          text: 'Save',
          contexts: this.$config.contexts.only('c.u').concat(['update_local']),
          attrs: {
            class: 'main-button'
          },
          loading: this.itemLoading,
          call: this.save
        }
      ]
    }
  },
  created () {
    this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
  },
  methods: {
    clear () {
      setTimeout(() => {
        this.parentContext = this.$config.contexts.create
        this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
      }, 300)
    },

    close () {
      this.$emit('input', false)

      this.clear()
    },

    toEditContext (item = this.selectedItem) {
      this.$emit('update:ormDialog', { ctx: 'edit', item })
    },

    filterOrmObject (payload) {
      const obj = Object.assign({}, payload)
      const keys = Object.keys(obj)

      const localFields = this.model.getOrmFields({ only: 'local' })
      const notNullFields = this.model.getOrmFields({ only: 'notNull', nested: true })

      for (const item of keys) {
        if (obj[item] === undefined) {
          obj[item] = null
        }

        // Removes null fields only for create context.
        if (this._.isNull(obj[item]) && this.$isCreateCtx(this.context)) {
          delete obj[item]
        }

        // Removes all local fields.
        if (localFields.length && localFields.includes(item)) {
          delete obj[item]
        }

        // Removes all not not nullable fields.
        if (notNullFields.length && notNullFields.includes(item) && this._.isNull(obj[item])) {
          delete obj[item]
        }
      }

      return obj
    },

    async save () {
      if (!await this.validate()) {
        return false
      }

      try {
        this.itemLoading = true
        const { afterSave } = this._.get(this.dialogConfig, 'config.hooks', {})
        let notification
        let data = []

        if (this.dialogConfig.notification) {
          notification = { ...this.dialogConfig.notification.split('|') }
        }
        const payload = this.filterOrmObject(this.editedItem)
        const res = (this.$isCreateCtx(this.context))
          ? await this.model.api()[this.apiActionName](payload)
          : await this.model.api()[this.apiActionName](this.selectedItem, payload)

        if (this.$isCreateCtx(this.context)) {
          data = [
            this._.upperFirst(
              (notification)
                ? this.$tc(notification[0], notification[1])
                : this.$tc(this.model.ormTrans.single, 1)
            ),
            this.$t('was created!')
          ].join(' ').trim()
        } else if (this.model.ormTrans.notificationUpdate) {
          data = [
            this._.upperFirst(
              (notification)
                ? this.$tc(notification[0], notification[1])
                : this.$tc(this.model.ormTrans.notificationUpdate, 1)
            ),
            this.$t('was updated!')
          ].join(' ').trim()
        } else {
          data = [
            this._.upperFirst(
              (notification)
                ? this.$tc(notification[0], notification[1])
                : this.$tc(this.model.ormTrans.single, 1)
            ),
            this.$t('was updated!')
          ].join(' ').trim()
        }
        this.$notification.success(data)

        this.close()

        if (this._.isFunction(afterSave)) {
          const { entities, model } = res
          const instance = this._.get(entities, `${model.entity}[0]`)

          afterSave({
            // Getting of instance with relation based on instance without relations.
            instance: this.model.query().withAll().whereId(instance.primaryVal).first(),
            actions: {
              edit: this.toEditContext
            }
          })
        }
      } catch (e) {
        this.$handlers.error(e, this.$refs[this.model.entity])
      } finally {
        this.itemLoading = false
      }
    },

    afterFill (context, item) {
      this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ from: item, ctx: this.context }))
    }
  }
}
</script>

<style lang="scss">
//
</style>
