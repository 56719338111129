import Dialog from '~/models/system/Dialog'

const contentDialog = {
  computed: {
    contentDialog () {
      return Dialog.query().where('type', 'content').first()
    }
  }
}
export default contentDialog
