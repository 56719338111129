import Dialog from '~/models/system/Dialog'

const signAgentDialog = {
  computed: {
    signAgentDialog () {
      return Dialog.query().where('type', 'signAgentDialog').first()
    }
  }
}
export default signAgentDialog
