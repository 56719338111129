export default {
  props: {
    type: {
      type: String,
      required: true
    },
    model: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  }
}
