<template lang="pug">
  ValidationObserver(ref="form" slim)
    v-form(class="m-dialog-form white" @submit.prevent.stop="submit")
      v-card(class="m-dialog-form__card")
        v-card-title(v-if="'header' in $slots" :class="['py-0']")
          slot(name="header")

        v-card-text(v-if="'default' in  $slots" :class="[...contentClasses, 'py-4']")
          slot(name="default")

        v-card-actions(v-if="'actions' in $scopedSlots" :class="[...contentClasses, 'pt-0 pb-8']")
          slot(name="actions"  v-bind:loading="isLoading")

        div(v-if="'footer' in $slots" :class="[...contentClasses, 'm-dialog-form__footer']")
          slot(name="footer")
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationObserver
  },
  props: {
    onSubmitCall: {
      type: Function,
      required: true
    },
    contentClassName: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    isLoading: false
  }),
  computed: {
    contentClasses () {
      return [
        this.contentClassName || (this.$vuetify.breakpoint.xs ? 'px-6' : 'px-12')
      ]
    }
  },
  methods: {
    async submit () {
      const valid = await this.$refs.form.validate()
      const form = this.onSubmitCall()

      if (valid) {
        this.isLoading = true
        try {
          let res

          if (form.request) {
            res = await form.request()
          }

          form.onSuccess && form.onSuccess(res)
        } catch (e) {
          const message = this._.get(e, 'response.data.message')
          if (message) {
            e.response.data.message = this.$t(message)
          }
          this.$handlers.error(e, this.$refs.form)

          form.onError && form.onError(e)
        }
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.m-dialog-form {
  width: 100%;
  max-width: 450px;
  border-radius: $card-border-radius;

  &__card {
    padding: 30px 0 0;
  }

  &__footer {
    background: $input-bg-gray;
    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
    padding-top: 22px;
    padding-bottom: 22px;
  }
}
</style>
