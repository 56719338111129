import { Model } from '@vuex-orm/core'
import Administration from '~/models/abstracts/Administration'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import PersistAble from '~/models/mixins/PersistAble'
import TimestampAble from '~/models/mixins/TimestampAble'
import AssignAble from '~/models/mixins/AssignAble'
import Dialog from '~/models/system/Dialog'

export default class TransferRequests extends ChainInheritance(Administration, [
  TimestampAble,
  AssignAble,
  PersistAble
]) {
  static entity = 'transferrequests'
  static paginated = true
  static defaultSortParam = 'date'
  static ormLoadWithRelations = true

  static ormTrans = {
    single: 'Payment transfer',
    multy: 'Payment transfers'
  }

  static statuses = {
    pending: {
      text: 'Чекає на обробку',
      value: 'pending'
    },
    declined: {
      text: 'Відхилено',
      value: 'declined'
    },
    approved: {
      text: 'Схвалено',
      value: 'approved'
    }
  }

  static fields () {
    return super.fields({
      id: this.attr(null),
      sum: this.attr(null),
      organizationPayer: this.attr(null),
      organizationRecipient: this.attr(null),
      payment: this.attr(null),
      status: this.attr(null)
    })
  }

  get senderString () {
    return this._.get(this.organizationPayer, 'name', '–')
  }

  get recipientString () {
    return this._.get(this.organizationRecipient, 'name', '–')
  }

  get sumString () {
    return (this._.get(this.payment, 'sum', this.sum) / 100).toFixed(2)
  }

  static ormHeaders = [
    { text: 'Status', value: 'status', sortable: true, filterable: false },
    { text: 'Sender', value: 'senderString', sortable: false, filterable: false },
    { text: 'Recipient', value: 'recipientString', sortable: false, filterable: false },
    { text: 'Sum', value: 'sumString', sortable: false, filterable: false, align: 'right' },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormColsComponents = {
    status: {
      component: 'e-radio-status',
      attrs: {
        valueMap: {
          approved: true,
          pending: true,
          declined: true
        },
        colorMap: {
          approved: '#161b25',
          pending: '#ff8a00',
          declined: '#FF002B'
        },
        tooltipsMap: {
          pending: this.statuses.pending.text,
          approved: this.statuses.approved.text,
          declined: this.statuses.declined.text
        }
      }
    }
  }

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormActions = [
    {
      name: 'approve',
      text: 'Approve',
      icon: {
        type: 'e-svg-icon',
        text: 'done'
      },
      visible: item => item.status === TransferRequests.statuses.pending.value,
      call: async (item) => {
        const confirmationDialog = Dialog.query().where('type', 'confirmation').first()
        await confirmationDialog.open({
          title: 'Confirm payment transfer',
          width: '460px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            await TransferRequests.api().approveTransfer(item.id)
          }
        })
      }
    },
    {
      name: 'decline',
      text: 'Dismiss',
      icon: {
        type: 'e-svg-icon',
        text: 'decline'
      },
      visible: item => item.status === TransferRequests.statuses.pending.value,
      call: async (item) => {
        const confirmationDialog = Dialog.query().where('type', 'confirmation').first()
        await confirmationDialog.open({
          title: 'Decline payment transfer',
          width: '460px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            await TransferRequests.api().declineTransfer(item.id)
          }
        })
      }
    }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.approveTransfer = function (id) {
        return this.put(Model.$routes.transferrequests.execute(id), { save: false })
      }
      configActions.declineTransfer = function (id) {
        return this.put(Model.$routes.transferrequests.decline(id), { save: false })
      }
      return configActions
    }
  }
}
