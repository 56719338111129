import Pivot from '~/models/abstracts/system/Pivot'

export default class UserManagerCashRegisters extends Pivot {
  static entity = 'userManagerCashRegisters'
  static primaryKey = ['userId', 'cashRegisterId']

  static fields () {
    return {
      userId: this.attr(null),
      cashRegisterId: this.attr(null)
    }
  }
}
