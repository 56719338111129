const errorsMessages = {
  privatBankFirstDocument: {
    message: 'перший документ не прийнято',
    component: 'block-privat-bank-first-document-error',
    callEvent: (ctx) => {
      ctx.$gtm.push({
        event: 'show_privat_bank_order_err',
        organization: ctx._.get(ctx.$Organization, 'owner.email')
      })
    }
  }
}

export default errorsMessages
