import Pivot from '~/models/abstracts/system/Pivot'

export default class OrganizationManagers extends Pivot {
  static entity = 'organizationManager'
  static primaryKey = ['organizationId', 'userId']

  static fields () {
    return {
      organizationId: this.attr(null),
      userId: this.attr(null)
    }
  }
}
