<template lang="pug">
  t-static-field()
    template(#label="" v-if="label")
      | {{ $t(label)}}
    e-link-path(:show-label="false" :item="value" class=" mb-4 label" style="margin-left: 0 !important" v-if="!loading")
    e-progress-linear(size="sm" v-else)
</template>

<script>
import ELinkPath from '~/components/elements/links/e-link-path'
import TStaticField from '~/components/templates/t-static-field'

export default {
  components: { TStaticField, ELinkPath },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
