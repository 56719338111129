import { Model } from '@vuex-orm/core'

export default class ProcessingTransactions extends Model {
  static entity = 'processingTransactions';

  static statuses = {
    created: 'CREATED',
    signed: 'SIGNED',
    done: 'DONE',
    error: 'ERROR'
  }

  static fields () {
    return {
      id: this.attr(null),
      localId: this.attr(null),
      type: this.attr(null),
      status: this.attr(null)
    }
  }
}
