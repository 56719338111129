const checkPropCtx = {
  methods: {
    $isReadCtx (v) {
      return this.$config.contexts.isRead(v)
    },
    $isCreateCtx (v) {
      return this.$config.contexts.isCreate(v)
    },
    $isUpdateCtx (v) {
      return this.$config.contexts.isUpdate(v)
    }
  }
}

export default checkPropCtx
