
const ctx = {
  props: {
    context: {
      type: String,
      default: 'default'
    }
  }
}

export default ctx
