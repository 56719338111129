<template lang="pug">
  v-progress-circular(
    :indeterminate="true"
    :rotate="360"
    :size="calcSize"
    :value="value"
    :width="width"
    :color="color"
  ) {{ value }}
</template>

<script>
import { VProgressCircular } from 'vuetify/lib'

export default {
  components: {
    VProgressCircular
  },
  props: {
    value: {
      type: null,
      default: null
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => {
        return ['sm', 'md', 'lg', 'xl', 'xxl'].includes(value)
      }
    },
    width: {
      type: [Number, String],
      default: 2
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
  data: () => ({
    sizes: {
      sm: 16,
      md: 24,
      lg: 36,
      xl: 65,
      xxl: 80
    }
  }),
  computed: {
    calcSize () {
      return this.sizes[this.size]
    }
  }
}
</script>

<style lang="scss">
//
</style>
