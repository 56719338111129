export default superclass => class extends superclass {
  // Field for soft deletable models.
  static fields (fields) {
    const obj = Object.assign({
      dateCreated: this.attr(null),
      dateModified: this.attr(null)
    }, fields)

    return super.fields.length ? super.fields(obj) : obj
  }

  get createdAt () {
    return this.getDate(this.dateCreated)
  }

  get updatedAt () {
    return this.getDate(this.dateModified)
  }
}
