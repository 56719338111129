<template lang="pug">
  ValidationObserver(:ref="model.entity")
    t-orm-fields-content(v-bind.sync="editedItem" :ctx="contentCtx" :items="model.ormFields" :context="context")
</template>

<script>
import TOrmFieldsContent from '~/components/templates/orm/t-orm-fields-content'

export default {
  components: {
    TOrmFieldsContent
  },
  props: {
    model: {
      type: Function,
      required: true
    },
    property: {
      type: String,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    editedItem: {}
  }),
  computed: {
    Model () {
      return this.model
    },
    context () {
      return this._.get(this.config, 'context', this.$config.contexts.create)
    },
    contentCtx () {
      return Object.assign({}, {
        parent: this.selectedItem
      }, { ...this.config.ctx })
    }
  },
  watch: {
    editedItem: {
      handler (cur) {
        // TODO temporary workaround
        // if (!await this.$refs[this.model.entity].validate()) {
        //   return false
        // }
        const fields = this.Model.deepMorph(cur)
        if (!this._.isEmpty(fields.profile)) {
          cur.profile = fields.profile
        }
        const model = new this.Model(cur)

        if (!this._.isEmpty(fields.password) && !this._.isEmpty(fields.passwordConfirmation)) {
          Object.assign(model, { password: fields.password, passwordConfirmation: fields.passwordConfirmation })
        }
        //* ***********
        this.$emit(`update:${this.property}`, model)
      },
      deep: true
    }
  },
  created () {
    this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
  }
}
</script>

<style lang="scss" scoped>
  //
</style>
