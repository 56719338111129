import { Model } from '@vuex-orm/core'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import Directory from '~/models/abstracts/Directory'

export default class SmsInvoice extends ChainInheritance(Directory, []) {
  static entity = 'smsInvoice'
  static paginated = false
  static defaultSortParam = 'createdAt'
  static ormLoadWithRelations = true

  static ormTrans = {
    single: 'Invoice',
    multy: 'Invoices'
  }

  static fields () {
    return {
      id: this.attr(null),
      date: this.attr(null),
      number: this.attr(0),
      amount: this.attr(0),
      billing: this.attr({}), // todo: relation!?
      description: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null)
    }
  }

  get amountString () {
    return typeof this.amount === 'number' && (this.amount / 100).toFixed(2)
  }

  get organizationName () {
    return this._.get(this.billing, 'organizationData.name')
  }

  get organizationPayerCode () {
    const code = this._.get(this.billing, 'payerCode')
    return (code) ? '#' + code : ''
  }

  get dateAsString () {
    return this.getDateTime(this.date || this.createdAt)
  }

  static ormHeaders = [
    { text: 'Organization ', value: 'organizationName', sortable: true, filterable: false },
    { text: 'Date', value: 'dateAsString', sortable: true, filterable: false },
    { text: 'Number', value: 'number', sortable: true, filterable: false },
    { text: 'Sum', value: 'amountString', sortable: true, filterable: false },
    { text: 'Payment code', value: 'organizationPayerCode', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormDialogs = {
    read: 'm-orm-view-dialog'
  }

  static ormActions = [
    {
      name: 'read'
    },
    {
      name: 'downloadPdf',
      text: 'Download PDF',
      icon: {
        type: 'e-svg-icon',
        text: 'pdf'
      },
      call: async (item) => {
        if (item && item.id) {
          const pdf = await SmsInvoice.api().getSmsInvoicePdf(item.id)
          const blob = new Blob([this._.get(pdf, 'response.data', '')], { type: 'application/pdf' })
          window.open(URL.createObjectURL(blob), '_blank')
        }
      }
    }
  ]

  static ormDialogsConfig = {
    read: {
      title: item => ({ type: this.ormTrans.single, name: item.number }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'organizationName',
            label: 'Organization '
          },
          {
            model: 'dateAsString',
            label: 'Date'
          },
          {
            model: 'number',
            label: 'Number'
          },
          {
            model: 'amountString',
            label: 'Sum'
          },
          {
            model: 'organizationPayerCode',
            label: 'Payment code'
          }
        ]
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.generateSmsInvoice = function (payload) {
        return this.post(Model.$routes.smsInvoice.generateInvoice(), payload, { save: false })
      }
      configActions.getSmsInvoicePdf = function (id) {
        return this.get(Model.$routes.smsInvoice.getPdf(id), {
          responseType: 'blob',
          save: false
        })
      }

      return configActions
    }
  }
}
