<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="listeners" max-width="900px")
    template(#title) {{ title }}

    template(#content)
      ValidationObserver(:ref="model.entity" slim)
        m-stepper(
          :stepper="stepper"
          :invalidSteps="invalidSteps()"
          v-bind.sync="editedItem"
          @change="stepChanged"
          ref="stepper"
        )

    template(#actions)
      t-orm-buttons(:items="ormModalButtons" :context="context")
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import TDialog from '~/components/templates/t-dialog'
import checkPropCtx from '~/mixins/methods/checkPropCtx'
import fill from '~/mixins/modules/dialogs/fill'
import validate from '~/mixins/validation/validate'
import Stepper from '~/view_configs/modules/stepper/Stepper'
import MStepper from '~/components/modules/stepper/m-stepper'

/* TODO IMPORTANT!!! this component presented only for demonstration. It must be refactored for future usage */

export default {
  components: {
    MStepper,
    TOrmFields,
    TOrmButtons,
    TDialog
  },
  mixins: [checkPropCtx, fill, validate],
  props: {
    type: {
      type: String,
      required: true
    },
    model: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    itemLoading: false,
    editedItem: {},
    initialItem: {},
    nextEmptyStep: null
  }),
  computed: {
    stepperConfig () {
      return this.dialogConfig.stepperConfig
    },
    stepperFields () {
      const fields = []
      this.dialogConfig.stepperFields.forEach((items, key) => {
        fields[key] = []
        items.forEach((item) => {
          fields[key].push(this.model.ormFields.find(field => field.model === item))
        })
      })
      return fields
    },
    stepper () {
      const stepperComponents = []
      this.stepperFields.forEach((items, key) => {
        if (!this._.isEmpty(this.stepperConfig.stepComponents[key])) {
          stepperComponents[key] = this.stepperConfig.stepComponents[key]
          Object.assign(stepperComponents[key].attrs, { config: this.dialogConfig })
        } else {
          stepperComponents[key] = {}
          stepperComponents[key].component = TOrmFields
          stepperComponents[key].attrs = {
            items,
            context: this.context,
            config: this.dialogConfig
          }
        }
      })
      return new Stepper(
        stepperComponents,
        this.stepperConfig.initialStep,
        this.stepperConfig.texts,
        this.stepperConfig.stepperAttrs,
        this.stepperConfig.stepAttrs
      )
    },
    dialogCtx () {
      return Object.assign({}, {
        parent: this.selectedItem,
        dialogRefs: this.$refs,
        model: this.model.entity
      }, { ...this.ctx })
    },
    dialogConfig () {
      return {
        ...this.model.getOrmDialogsConfig(this.type),
        ctx: this.dialogCtx
      }
    },
    context () {
      return this._.get(this.dialogConfig.config, 'context', this.parentContext || this.$config.contexts.create)
    },
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.clear()
            vm.$emit('input', event)
          }
        }
      )
    },
    isLoading () {
      return this.loading || this.itemLoading
    },
    title () {
      // TODO: create one logic for pattern generation.
      let title, name

      if (this.dialogConfig.title) {
        title = { ...this.dialogConfig.title.split('|') }
      }

      // TODO: move to class config???
      if (this.dialogConfig.config && this.dialogConfig.config.modalName) {
        name = this.dialogConfig.config.modalName
      } else {
        switch (this.context) {
          case this.$config.contexts.create:
            name = 'Creating of'
            break
          case this.$config.contexts.read:
            name = 'Card of'
            break
          case this.$config.contexts.update:
            name = 'Editing of'
            break
        }
      }

      const modalType = (() => {
        if (title) {
          return this.$tc(title[0], title[1])
        } else if (this.dialogConfig.config && this.dialogConfig.config.ucFirst) {
          return this._.upperFirst(this.$tc(this.model.ormTrans.single, 2))
        } else if (this.dialogConfig.config && this.dialogConfig.config.modalName) {
          return this.$tc(this.model.ormTrans.single, 1)
        } else {
          return this.$tc(this.model.ormTrans.single, 2)
        }
      })()

      return [
        this.$t(name),
        modalType,
        (this.dialogConfig.config && this.dialogConfig.config.ucFirst) ? this.selectedItem.name : ''
      ].join(' ').trim(' ')
    },
    ormModalButtons () {
      return [
        {
          text: 'Cancel',
          contexts: this.$config.contexts.only('c.u'),
          attrs: {
            color: 'primary',
            text: true,
            depressed: true
          },
          class: ['mr-2'],
          call: this.close
        },
        {
          text: this.nextEmptyStep ? 'Next' : 'Save',
          contexts: this.$config.contexts.only('c.u'),
          attrs: {
            color: 'primary',
            depressed: true
          },
          loading: this.itemLoading,
          call: this.save
        }
      ]
    }
  },
  created () {
    this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
  },
  methods: {
    invalidSteps () {
      const observer = this.$refs[`${this.model.entity}`]
      const invalidSteps = []
      if (observer) {
        const invalidFields = []
        this._.forOwn(observer.fields, (value, key) => {
          if (value.invalid && value.validated) {
            invalidFields.push(key)
          }
        })
        if (!invalidFields.length) { return [] }
        const firstStepInvalid = this._.intersection(this.stepper.stepComponents[0].attrs.config.stepperFields[0], invalidFields)
        if (firstStepInvalid.length) {
          invalidSteps.push(1)
        }
        if (invalidFields.length > firstStepInvalid.length) {
          invalidSteps.push(2)
        }
      }
      return invalidSteps
    },
    clear () {
      setTimeout(() => {
        this.parentContext = this.$config.contexts.create
        this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
      }, 300)
    },

    close () {
      this.$emit('input', false)

      this.clear()
    },

    toEditContext (item = this.selectedItem) {
      this.$emit('update:ormDialog', { ctx: 'edit', item })
    },

    // We filter payload properties by values (all except null).
    // TODO: We pick only dirty and changed and required fields except primary key.
    // const fields = this.$refs[this.model.entity].fields
    // const mapped = this._.pickBy(fields, (i, index) => {
    //   return (i.dirty && i.changed) || [this.model.entity.primaryKey].includes(index)
    // })
    // const object = this._.pick(this.editedItem, Object.keys(mapped))
    filterOrmObject (payload) {
      const obj = Object.assign({}, payload)
      const keys = Object.keys(obj)

      const localFields = this.model.getOrmFields({ only: 'local' })
      const notNullFields = this.model.getOrmFields({ only: 'notNull', nested: true })

      for (const item of keys) {
        if (obj[item] === undefined) {
          obj[item] = null
        }

        // Removes null fields only for create context.
        if (this._.isNull(obj[item]) && this.$isCreateCtx(this.context)) {
          delete obj[item]
        }

        // Removes all local fields.
        if (localFields.length && localFields.includes(item)) {
          delete obj[item]
        }

        // Removes all not not nullable fields.
        if (notNullFields.length && notNullFields.includes(item) && this._.isNull(obj[item])) {
          delete obj[item]
        }
      }

      return obj
    },

    async save () {
      const { afterSave } = this._.get(this.dialogConfig, 'config.hooks', {})

      if (this.nextEmptyStep) {
        return this.$refs.stepper.goTo(this.nextEmptyStep)
      }

      if (!await this.validate()) {
        return false
      }

      let notification
      let data = []

      if (this.dialogConfig.notification) {
        notification = { ...this.dialogConfig.notification.split('|') }
      }

      const payload = this.filterOrmObject(this.editedItem)

      this.itemLoading = true
      try {
        const res = (this.$isCreateCtx(this.context))
          ? await this.model.api().create(payload)
          : await this.model.api().update(this.selectedItem, payload)

        if (this.$isCreateCtx(this.context)) {
          data = [
            this._.upperFirst(
              (notification)
                ? this.$tc(notification[0], notification[1])
                : this.$tc(this.model.ormTrans.single, 1)
            ),
            this.$t('was created!')
          ].join(' ').trim()
        } else if (this.model.ormTrans.notificationUpdate) {
          data = [
            this._.upperFirst(
              (notification)
                ? this.$tc(notification[0], notification[1])
                : this.$tc(this.model.ormTrans.notificationUpdate, 1)
            ),
            this.$t('was updated!')
          ].join(' ').trim()
        } else {
          data = [
            this._.upperFirst(
              (notification)
                ? this.$tc(notification[0], notification[1])
                : this.$tc(this.model.ormTrans.single, 1)
            ),
            this.$t('was updated!')
          ].join(' ').trim()
        }
        this.$notification.success(data)

        if (!this.$refs.stepper.goToNext()) {
          this.close()
        }

        if (this._.isFunction(afterSave)) {
          const { entities, model } = res
          const instance = this._.get(entities, `${model.entity}[0]`)

          afterSave({
            // Getting of instance with relation based on instance without relations.
            instance: this.model.query().withAll().whereId(instance.primaryVal).first(),
            actions: {
              edit: this.toEditContext
            }
          })
        }
      } catch (e) {
        this.$handlers.error(e, this.$refs[this.model.entity])
      }
      this.itemLoading = false
    },

    stepChanged (data) {
      this.nextEmptyStep = data.nextEmptyStep
    },
    afterFill (context, item) {
      this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ from: item, ctx: this.context }))
      this.$nextTick().then(() => {
        this.nextEmptyStep = this.$refs.stepper.nextEmptyStep
      })
    }
  }
}
</script>

<style lang="scss">
  //
</style>
