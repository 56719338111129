<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="$listeners" :max-width="cardWidth")
    template(#title)  {{title}}

    template(#content)
      m-group-grid-renderer(:config="gridConfig")

</template>

<script>
import MGroupGridRenderer from '~/components/modules/groups/m-group-grid-renderer'
import TDialog from '~/components/templates/t-dialog'

export default {
  components: {
    MGroupGridRenderer,
    TDialog
  },
  props: {
    type: {
      type: String,
      required: true
    },
    model: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    itemLoading: false,
    parentItem: {}
  }),
  computed: {
    dialogConfig () {
      return {
        ...this.model.getOrmDialogsConfig(this.type)
      }
    },
    cardWidth () {
      let width = 650

      // Here we calc width of dialog windows based on num of cols in grid.
      if (this.isGridded && this.dialogConfig.grid[0].nodes.length > 1) {
        width += (this.dialogConfig.grid[0].nodes.length * 230)
      }

      return this.dialogConfig.width || [width, 'px'].join('')
    },
    isGridded () {
      return this.dialogConfig && ('grid' in this.dialogConfig)
    },
    isLoading () {
      return this.loading || this.itemLoading
    },
    gridConfig () {
      return Object.assign({}, this.dialogConfig, {
        parentObject: this.parentItem,
        attrs: this.$attrs,
        listeners: this.$listeners
      })
    },

    title () {
      if (!this.dialogConfig.title) { return '' }
      return this.$t(this.dialogConfig.title, { name: this._.get(this.parentItem, 'name', '') })
    }
  },
  methods: {
    close () {
      this.$emit('input', false)
    },

    fill (context, item, ctx = {}) {
      this.ctx = ctx
      this.parentItem = item
    }
  }
}
</script>

<style lang="scss">
  //
</style>
