export default superclass => class extends superclass {
  // Indicate that to current model can be assigned some models in simple way (checkbox in tree or select table).
  static isSimpleAssignable = true

  static getAssignMap (parent) {
    return Object.assign({}, parent)
  }

  static getAssignMapFor (model) {
    const map = this.getAssignMap()

    return model.entity in map ? map[model.entity] : null
  }
}
