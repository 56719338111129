export default superclass => class extends superclass {
  // Indicates that current model is filterable.
  static isFilterable = true

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      classes: ['ml-2'],
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      }
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'space-between'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                md: 4
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static getFilters (list = []) {
    return this.ormFilters.concat(list)
  }
}
