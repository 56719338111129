<template lang="pug">
  ValidationObserver(:ref="model.entity" slim)
    t-orm-fields(v-bind.sync="editedItem"
                :items="model.ormFields"
                :tabs="model.ormTabs"
                :context="context"
                :config="wrapperConfig")
</template>

<script>
export default {
  components: {
    TOrmFields: () => import('~/components/templates/orm/t-orm-fields')
  },
  props: {
    model: {
      type: Function,
      required: true
    },
    property: {
      type: String,
      required: true
    },
    grid: {
      type: Array,
      default: () => ([])
    },
    config: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    editedItem: {}
  }),
  computed: {
    Model () {
      return this.model
    },
    relationVal () {
      return this._.get(this.config.ctx, `parent.${this.property}`, null)
    },
    context () {
      return this._.get(this.config, 'context', this.$config.contexts.create)
    },
    wrapperCtx () {
      return Object.assign({}, {
        parent: this.selectedItem
      }, { ...this.config.ctx })
    },

    wrapperConfig () {
      return {
        ctx: this.wrapperCtx,
        grid: this.grid
      }
    }
  },
  watch: {
    editedItem: {
      async handler (cur) {
        if (!await this.$refs[this.model.entity].validate()) {
          return false
        }

        const model = this.relationVal ? Object.assign(this.relationVal, cur) : new this.Model(cur)

        // TODO: improve this (this add partial serializing when we edit item with related model).
        // TODO: We may create this by wrapper
        model.partialSerialize = true

        this.$emit(`update:${this.property}`, model)
      },
      deep: true
    }
  },
  created () {
    const payload = {
      ctx: this.context
    }

    if (this.relationVal) {
      payload.from = this.relationVal
    }

    this.$set(this.$data, 'editedItem', this.model.getFieldsObject(payload))
  }
}
</script>

<style lang="scss" scoped>
  //
</style>
