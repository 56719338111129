<template lang="pug">
  v-btn(color="rgba(22, 27, 37, 0.5)" fab text :class="getCls" v-bind="$attrs" v-on="$listeners")
    v-icon(size="24") mdi-close
</template>

<script>
import sz from '~/mixins/props/sizes'

export default {
  mixins: [sz],
  computed: {
    getCls () {
      return [
        'elevation-0',
        'e-button-close',
        `e-button-close__${this.size}`
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .e-button-close {
    &__xs {
      width: 1.125rem !important;
      height: 1.125rem !important;

      .v-btn__content .v-icon{
        font-size: 0.8rem;
        height: 1.250rem;
      }
    }
  }
</style>
