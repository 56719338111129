import informationDialog from '~/mixins/dialogs/informationDialog'

const billingBlocked = {
  mixins: [informationDialog],
  computed: {
    isBillingBlocked () {
      return this._.get(this.$Organization, 'billing.status') === 'disabled'
    }
  },
  methods: {
    async isBillingBlockedDialog () {
      return await this.informationDialog.open({
        hideCloseButton: true,
        width: '660px',
        text: this.$t('To issue checks, please pay the bill for the use of cash registers'),
        buttonText: this.$t('Okay')
      })
    }
  }
}

export default billingBlocked
