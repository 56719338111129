import { render, staticRenderFns } from "./t-orm-fields-content-wrapper.vue?vue&type=template&id=0ecec978&scoped=true&lang=pug&"
import script from "./t-orm-fields-content-wrapper.vue?vue&type=script&lang=js&"
export * from "./t-orm-fields-content-wrapper.vue?vue&type=script&lang=js&"
import style0 from "./t-orm-fields-content-wrapper.vue?vue&type=style&index=0&id=0ecec978&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ecec978",
  null
  
)

export default component.exports