<template lang="pug">
  div
    slot
</template>

<script>
export default {
  data: () => ({
    //
  })
}
</script>

<style scoped lang="scss">
//
</style>
