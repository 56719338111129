<template lang="pug">
  div(class="pb-4")
    v-row(class="font-weight-bold")
      v-col(cols="2")
        | {{$t('Code')}}
      v-col(cols="5")
        | {{$t('Name')}}
      v-col(cols="2")
        | {{$t('Price')}}
      v-col(cols="3")
        | {{$t('Barcode ')}}
    v-row(v-for="item in items" :key="item.id")
      v-col(cols="2")
        | {{item.code}}
      v-col(cols="5")
        | {{item.name}}
      v-col(cols="2")
        | {{price(item)}}
      v-col(cols="3")
        | {{item.barcode || '-'}}

</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    price (item) {
      return item.priceFloat.toFixed(2)
        .replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
    }
  }
}
</script>

<style scoped>

</style>
