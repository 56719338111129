const responsive = {
  computed: {
    xsDevice () {
      return this.$vuetify.breakpoint.xs
    },
    smDevice () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    },
    mdDevice () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md
    }
  }
}

export default responsive
