<template lang="pug">
  div
    switching-sidebar
</template>

<script>
import SwitchingSidebar from '~/modules/sidebar/views/components/switching-sidebar'

export default {
  name: 'Sidebars',
  components: {
    SwitchingSidebar
  }
}
</script>

<style scoped lang="scss">

</style>
