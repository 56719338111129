<template lang="pug">
  v-file-input(v-bind="$attrs" v-on="$listeners" type="file" ref="input" class="e-input-file")
</template>

<script>
export default {
  methods: {
    click () {
      this.$refs.input.$refs.input.click()
    }
  }
}
</script>

<style lang="scss" scoped>
  .e-input-file {
    display: none;
    position: absolute;
    height: 0.1px;
    width: 0.1px;
    overflow: hidden;
    opacity: 0;
    z-index: -1;
  }
</style>
