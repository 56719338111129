export default {
  computed: {
    configFilters () {
      let filter = this._.get(this.config, 'filter', null)

      if (this._.isFunction(filter)) {
        filter = filter({
          parent: this.selectedItem,
          route: this.$route,
          $i18n: this.$i18n
        })
      }

      return filter
    }
  }
}
