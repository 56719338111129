<template lang="pug">
  v-col(cols="12" class="py-0")
    v-row()
      v-col(
        v-for="(item, index) in items"
        :key="index"
        v-bind="fieldCols(item)"
        class="py-0"
      )
        template(v-if="item.group")
          component(#default="{ scopedAttrs }"
            v-if="inContext(item)"
            :ctx="morphedCtx(item)"
            :is="item.group"
            :value="fieldVal(item)"
            :key="[item.model, '-', index].join('')"
            :item="item"
            @input="$emit(['update:', item.model].join(''), $event)")

            template(v-if="item.fields")
              //- TODO: replace by single component
              v-row(v-for="(field, index) in item.fields" :key="index" no-gutters)
                template(v-if="inContext(field)")
                  t-orm-label(v-if="field.label" :item="field")

                v-col(v-bind="fieldCols(item)" class="py-0")
                  e-input-wrapper(
                    :ctx="morphedCtx(item)"
                    :item="field"
                    :value="fieldVal(field)"
                    :key="[field.model, '-', index].join('')"
                    @input="$emit(['update:', field.model].join(''), $event)")

            template(v-else-if="item.renderer")
              component(#default="{ items, on, reactive }"
                        v-bind="scopedAttrs || item.renderer.attrs"
                        :is="item.renderer.component"
                        :item="item"
                        :value="fieldVal(item)"
                        @input="$emit(['update:', item.model].join(''), $event)")
                //- TODO: replace by single component
                v-row(v-for="(field, index) in items" :key="index" no-gutters)
                  template(v-if="inContext(field)")
                    t-orm-label(v-if="field.label" :item="field")

                  v-col(v-bind="rendererFieldCols(item)" class="py-0")
                    e-input-wrapper(
                      :ctx="morphedCtx(item)"
                      :item="field"
                      :dynamic="true"
                      :value="scopedval(reactive, field)"
                      :key="[field.model, '-', index].join('')"
                      @input="callListeners(on, field, $event)")

        template(v-else)
          //- TODO: replace by single component
          template(v-if="inContext(item)")
            t-orm-label(v-if="item.label && item.type !=='dynamic-field' && _.get(item, 'attrs.visible', true)" :item="item" :class="item.labelClassName")

            template(v-if="item.type !== 'dynamic-field'")
              e-input-wrapper(
                :ctx="morphedCtx(item)"
                :item="item"
                :dynamic="_.get(item.attrs, 'dynamic', false)"
                :value="fieldVal(item)"
                :key="[item.model, '-', index].join('')"
                @input="$emit(['update:', item.model].join(''), $event)")

            template(v-else)
              e-input-dynamic(
                :header="item.header"
                :currentDynamicField.sync="currentDynamicField"
                :field-component="item.fieldComponent",
                :field-names="item.models"
                :fields-labels="item.labels"
                :label="item.label"
                :field-attrs="item.fieldAttrs"
                :field-provider="item.fieldProvider"
                :name-pattern="item.namePattern"
                :values="fieldsValues(item)"
                :initial-count="item.initialCount"
                :max="item.max"
                @input="$emit(['update:', currentDynamicField].join(''), $event)")
                template(#label="{label}")
                  t-orm-label(:item="{label:label}")
</template>

<script>
import contexts from '~/mixins/props/contexts'
import ctx from '~/mixins/props/ctx'
import EInputDynamic from '~/components/elements/inputs/e-input-dynamic'
import TOrmLabel from '~/components/templates/orm/_includes/t-orm-label'
import EInputWrapper from '~/components/elements/inputs/e-input-wrapper'
import MGroupCollapse from '~/components/modules/groups/m-group-collapse'
import MGroupBordered from '~/components/modules/groups/m-group-bordered'
import MGroupRenderer from '~/components/modules/groups/m-group-renderer'
import MGroupDynamicIncrement from '~/components/modules/groups/m-group-dynamic-increment'

export default {
  components: {
    EInputDynamic,
    TOrmLabel,
    EInputWrapper,
    MGroupCollapse,
    MGroupBordered,
    MGroupRenderer,
    MGroupDynamicIncrement
  },
  mixins: [ctx, contexts],
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    currentDynamicField: null
  }),
  methods: {
    morphedCtx (item) {
      return {
        field: item,
        attrs: this.$attrs,
        fields: this.items,
        context: this.context,
        route: this.$route,
        $i18n: this.$i18n,
        ...this.ctx
      }
    },
    inContext (item) {
      if (process.env.NODE_ENV === 'development' && Number.isFinite(item.context)) {
        alert('Please replace previous context based on integer on new array representation.')
        // eslint-disable-next-line no-console
        console.error(item)
      }

      return (typeof item.context === 'undefined') || item.context.includes(this.context)
    },
    fieldVal (item) {
      return this._.isFunction(item.fieldVal)
        ? item.fieldVal(this.morphedCtx(item))
        : this._.get(this.$attrs, item.model)
    },
    scopedval (reactive, item) {
      return this._.get(reactive, item.model)
    },
    fieldsValues (item) {
      return this._.pick(this.$attrs, item.models)
    },
    callListeners (listeners, item, value) {
      listeners.input(value, item)
    },
    fieldCols (field) {
      if (field.component === 'v-checkbox') {
        return { cols: 'auto' }
      }
      return field.cols || (field.label ? { cols: 12, md: 7, lg: 7, xl: 8 } : { cols: 12 })
    },
    rendererFieldCols (field) {
      const fieldConfig = field.renderer.attrs.config.prototype

      if (fieldConfig.component === 'v-checkbox') {
        return { cols: 'auto' }
      }
      return fieldConfig.label ? { cols: 12, md: 8, lg: 7, xl: 8 } : { cols: 12 }
    }
  }
}
</script>

<style scoped lang="scss">
  //
</style>
