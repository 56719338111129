<template lang="pug">
  t-dialog(:value="isOpen" max-width="630px" :onCloseBtn="closeDialog")
    template(#title) {{ _.get(dialog, 'options.title', '') }}
    template(#content)
      div(class="mt-1 mb-0") {{ _.get(dialog, 'options.text', '') }}
      e-button-main(
        v-if="showBtn"
        :enable-icon="false"
        class="d-block mx-auto my-7"
        @click="btnClick"
      ) {{ btnText }}
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TDialog from '~/components/templates/t-dialog'
import EButtonMain from '~/components/elements/buttons/e-button-main'

export default {
  components: {
    TDialog,
    EButtonMain
  },
  data: () => ({
    dialogName: 'mDialogInfo'
  }),
  computed: {
    ...mapState({
      dialog: state => state.dialogs.dialogs.mDialogInfo
    }),
    isOpen () {
      return this._.get(this.dialog, 'options.visible', false)
    },
    showBtn () {
      return this._.get(this.dialog, 'options.button.show', false)
    },
    btnText () {
      return this._.get(this.dialog, 'options.button.text', '') || this.$t('Continue')
    }
  },
  methods: {
    ...mapActions({
      close: 'dialogs/closeWithOptions'
    }),
    closeDialog () {
      this.close({
        type: this.dialogName
      })
    },
    btnClick () {
      const storeAction = this._.get(this.dialog, 'options.button.action', '')
      if (storeAction && typeof storeAction === 'function') {
        storeAction()
      }
      this.closeDialog()
    }
  }
}
</script>

<style scoped lang="scss">
//
</style>
