import { Model } from '@vuex-orm/core'

export class ReceiptPaymentMethods extends Model {
  static entity = 'receiptpaymentmethod'

  static fields () {
    return {
      id: this.attr(null),
      paymentType: this.attr(null),
      label: this.attr(null)
    }
  }
}

export default ReceiptPaymentMethods
