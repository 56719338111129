<template lang="pug">
  div
    p(class="mb-3") Це означає, що в ДПС діє інший ключ ЕЦП (старіший)
    p(class="mb-1") Є два варіанти що з цим робити:
    ol(class="list mb-3")
      li Скористатись “старим ключем”, який діє в ДПС
      li Відкликати всі сертифікати із ПриватБанка, згенерувати (завантажити) новий ключ ЕЦП і через 1 добу (на наступний день) подати заявку новим ключем

    p(class="mb-1")
      span Для того щоб відкликати сертифікати потрібно{{ showInstructions ? ':' : '...' }}
      e-link(
        v-if="!showInstructions"
        class="ml-1"
        @click="showInstructions = true"
      ) Читати далі
    div(v-if="showInstructions")
      ul(class="list mb-3")
        li()
          span Перейдіть за
          e-link(
            href="https://acsk.privatbank.ua/revoke-page"
            class="mx-1"
          ) посиланням
        li Виберіть
          b(class="ml-1") Відкликати сертифікат за допомогою файлового сховища ключів
        li Вкажіть ІПН/ЄДРПОУ
        li Відмітьте всі сертифікати у списку і натисніть
          b(class="ml-1") Відкликати
        li Завантажте ключ ЕЦП який у вас присутній і вкажіть пароль до нього
      p(class="mb-0") Після того як були відізвані всі сертифікати можна згенерувати (завантажити) новий ключ ЕЦП
</template>

<script>
import ELink from '~/components/elements/links/e-link'

export default {
  name: 'BlockPrivatBankFirstDocumentError',
  components: {
    ELink
  },
  data: () => ({
    showInstructions: false
  })
}
</script>

<style scoped lang="scss">
.list {
  li:not(:last-child) {
    margin-bottom: 7px;
  }
}
</style>
