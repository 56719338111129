<template lang="pug">
  v-btn(
    v-if="buttonVisible"
    v-bind="$attrs"
    @click="buttonClick"
    class="google-pay-button"
  )
    e-svg-icon(size="xbg") google-pay

</template>

<script>
import GooglePayConfig from '~/modules/googlePay/config'
import GooglePayService from '~/modules/googlePay/service/GooglePayService'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  components: {
    ESvgIcon
  },
  props: {
    price: {
      type: Number,
      required: true
    },
    merchantId: {
      type: String,
      required: true
    },
    gatewayMerchantId: {
      type: String,
      required: true
    },
    merchantName: {
      type: String,
      required: true
    },
    environment: {
      type: String,
      default: 'TEST'
    },
    paymentGateway: {
      type: String,
      required: true
    }
  },
  data: () => ({
    googlePayService: null,
    buttonVisible: false
  }),
  computed: {
    buttonClick () {
      return this.googlePayService._onClickPaymentButton()
    }
  },
  watch: {
    price (to) {
      if (this.googlePayService) {
        this.googlePayService.setTotalPrice(to)
      }
    }
  },
  created () {
    const config = new GooglePayConfig(this.merchantId, this.gatewayMerchantId, this.merchantName, this.paymentGateway, this.environment)
    this.googlePayService = new GooglePayService(config, this.callback)
    this.googlePayService.setTotalPrice(this.price)
  },
  mounted () {
    if (!document.head.querySelector('script[src="https://pay.google.com/gp/p/js/pay.js"]')) {
      const script = document.createElement('script')
      script.setAttribute('src', 'https://pay.google.com/gp/p/js/pay.js')
      script.onload = this.onScriptLoaded
      document.head.appendChild(script)
    } else {
      this.onScriptLoaded()
    }
  },
  methods: {
    async onScriptLoaded () {
      this.googlePayService.createClient()
      const isReady = await this.googlePayService.isReadyToPay()
      if (isReady) {
        // const button = this.googlePayService.createButton()
        // document.getElementById('googlePayButtonContainer').appendChild(button)
        this.buttonVisible = true
      }
    },
    callback (token, err) {
      this.$emit('click', token, err)
    }
  }
}
</script>

<style scoped>

</style>
