<template lang="pug">
  t-static-field()
    template(#label="" v-if="label")
      | {{ $t(label)}}
    span(:class="itemClasses" )
      template(v-if="!loading")
        |{{static || value}}
      template(v-else)
        e-progress-linear(size="sm" class="mt-3")

</template>

<script>
import EProgressLinear from '~/components/elements/progress/e-progress-linear'
import TStaticField from '~/components/templates/t-static-field'
export default {
  components: { TStaticField, EProgressLinear },
  props: {
    value: {
      type: null,
      default: null
    },
    static: {
      type: String,
      default: null
    },
    classes: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    itemClasses () {
      return ['label'].concat(this.classes)
    }

  }
}
</script>

<style scoped lang="scss">
  .e-orm-static-field-content{
    min-height: $text-field-filled-full-width-outlined-slot-min-height + 19px;
  }
</style>
