import { Model } from '@vuex-orm/core'
import OrmModel from '~/models/abstracts/base/OrmModel'

export default {
  install (components, options) {
    components.Query.prototype.active = function () {
      this.where('active', true)

      return this
    }

    components.Query.prototype.only = function (payload) {
      if (!payload) { return this }

      this.where((model) => {
        const primary = Number.isFinite(+model.primaryVal) ? +model.primaryVal : model.primaryVal

        if (Model._.isArray(payload)) {
          return payload.includes(primary)
        }

        if (payload instanceof OrmModel) {
          return payload.primaryVal === primary
        }

        return payload === primary
      })

      return this
    }

    components.Query.prototype.except = function (payload) {
      if (!payload) { return this }

      this.where((model) => {
        const primary = Number.isFinite(+model.primaryVal) ? +model.primaryVal : model.primaryVal

        if (Model._.isArray(payload)) {
          return !payload.includes(primary)
        }

        if (payload instanceof OrmModel) {
          return payload.primaryVal !== primary
        }

        return payload !== primary
      })

      return this
    }

    components.Query.prototype.default = function () {
      this.where('defaultValue', true)

      return this
    }

    components.Query.prototype.findOrNull = function (id) {
      return this.whereId(id).first() || null
    }

    components.Query.prototype.within = function (model, field, single) {
      this.where(field || Model._.toLower(model.constructor.name), model[model.constructor.primaryKey])

      if (single) {
        return this.first()
      }

      return this.all()
    }
  }
}
