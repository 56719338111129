import Dialog from '~/models/system/Dialog'

const signPluginCommonDialog = {
  computed: {
    signPluginCommonDialog () {
      return Dialog.query().where('type', 'signPluginCommon').first()
    }
  }
}
export default signPluginCommonDialog
