import { mapActions } from 'vuex'
import TDialog from '~/components/templates/t-dialog'

const concreteDialog = {
  inheritAttrs: false,
  components: { TDialog },
  props: {
    item: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      validator: value => value.match('sm|md|lg')
    }
  },
  computed: {
    payload () {
      return this.item.options
    }
  },
  methods: {
    ...mapActions({
      dialogClose: 'dialogs/close'
    }),

    close (confirmed, payload) {
      if ((typeof confirmed === 'boolean' && confirmed)) {
        this.item.resolve(payload || confirmed)
      }

      this.dialogClose(this.item)
    }
  }
}

export default concreteDialog
