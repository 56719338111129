<template lang="pug">
  div(class="dialog")
    div(class="dialog__title") {{ $t(title) }}
    div(
      class="mb-5"
      v-html="message"
    )
</template>

<script>
export default {
  name: 'BlockSingleNotification',
  props: {
    title: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">

</style>
