export default {
  'v-text-field': {
    //
  },
  'v-select': {
    //
  },
  'e-autocomplete': {
    model: null,
    component: 'e-autocomplete',
    provider: {
      vid: null,
      name: null,
      rules: null
    },
    attrs: {
      label: null,
      clearable: true,
      outlined: true,
      // If there is not presented "item-text" property, then we cast value to string.
      'item-text': v => v,
      'return-object': true
    }
  }
}
