const clipboard = {
  methods: {
    copy (value, selector) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(value)
      } else if (window.clipboardData) { // IE
        window.clipboardData.setData('text', value)
      } else { // other browsers, iOS, Mac OS
        this.copyToClipboard(this.$el.querySelector(selector))
      }
    },
    copyToClipboard (element) {
      const oldContentEditable = element.contentEditable
      const oldReadOnly = element.readOnly

      try {
        element.contentEditable = 'true' // specific to iOS
        element.readOnly = false
        this.copyNodeContentsToClipboard(element)
      } finally {
        element.contentEditable = oldContentEditable
        element.readOnly = oldReadOnly
      }
    },
    copyNodeContentsToClipboard (element) {
      const range = document.createRange()
      const selection = window.getSelection()
      range.selectNodeContents(element)
      selection.removeAllRanges()

      selection.addRange(range)
      element.setSelectionRange(0, 999999)

      document.execCommand('copy')
    }
  }
}

export default clipboard
