<template lang="pug">
  div(class="breadcrumbs")
    div(
      v-for="(item, index) in items"
      :key="index"
      class="breadcrumbs__item"
    )
      span(
        :class="{ 'pointer': !lastItem(index) }"
        @click="onClick(item, index)"
      ) {{ $t(item.text) }}
      e-svg-icon(
        v-if="index !== items.length - 1"
        class="breadcrumbs__arrow"
        size="auto"
      ) arrow-right
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  components: { ESvgIcon },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    lastItem (index) {
      return index === (this.items && (this.items.length - 1))
    },
    onClick (item = {}, index) {
      if (!this.lastItem(index) && this._.isFunction(item.call)) {
        item.call(item)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.breadcrumbs {
  display: flex;

  &__item {
    display: flex;
    align-items: center;
    color: #161B25;
    font-size: 13px;
    line-height: 16px;
    cursor: default;
  }

  &__arrow {
    margin: 0 6px;
    width: 8px;
    height: 8px;
    display: block;
  }
}
</style>
