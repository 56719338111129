<template lang="pug">
  div(class="d-flex")
    | {{value}}
    span(class="pl-2" v-if="showAction" @click="action(item)")
      e-hint-tooltip(top :icon="icon") {{$t(tooltipText)}}
</template>

<script>

import EHintTooltip from '~/components/elements/tooltips/e-hint-tooltip'
export default {
  name: 'EOrmTableColumnWithAction',
  components: {
    EHintTooltip
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: 'interlock'
    },
    actionCondition: {
      type: Function,
      default: null
    },
    tooltipText: {
      type: String,
      default: null
    },
    action: {
      type: Function,
      default: null
    }
  },
  computed: {
    showAction () {
      return this.actionCondition ? this.actionCondition(this.item) : true
    }
  }
}
</script>

<style scoped lang="scss">

</style>
