import moment from 'moment'
import lodash from 'lodash'
import routes from '~/const/routes'

export default {
  install (components, options) {
    // Add an instance method or property.
    // TODO: we really need it?
    components.Model.prototype._ = lodash
    components.Model.prototype.$moment = moment

    // Add global (static) method or property.
    components.Model._ = lodash
    components.Model.$moment = moment
    // TODO: we really need it?
    components.Model.$routes = routes
  }
}
