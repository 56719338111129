import Vue from 'vue'
import MOrmTable from '~/components/modules/tables/orm/m-orm-table'
import MOrmSelectTable from '~/components/modules/tables/orm/m-orm-select-table'
import MGroupGridRenderer from '~/components/modules/groups/m-group-grid-renderer'

export default function ({ app }) {
  Vue.component('MOrmTable', MOrmTable)
  Vue.component('MOrmSelectTable', MOrmSelectTable)
  // Need this because e use this component in recursive rendering
  // TODO define why we can`t use local registering of components in this case
  Vue.component('MGroupGridRenderer', MGroupGridRenderer)
}
