import Vue from 'vue'

export default class AppNotifications {
  static defaultParams = {
    duration: 6e3,
    ignoreDuplicates: true
  }

  static error (msg, translate = true) {
    let message = msg
    if (msg && msg.response) {
      message = msg.response.data && (msg.response.data.detail || msg.response.data.message)
    }
    this.notify(message, 'error', translate)
  }

  static success (msg, translate = true) {
    this.notify(msg, 'success', translate)
  }

  static info (msg, translate = true) {
    this.notify(msg, 'info', translate)
  }

  static notify (msg, type, translate = true) {
    Vue.notify({
      ...AppNotifications.defaultParams,
      text: msg,
      data: {
        type,
        translate
      }
    })
  }
}
