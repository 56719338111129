<template lang="pug">
  v-row(v-if="data" class="mb-5")
    v-col(cols="12" class="pb-0")
      m-info-block(:type="this.type")
        v-row(v-if="type === 'info'" no-gutters)
          v-col(cols="12") {{$t('File imported successfully')}}
          v-col(cols="auto") {{$t('Imported goods')}}:
          v-col {{info[0]}}
        v-row(v-if="type === 'warning'" no-gutters)
          v-col(cols="12") {{$t('There are errors in import file. Please check import file and try again')}}
    v-col(cols="12" v-if="data.type=== 'error'" class="pt-0")
      v-row(no-gutters)
        v-col(cols="12" v-if="info.message")
          | {{info.message}}
        v-col(cols="12" v-for="(error, key) in info.context" :key="'error-' + key")
          | {{error}}

</template>

<script>
import MInfoBlock from '~/components/modules/blocks/m-info-block'
export default {
  name: 'MBlockGoodsImportResult',
  components: { MInfoBlock },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  computed: {
    info () {
      return this.data ? this.data.response.data : null
    },
    type () {
      return this.data && this.data.type === 'success' ? 'info' : 'warning'
    }
  }
}
</script>

<style scoped>

</style>
