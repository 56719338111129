import OrmModel from '~/models/abstracts/base/OrmModel'

export default class Group extends OrmModel {
  static entity = 'group';

  static fields () {
    return {
      id: this.attr(null),
      key: this.attr(null),
      name: this.attr(null),
      objects: this.attr(null)
    }
  }

  static ormTrans = {
    single: 'Group',
    multy: 'Groups'
  };

  static ormHeaders = [
    //
  ];

  static ormFields = [
    {
      model: 'id'
    },
    {
      model: 'key'
    },
    {
      model: 'name'
    },
    {
      model: 'objects'
    }
  ];
}
