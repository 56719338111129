<template lang="pug">
  v-card(class="elevation-0 m-group-tree")
    template
      component(v-model="dialog" :is="model.ormDialogs[ormDialog]" v-bind="treeDialogConfig")

      v-col(cols="12" class="py-1")
        v-row(align="center" justify="space-between")
          v-col(cols="6" class="py-1")
            div(class="font-weight-bold subtitle-2")  {{ _.upperFirst($tc(model.ormTrans.multy, 2)) }}

          v-col( cols="6" class="py-1 text-right")
            v-btn(text class="primary--text font-weight-bold subtitle-2" @click="createAction")
              | + {{$t('New group') }}

      v-treeview(v-model="tree.checked"
                 v-bind.sync="tree"
                 :items="items"
                 :item-key="model.primaryKey"
                 :item-children="treeConfig ? treeConfig.childrenFieldName : undefined"
                 :load-children="loadNodes"
                 :selectable="config.selectable"
                 selected-color="success"
                 class="m-group-tree__tree"
                 return-object
                 activatable
                 dense)

        template(#label="{ item, open }")
          v-row(@dblclick="treeItemConfig.dblclick(item)" justify="start" align="center" class="m-group-tree__item-row" no-gutters)
            v-col(cols="12" class="py-0 text-truncate")
              v-icon(:color="item.active ? 'success': 'secondary'") {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
              v-tooltip(top light)
                template(v-slot:activator="{ on }")
                  span(class="primary--text inline-block ml-2 m-group-tree__text" v-on="on" ) {{ item.name }}
                span {{ item.name }}

        template(#append="{ item }")
          t-orm-menu-actions(v-if="!$isReadCtx(context) && !item.isRoot"
                            :items="filteredActions"
                            :target="item")

      e-progress-linear(v-show="treeLoading" size="sm")
</template>

<script>
import { VCard, VCardText } from 'vuetify/lib'
import contexts from '~/mixins/props/contexts'
import checkPropCtx from '~/mixins/methods/checkPropCtx'
import { actions as ormActions, ormConfig } from '~/const/global'
import TOrmMenuActions from '~/components/templates/orm/t-orm-menu-actions'
import MOrmCrudDialog from '~/components/modules/dialogs/orm/m-orm-crud-dialog'
import MOrmToggleStatusDialog from '~/components/modules/dialogs/orm/m-orm-toggle-status-dialog'
import EProgressLinear from '~/components/elements/progress/e-progress-linear'

export default {
  components: {
    MOrmCrudDialog,
    MOrmToggleStatusDialog,
    VCard,
    VCardText,
    TOrmMenuActions,
    EProgressLinear
  },
  mixins: [contexts, checkPropCtx],
  inheritAttrs: false,
  props: {
    model: {
      type: Function,
      default: null
    },
    checked: {
      type: Array,
      default: _ => ([])
    },
    config: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      tree: {
        checked: [],
        active: []
      },
      initial: true,
      dialog: false,
      ormDialog: 'default',
      treeLoading: false
    }
  },
  computed: {
    treeConfig () {
      return this.model.getOrmTreeConfig()
    },
    dialogConfig () {
      return this.model.getOrmDialogsConfig(this.ormDialog, {})
    },
    selected () {
      return this.tree.active[0]
    },
    notRootGroup () {
      return this.selected && !this.selected.isRoot ? this.selected : null
    },
    canCreateGroup () {
      return this.$isReadCtx(this.context) ? false : this.$ability.can(ormActions.create, this.model[ormConfig.modelKey])
    },
    items () {
      const query = this.model.query()

      if (this.treeConfig) {
        query.where(this.treeConfig.parentFieldName, null)
      }

      if (this.model.rootGroup) {
        const obj = {
          isRoot: true,
          name: this.$t('All groups'),
          active: true
        }

        if (this.treeConfig) {
          obj[this.treeConfig.childrenFieldName] =
          !this.initial ? this.deepFilter(query.withAllRecursive(8).get()) : []
        }
        return [this.deepSort(obj)]
      }
      return this.deepFilter(query.get())
    },
    treeDialogConfig () {
      return Object.assign({
        model: this.model,
        loading: this.treeLoading,
        type: this.ormDialog,
        ref: 'modal'
      }, this.dialogConfig)
    },
    treeItemConfig () {
      return Object.assign({
        dblclick: item => !item.isRoot ? this.editAction(item) : null,
        actions: [
          {
            name: 'edit',
            text: 'edit',
            icon: {
              type: 'e-flat-icon',
              text: 'flaticon-edit'
            },
            attrs: {
              color: 'primary'
            },
            isPermitted: item => this.$ability.can(ormActions.update, this.model[ormConfig.modelKey]),
            call: this.editAction
          },
          {
            name: 'toggle',
            text: item => item.active ? 'Deactivate' : 'Activate',
            icon: {
              type: 'e-flat-icon',
              text: item => item.active ? 'flaticon-garbage' : 'flaticon-repeat'
            },
            attrs: {
              color: 'error'
            },
            // attrs: {
            //   color: item => item.active ? 'error' : 'success'
            // },
            isPermitted: item => this.$ability.can(ormActions.delete, this.model[ormConfig.modelKey]),
            call: this.toggleStatusAction
          }
        ]
      }, this.model.ormRowsConfig)
    },
    filteredActions () {
      const intersection = this._.intersectionBy(this.treeItemConfig.actions, this.model.ormActions, 'name')
      return this._.map(intersection, (intersected) => {
        const config = this.model.ormActions.find(modelAction => modelAction.name === intersected.name)
        return Object.assign({}, config, intersected)
      })
    }
  },
  watch: {
    'tree.checked' (cur) {
      this.$emit('update:checked', cur)
    },
    'tree.active' () {
      this.$router.push({ path: this.$route.path, query: this.selected ? { group: this.selected.primaryVal } : {} })
      this.$emit('input', this.selected)
    }
  },
  async created () {
    if (this.checked.length) {
      this.tree.checked = this.checked
    }

    if (this.model && !this.model.rootGroup) {
      this.treeLoading = true
      await this.model.api().all()
      this.treeLoading = false
    }
  },
  methods: {
    deepFilter (list) {
      if (!list.length) {
        return list
      }

      const filtered = list.filter((i) => {
        const conds = []

        if (this.config.filters) {
          for (const prop in this.config.filters) {
            if (this._.isNull(this.config.filters[prop])) {
              continue
            }

            conds.push(i[prop] === this.config.filters[prop])
          }
        }

        const isPasses = this._.compact(conds).length === conds.length

        if (isPasses) {
          if (i.hasChildren || (i[this.treeConfig.childrenFieldName] && i[this.treeConfig.childrenFieldName].length)) {
            i[this.treeConfig.childrenFieldName] = this.deepFilter(i[this.treeConfig.childrenFieldName])
          } else {
            delete i[this.treeConfig.childrenFieldName]
          }

          return isPasses
        }

        return isPasses
      })

      return filtered
    },
    deepSort (list) {
      if (list.childGroups.length) {
        for (let i = 0; list.childGroups[i]; i++) {
          if (list.childGroups[i].hasChildren) {
            this.deepSort(list.childGroups[i])
          }
        }
        list.childGroups = this._.sortBy(list.childGroups, 'name')
      }
      return list
    },
    createAction () {
      this.ormDialog = 'default'
      this.$nextTick(() => {
        this.$refs.modal.fill(this.$config.contexts.create, { parentGroup: this.notRootGroup })
        this.dialog = true
      })
    },
    editAction (item) {
      this.ormDialog = 'edit'
      this.$nextTick(() => {
        this.$refs.modal.fill(this.$config.contexts.update, item)
        this.dialog = true
      })
    },
    toggleStatusAction (item) {
      this.ormDialog = 'toggle'
      this.$nextTick(() => {
        const context = item.active ? 'deactivation' : 'activation'
        const config = this.dialogConfig.title(item)
        config.type = this.$tc(config.type, 1)
        config.children = this.$tc(config.children, 1)

        this.$refs.modal.fill(item, this.$t(`m-orm-toggle-status-dialog.title.${context}`, config))
        this.dialog = true
      })
    },
    loadNodes (item) {
      if (!item.isRoot && !item.hasChildren) {
        return
      }

      const query = this.model.api()

      if (item.isRoot) {
        this.initial = false
        query.root()
      } else if (this.treeConfig) {
        query.parent({ [this.treeConfig.parent]: item })
      }

      return query.all()
    }
  }
}
</script>

<style scoped lang="scss">
  .m-group-tree {
    &__item-row {
      &:hover {
        cursor: default;
      }
    }

    &__tree {
      position: relative;

      ::v-deep .v-treeview-node {
        border-top: 1px solid rgba(0,0,0,0.12);
      }
    }

    &__text {
      vertical-align: bottom;
    }
  }
</style>
