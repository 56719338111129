export default superclass => class extends superclass {
  // Indicates that current model is shortable.
  static isShortAble = true

  // List of headers for short preview.
  static ormShortHeaders = ['code', 'name']

  static getShortHeaders ({ list = [] } = {}) {
    return this.ormShortHeaders.concat(list)
  }
}
