<template lang="pug">
  v-row
    v-col(cols="12")
      v-container(fill-heightstyle="height: calc(100vh - 160px)")
        v-layout(align-center)
          v-flex(text-center)
            h1(class="display-2 primary--text") {{ $t('errors.whoops') }}, {{ error.statusCode }}

            p(class="pa-4 display-1") {{ $t(error.message) }}

            v-btn(color="warning" @click="$router.go(0)") {{ $t('Go back') }}
</template>
<script>

export default {
  layout: 'default',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    error: [String, Error, Object]
  }
}
</script>
<style lang="scss">
  //
</style>
