<template lang="pug">
  v-col(cols="12" class="pa-0")
    m-group-grid-renderer(v-if="isGridded" :config="gridConfig")

    v-row(no-gutters v-else)
      v-col(cols="12")
        v-row
          t-orm-fields-content(v-bind="$attrs" v-on="$listeners" :ctx="ctx" :items="items" :context="context")
</template>

<script>
import contexts from '~/mixins/props/contexts'
import TOrmFieldsContent from '~/components/templates/orm/t-orm-fields-content'
import MGroupGridRenderer from '~/components/modules/groups/m-group-grid-renderer'

export default {
  components: {
    TOrmFieldsContent,
    MGroupGridRenderer
  },
  mixins: [contexts],
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      required: true
    },
    tabs: {
      type: Array,
      default: () => []
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ctx () {
      return this.config ? this.config.ctx : {}
    },
    isGridded () {
      return this.gridConfig.grid && this.gridConfig.grid.length
    },
    gridConfig () {
      return Object.assign({}, this.config, {
        items: this.items,
        attrs: this.$attrs,
        listeners: this.$listeners,
        context: this.context
      })
    }
  }
}
</script>

<style lang="scss">
</style>
