<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="$listeners" type="card" max-width="800px" content-class="m-orm-card-dialog" :loading="loading")
    template(#title)
      span(class="text-center d-inline-block") {{ title }}
    template(#content)
      div(v-if="loading" class="loader")
      t-orm-simple-data(class="mt-1" :items="fieldObjects" v-else)
</template>

<script>
import TDialog from '~/components/templates/t-dialog'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import TOrmSimpleData from '~/components/templates/orm/t-orm-simple-data'

export default {
  components: {
    TOrmSimpleData,
    TDialog,
    TOrmButtons,
    TOrmFields
  },
  props: {
    type: {
      type: String,
      required: true
    },
    model: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    title: null,
    item: {},
    loading: false,
    requestData: null
  }),
  computed: {
    context () {
      return this._.get(this.dialogConfig.config, 'context', this.parentContext || this.$config.contexts.create)
    },
    dialogConfig () {
      return this.model.getOrmDialogsConfig(this.type)
    },
    fields () {
      const config = this.dialogConfig.config
      const mapField = config.mapField
      let fields = []
      if (!this._.isEmpty(mapField)) {
        fields = this._.get(config.fields, this.item[mapField])
      } else {
        fields = config.fields
      }
      return fields
    },
    fieldObjects () {
      const values = []
      for (let i = 0; i < this.fields.length; i++) {
        if ((this._.isFunction(this.fields[i].visible) && this.fields[i].visible(this.item)) || !this._.isFunction(this.fields[i].visible)) {
          values.push({
            label: this.fields[i].label,
            val: this._.get(this.item, this.fields[i].model, null),
            value: this.fields[i].value,
            translate: this.fields[i].translate || false,
            hideEmpty: this.fields[i].hideEmpty || false,
            requestData: this.requestData,
            request: () => this.fields[i].request && this.fields[i].request(this.item)
          })
        }
      }
      return values
    }
  },
  watch: {
    async item (val) {
      await this.getRequestData(val)
    }
  },
  methods: {
    fill (item, title) {
      this.title = title
      this.item = item
    },
    async getRequestData (item) {
      const request = this._.get(this.dialogConfig, 'config.request')
      if (this._.isFunction(request) && item) {
        try {
          this.loading = true
          this.requestData = this._.get(await request(item), 'response.data', null)
        } catch (e) {
          this.$handlers.error(e, this)
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style lang="scss">
.loader {
  height: 300px;
}
</style>
