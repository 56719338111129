<template lang="pug">
  div( class="e-link__path d-inline-block ml-4" v-if="item")
    span(class="mr-2" v-if="showLabel") {{ $t('Group') }}:
    span(class="e-link__path-item primary--text text--darken-1 font-weight-bold") {{ selectedPath }}
</template>

<script>
export default {
  props: {
    showLabel: {
      type: Boolean,
      default: true
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    selectedPath () {
      return this.getRecursivePath(this.item, []).reverse().join(' / ')
    }
  },
  methods: {
    getRecursivePath (group, list) {
      list.push(group.name)

      if (group.parentGroup) {
        this.getRecursivePath(group.parentGroup, list)
      }

      return list
    }
  }
}
</script>

<style lang="scss">
  .e-link {
    &__path {
      vertical-align: middle;

      &-item {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

</style>
