<template lang="pug">
  v-chip(:color="color") {{ name }}
</template>

<script>
export default {
  props: {
    value: {
      type: [Object, null],
      required: true
    },
    colors: {
      type: Array,
      default: () => (['#d0f5da', '#fefad3', '#e1e5fc', '#fcd9db'])
    }
  },
  computed: {
    name () {
      return this.value ? this.value.name : null
    },
    color () {
      return this.value ? this.colors[this.value.primaryVal] : null
    }
  }
}
</script>

<style lang="scss" scoped>
  //
</style>
