<template lang="pug">
  tr()
    td() {{$t(item.label)}}
    td(v-if="!loading")
      span() {{ item.translate ? $t(value) : value }}
    td(v-else)
      e-progress-linear
</template>

<script>
import EProgressLinear from '~/components/elements/progress/e-progress-linear'

export default {
  components: { EProgressLinear },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      value: ''
    }
  },
  async created () {
    this.value = await this.processValue()
  },
  methods: {
    async processValue () {
      let val = this.item.val
      let response = null

      if (this._.isFunction(this.item.request)) {
        this.loading = true
        try {
          response = await this.item.request()
        } catch (e) {
          this.$handlers.error(e, this)
        } finally {
          this.loading = false
        }
      }

      if (this._.isFunction(this.item.value)) {
        val = this.item.value(val, response, this._.get(this.item, 'requestData', null))
      }
      return val
    }
  }
}
</script>

<style scoped>

</style>
