import Search from '~/models/abstracts/Search'

export class TaxTypeOutlets extends Search {
  static entity = 'taxtypeoutlets'
  static orderBy = {
    position: 'desc',
    code: 'desc'
  }

  static fields () {
    return {
      id: this.attr(null),
      code: this.attr(),
      name: this.attr(),
      position: this.attr(null)
    }
  }

  toString () {
    return this.name
  }

  static getSearchFields () {
    return ['name', 'code']
  }
}

export default TaxTypeOutlets
