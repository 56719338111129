<template lang="pug">
  v-col(cols="12" class="py-0")
    v-simple-table(class="elevation-0 m-orm-view-table")
      template(#default)
        tbody
          tr(v-for="(field, index) in items" :key="index" class="m-orm-view-table__row")
            td(class="m-orm-view-table__row-data")
              v-row
                t-orm-label(:item="fieldConf(field)" :grid=" {cols: 12, md: 6}" context="m-orm-view-table")
                v-col(cols="12" md="6" class="py-0")
                  component( v-if="fieldConf(field).viewComponent" :is="fieldConf(field).viewComponent" v-bind="fieldConf(field).viewAttrs" :value="fieldVal(field)")
                  span(v-else class="d-inline-block label") {{ fieldVal(field) }}
</template>

<script>
// TODO refactor this component !!! important
import TOrmLabel from '~/components/templates/orm/_includes/t-orm-label'
import EModelsColsMap from '~/components/elements/selections/e-models-cols-map'

export default {
  components: {
    EModelsColsMap,
    TOrmLabel
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    parentItem: {
      type: [Object, String],
      default: null
    },
    config: {
      type: Object,
      required: true
    }
  },
  methods: {
    fieldConf (field) {
      return this.config.items.find(i => i.model === field)
    },
    fieldVal (field) {
      const val = this.config.attrs[field]

      if (this._.isString(val)) {
        return val
      }

      if (this._.isArray(val) && val.length) {
        return val.join(' ')
      }

      if (this._.isObject(val)) {
        return val.name
      }

      return val
    }
  }
}
</script>

<style lang="scss">
  .m-orm-view-table {
    border: 1px solid rgba(0, 0, 0, 0.12);

    &__row {
      &:nth-child(2n - 1) {
        background-color: #F0F6FC;
      }

      &-data {
        padding: 0 30px !important;
      }
    }
  }
</style>
