<template lang="pug">
  v-fade-transition
    v-overlay(v-if="hover || loading" :absolute="absolute" :opacity="opacity")
      v-row(class="fill-height ma-0" align="center" justify="center")
        e-progress-circular(v-if="loading" :size="progressSize" :width="progressWidth" :color="color")
</template>

<script>
import { VFadeTransition, VOverlay } from 'vuetify/lib'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'

export default {
  components: {
    VFadeTransition,
    VOverlay,
    EProgressCircular
  },
  props: {
    hover: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    absolute: {
      type: Boolean,
      default: true
    },
    progressSize: {
      type: String,
      default: 'md'
    },
    progressWidth: {
      type: [Number, String],
      default: 2
    },
    opacity: {
      type: [Number, String],
      default: 0.46
    },
    color: {
      type: String,
      default: 'primary'
    }
  }
}
</script>

<style lang="scss">
  //
</style>
