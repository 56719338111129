import { ProcessingWebSocketMessageHandler } from '~/services/_utils/ProcessingWebSoket/ProcessingWebSocketMessageHandler'

let vueInstance = null

export default class ProcessingWebSocket {
  promise = null
  errors = []
  messages = []
  messageHandler = null
  openingResolve = null
  openingReject = null
  closingResolve = null
  closingReject = null
  handlers = null
  onStateChange = null

  constructor (baseUrl, token, vm) {
    this.baseUrl = baseUrl.trim()
    this.token = token.trim()
    vueInstance = vm
  }

  open = (messageHandler, onStateChange) => {
    if (this.opened) { return }
    this._resetValues()
    if (onStateChange) {
      this.onStateChange = onStateChange
    }
    if (messageHandler) {
      this.messageHandler = messageHandler
    }

    this.socket = new WebSocket(this.baseUrl + '/transactions/subscribe?token=' + this.token)
    const promise = new Promise((resolve, reject) => {
      this.openingResolve = resolve
      this.openingReject = reject
    })
    this.setListeners()
    return promise
  }

  setListeners () {
    this.socket.onopen = async (e) => {
      this.opened = true
      if (typeof this.onStateChange === 'function') { await this.onStateChange(this, e) }
      if (this.openingResolve) {
        this.openingResolve(e)
      }
    }
    this.socket.onclose = async (e) => {
      this.opened = false
      if (typeof this.onStateChange === 'function') { await this.onStateChange(this, e) }
      if (this.closingResolve) {
        this.closingResolve(e)
      }
    }
    this.socket.onerror = async (e) => {
      this.errors.push(e)
      if (typeof this.onStateChange === 'function') { await this.onStateChange(this, e) }
      if (this.openingReject) {
        this.openingReject(e)
      }
      vueInstance.$handlers.error(e, vueInstance)
    }
    this.socket.onmessage = (e) => {
      this.messages.push(e)
      if (this.messageHandler instanceof ProcessingWebSocketMessageHandler) {
        this.messageHandler.handle(e, this)
      }
    }
  }

  close = () => {
    this.socket.close()
    return new Promise((resolve, reject) => {
      this.closingResolve = resolve
      this.closingReject = reject
    })
  }

  send (body) {
    if (this.opened) {
      this.socket.send(body)
    }
  }

  _resetValues () {
    this.errors = []
    this.messages = []
    this.openingResolve = null
    this.openingReject = null
  }
}
