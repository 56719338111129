<template lang="pug">
  t-dialog(
    v-bind="$attrs"
    v-on="$listeners"
    type="default"
    size="md"
    max-width="840"
    :showCloseBtn="false"
    :persistent="false"
    content-class="youtube-dialog"
  )
    template(#content)
      iframe(style="border-radius:8px" width="100%" height="450" :src="videoUrl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen)
</template>

<script>
import TDialog from '~/components/templates/t-dialog'

export default {
  components: {
    TDialog
  },
  props: {
    videoUrl: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.youtube-dialog {
  iframe {
    display: block;
  }
  .v-card {
    &__title {
      display: none !important;
    }

    &__text {
      padding: 0 !important;
    }
  }
}
</style>
