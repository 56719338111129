import User from '../administration/User'
import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'

export default class PromoCodes extends ChainInheritance(Directory, []) {
  static entity = 'promocodes'

  static ormTrans = {
    single: 'Promo code',
    multy: 'Promo codes'
  }

  static fields () {
    return {
      user_id: this.attr(null),
      id: this.attr(null),
      status: this.attr(null),
      promoCodePurpose: this.attr(null),
      user: this.belongsTo(User, 'user_id'),
      startDate: this.attr(null)
    }
  }
}
