<template lang="pug">
  v-btn(
    depressed
    :type="type"
    :class="getCls"
    v-bind="$attrs"
    :loading="loading"
    @click="$emit('click', $event)")
    e-flat-icon(class="mr-2" v-if="enableIcon") {{ icon }}
    slot
      | Переглянути

</template>

<script>
import { VBtn } from 'vuetify/lib'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import EFlatIcon from '~/components/elements/icons/e-flat-icon'

export default {
  components: {
    ESvgIcon,
    EFlatIcon,
    VBtn
  },
  props: {
    icon: {
      type: String,
      default: 'flaticon-eye'
    },
    type: {
      type: String,
      default: 'button',
      validator: (value) => {
        return ['button', 'submit'].includes(value)
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    enableIcon: {
      type: Boolean,
      default: true
    },
    textColor: {
      type: String,
      default: 'main'
    },
    appearance: {
      type: String,
      default: 'primary',
      validator: (value) => {
        return ['primary', 'secondary'].includes(value)
      }
    }
  },
  computed: {
    getCls () {
      return [
        'e-button-main',
        `e-button-main--${this.appearance}`,
        `e-button-main--text-${this.textColor}`
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.e-button-main {
  background: $btn-main-bg-gradient !important;
  color: $btn-main-color;
  white-space: normal;
  height: auto !important;
  padding: 5px 18px !important;
  min-height: 40px;
  font-size: 14px;
  line-height: 1.25;
  border-radius: $btn-border-radius;

  &.v-btn--disabled {
    background: linear-gradient(247.21deg, rgba(140, 226, 176, 0.8) 8.73%, rgba(4, 174, 213, 0.8) 90.64%) !important;
    color: rgba(255, 255, 255, 0.7) !important;
  }

  &--primary {
    background: $btn-main-bg-gradient !important;
  }

  &--secondary {
    background: $btn-secondary-color !important;
  }

  &--text-main {
    color: $btn-main-color;
  }

  &--text-secondary {
    color: $btn-secondary-color;
  }
}
</style>
