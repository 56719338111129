const loading = {
  props: {
    loading: {
      type: [Boolean, null],
      default: () => null
    }
  },
  data: _ => ({
    localLoading: false
  }),
  computed: {
    isLoading () {
      return this.loading || this.localLoading
    }
  },
  methods: {
    async $loadFn (fn) {
      if (!this._.isFunction(fn)) { return }

      this.localLoading = true
      await fn()
      this.localLoading = false
    }
  }
}

export default loading
