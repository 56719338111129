import { Model } from '@vuex-orm/core'

export default {
  install (components, options) {
    components.Model.ormColsComponents = {
      active: {
        component: 'e-radio-status'
      }
    }

    components.Model.ormTabs = []

    components.Model.ormRowsConfig = {
      disabled: scopedItem => 'active' in scopedItem.item ? !scopedItem.item.active : false
    }

    components.Model.ormActions = [
      {
        name: 'edit'
      },
      {
        name: 'delete',
        // visible: item => item.active,
        disabled: item => !item.active
      }
    ]

    components.Model.ormLoadWithRelations = false

    components.Model.ormDialogs = {
      default: 'm-orm-crud-dialog',
      edit: 'm-orm-crud-dialog',
      delete: 'm-orm-delete-dialog',
      activate: 'm-orm-activate-dialog',
      deactivate: 'm-orm-deactivate-dialog',
      card: 'm-orm-card-dialog'
    }

    components.Model.getOrmDialogsConfig = function (type, def) {
      if (type && this.ormDialogsConfig && !Model._.isEmpty(this.ormDialogsConfig[type])) {
        return this.ormDialogsConfig[type]
      }

      if (def) {
        return def
      }
    }

    components.Model.getOrmFiltersConfig = function (type, def) {
      if (type && this.ormFiltersConfig && !Model._.isEmpty(this.ormFiltersConfig[type])) {
        return this.ormFiltersConfig[type]
      }

      if (def) {
        return def
      }

      return this.ormFiltersConfig
    }

    components.Model.ormCols = function (short, tableHeaders = []) {
      const values = this._.map(tableHeaders, item => item.value)
      let headers = this._.filter(this.ormHeaders, item => values.includes(item.tableHeader || item.value))

      if (short && this.isShortAble) {
        headers = headers.filter(header => this.getShortHeaders().includes(header.value))
      }

      const list = Model._.difference(
        Model._.map(headers, 'value'), this.ormColsExcept
      )

      const items = Model._.map(list, (field) => {
        let item = {
          col: field
        }

        if (this.ormColsComponents && this.ormColsComponents[field]) {
          item = Object.assign({}, this.ormColsComponents[field], item)
        }

        return item
      })

      return items
    }

    components.Model.getModelFields = function (obj, ctx) {
      const tmp = Model._.map(obj || this.ormFields, (field) => {
        const key = 'model'

        // Filtering of fields that have context property.
        if (ctx && ('context' in field) && Array.isArray(field.context) && !field.context.includes(ctx)) {
          return null
        }

        if ('fields' in field && field.fields.length) {
          return [field[key]].concat(Model._.map(field.fields, key))
        }

        // need this for dynamic fields
        if ('models' in field && field.models.length) {
          return field.models
        }

        return field[key]
      })

      return Model._.compact(Model._.flatten(tmp))
    }

    components.Model.getFilterFields = function (obj, local) {
      const tmp = Model._.map(obj || this.ormFilters, (field, index) => {
        const key = 'model'

        if (Model._.isBoolean(local)) {
          if (local) {
            return field.local ? field[key] : null
          }

          return !field.local ? field[key] : null
        }

        if ('fields' in field && field.fields.length) {
          return [field[key]].concat(Model._.map(field.fields, key))
        }
        // need this for dynamic fields
        if ('models' in field && field.models.length) {
          return field.models
        }

        return field[key]
      })

      return Model._.flatten(tmp)
    }

    components.Model.getModelValueByIndex = function (array, index) {
      return array[index] || null
    }

    components.Model.getModelFieldsDefaultValue = function (model) {
      const field = this.ormFields.find(field => model === field.model)

      if (field && 'default' in field) {
        return Model._.isFunction(field.default) ? field.default(field) : field.default
      }

      return null
    }

    components.Model.getModelFiltersDefaultValue = function (model, fieldId) {
      const field = this.ormFilters.find(field => model === field.model)

      if (field && 'default' in field) {
        return Model._.isFunction(field.default) ? field.default(field, fieldId) : field.default
      }

      return null
    }

    components.Model.generateOrmFieldsObject = function (config, payload) {
      const modelFields = this.getModelFields(config)

      const defaultValue = Model._.map(modelFields, (field, index) => {
        return Model._.isArray(payload)
          ? this.getModelValueByIndex(payload, index)
          : null
      })

      return Model._.zipObject(
        modelFields,
        defaultValue
      )
    }

    components.Model.getFieldsObject = function ({ from = null, ctx = 'default' } = {}) {
      const modelFields = this.getModelFields(null, ctx)
      const relationFields = this.getRelationFields(modelFields, false)
      const fields = modelFields.concat(relationFields)
      const defaultValue = Model._.map(fields, (field) => {
        return Model._.isObject(from)
          ? this.getFieldsByOrmFieldsMap(from, field)
          : this.getModelFieldsDefaultValue(field)
      })

      return Model._.zipObject(
        fields,
        defaultValue
      )
    }

    components.Model.getOrmFields = function ({ except = null, only = null, nested = false } = {}) {
      const tmp = Model._.map(this.ormFields, (field) => {
        const key = 'model'

        if (nested && only && ('fields' in field) && field.fields.length) {
          return Model._.map(field.fields, i => Model._.get(i, only, false) ? i[key] : null)
        }

        if (nested && only) {
          return null
        }

        if (except && Model._.get(field, except, false)) {
          return null
        }

        if (only && !Model._.get(field, only, false)) {
          return null
        }

        return field[key]
      })

      return Model._.compact(Model._.flatten(tmp))
    }

    // TODO: replace it by getFieldsObject method based on context.
    components.Model.getOrmFieldsObject = function (from) {
      const modelFields = this.getModelFields()
      const defaultValue = Model._.map(modelFields, (field) => {
        return Model._.isObject(from)
          ? this.getFieldsByOrmFieldsMap(from, field)
          : this.getModelFieldsDefaultValue(field)
      })

      return Model._.zipObject(
        modelFields,
        defaultValue
      )
    }

    components.Model.getOrmFiltersObject = function (from, local, paramsBug) {
      if (Model._.isObject(from)) {
        return Model._.pick(from, this.getFilterFields(null, local))
      }

      const modelFields = this.getFilterFields()
      const defaultValue = Model._.map(modelFields, (field) => {
        const fieldId = Model._.get(paramsBug, 'queryString.' + field, null)
        return this.getModelFiltersDefaultValue(field, fieldId)
      })

      return Model._.zipObject(
        modelFields,
        defaultValue
      )
    }

    components.Model.getFieldsByOrmFieldsMap = function (from, field) {
      if (Model._.has(from, field) || (this.ormFieldsMap && Model._.has(from, this.ormFieldsMap[field]))) {
        return Model._.get(
          from,
          Model._.has(from, field)
            ? field
            : this.ormFieldsMap[field]
        )
      }

      return this.getModelFieldsDefaultValue(field)
    }

    components.Model.deepMorph = function (from, ctx = this) {
      const modelIntersection = Model._.intersection(ctx.getModelFields(), Model._.keys(ctx.ormFieldsMap))
      const modelDifference = Model._.difference(Model._.keys(from), modelIntersection)

      const mapFields = Model._.pick(ctx.ormFieldsMap, modelIntersection)
      const invertedMapFields = Model._.invert(mapFields)
      const relValues = ctx.toRelation(Model._.pick(from, Model._.keys(mapFields)))

      const zipValues = Model._.values(relValues)
      const zipKeys = Model._.values(mapFields).filter((val) => {
        const last = Model._.last(val.split('.'))
        const relValuesKeys = Model._.keys(relValues)

        if (/\[[0-9]+\]/g.test(last)) {
          return relValuesKeys.includes(invertedMapFields[val])
        } else {
          // NOTE: that we check on last value of mapping or key.
          return relValuesKeys.includes(last) || relValuesKeys.includes(invertedMapFields[val])
        }
      })

      // Remove key and values for fields if field with null value.
      for (const index in zipValues) {
        if (Model._.isNull(zipValues[index])) {
          zipValues.splice(index, 1)
          zipKeys.splice(index, 1)
        }
      }

      return Object.assign({},
        ctx.toRelation(Model._.pick(from, modelDifference)),
        Model._.zipObjectDeep(zipKeys, zipValues)
      )
    }

    components.Model.getRelationFields = function (except = null) {
      if (except) {
        return this._.difference(this.ormRelationMap, except)
      }

      return this.ormRelationMap
    }

    components.Model.toRelation = function (fields) {
      if (!('ormRelationMap' in this) || !this.ormRelationMap.length) {
        return fields
      }

      const picked = Model._.pick(fields, this.ormRelationMap)

      const mapped = Model._.mapValues(picked, (payload) => {
        if (!payload) {
          return payload
        }

        if (Model._.isArray(payload)) {
          return payload.map(i => this.relationLinkFor(i))
        } else {
          return this.relationLinkFor(payload)
        }
      })

      return Object.assign({}, fields, mapped)
    }

    components.Model.relationLinkFor = function (obj) {
      // TODO: create local error reporting in hendlers (for developers).
      if (process.env.NODE_ENV === 'development' && !obj.constructor.entity) {
        alert('Current object do not have concrete class! Details in console...')
        // eslint-disable-next-line no-console
        console.error('Cant get entity name in orm-table.js / 155 line')
        // eslint-disable-next-line no-console
        console.error('Object do not extend any of our ORM classes.')
        // eslint-disable-next-line no-console
        console.error('Object: ', obj)
      }

      if (Model._.isNull(obj.primaryVal)) {
        return this.deepMorph(obj, obj.$self())
      }

      // TODO: improve this (this add partial serializing when we edit item with related model). We may create this by wrapper.
      if (obj.partialSerialize) {
        obj.id = [Model.$routes[obj.$self().entity].list(), obj.primaryVal].join('/').trim('/')
        return this.deepMorph(obj, obj.$self())
      }

      return [Model.$routes[obj.$self().entity].list(), obj.primaryVal].join('/').trim('/')
    }
  }
}
